import { Component, OnInit, Input } from "@angular/core";
import { DatePipe } from "@angular/common";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RateQuoteLookupModel } from "src/app/models/RateQuoteLookupModel";
import { PremiumPaidBy } from "src/app/models/enums/PremiumPaidBy";
import { RefundOption } from "src/app/models/enums/RefundOption";
import { RenewalOption } from "src/app/models/enums/RenewalOption";
import { RequestType } from "src/app/models/enums/RequestType";

@Component({
    selector: "app-search-rate-quote-id",
    templateUrl: "./search-rate-quote-id.component.html",
    styleUrls: ["./search-rate-quote-id.component.css"],
    providers: [DatePipe]
})
export class SearchRateQuoteIdComponent implements OnInit {
    searchId: string;
    requestType: string;
    tableRowSelected: FormGroup;
    pickedLine: RateQuoteLookupModel | null = null;
    showComponent = false;
    sampleSearchData: RateQuoteLookupModel = {
        rateQuoteId: "123456A",
        productDescription: "Borrower Paid Monthly",
        quoteType: RequestType.DelegatedMi,
        quoteDate: "7/1/24, 3:21:01PM",
        expirationeDate: "8/1/24",
        closing: {
            percent: 25,
            duration: "120",
            amount: 125.0
        },
        firstRenewal: {
            percent: 25,
            duration: "120",
            amount: 125.0
        },
        secondRenewal: {
            percent: 25,
            duration: "120",
            amount: 125.0
        },
        premiumPlan: "Borrower Paid Monthly",
        premiumAtClosing: "deferred",
        paidBy: PremiumPaidBy.BorrowerPaid,
        refundOption: RefundOption.NotRefundable,
        renewalOption: RenewalOption.DecliningAmortizing,
        shortProductDescription: "BPMI",
        showDetails: false,
        bgColor: "white",
        isSelected: false
    };
    sampleSearchData2: RateQuoteLookupModel = {
        rateQuoteId: "563345B",
        productDescription: "Borrower Paid Monthly",
        quoteType: RequestType.DelegatedMi,
        quoteDate: "9/6/24, 3:21:01PM",
        expirationeDate: "8/1/24",
        closing: {
            percent: 25,
            duration: "120",
            amount: 125.0
        },
        firstRenewal: {
            percent: 25,
            duration: "120",
            amount: 125.0
        },
        secondRenewal: {
            percent: 25,
            duration: "120",
            amount: 125.0
        },
        premiumPlan: "Borrower Paid Monthly",
        premiumAtClosing: "deferred",
        paidBy: PremiumPaidBy.BorrowerPaid,
        refundOption: RefundOption.NotRefundable,
        renewalOption: RenewalOption.DecliningAmortizing,
        shortProductDescription: "BPMI",
        showDetails: false,
        bgColor: "white",
        isSelected: false
    };
    searchData = [this.sampleSearchData, this.sampleSearchData2];
    sortDirection: string = "asc";

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder
    ) {
        this.tableRowSelected = this.fb.group({
            option: new FormControl("")
        });
    }

    ngOnInit(): void {
        console.log("Initialize Search Rate Quote");
    }

    selectedLine(item: any): void {
        this.searchData.forEach(data => {
            if (data.rateQuoteId === item.rateQuoteId) {
                data.isSelected = !data.isSelected;
                data.bgColor = "white";
            } else {
                data.isSelected = false;
                data.bgColor = "white";
            }
        });

        if (item.isSelected) {
            item.bgColor = "rgba(173, 216, 230, 35%)";
            this.pickedLine = item;
        } else {
            item.bgColor = "white";
            this.pickedLine = null;
        }
    }

    resetSelectedLine(): void {
        this.tableRowSelected.reset();
        this.tableRowSelected.get("option")?.setValue(null);
    }

    onSave(): void {
        // Close modal and pass Admin Settings back
        this.activeModal.close();
    }

    showDetails(item: any): void {
        item.showDetails = !item.showDetails;
    }

    sortByQuoteDate() {
        this.searchData.sort((a, b) => {
            const dateA = this.parseDate(a.quoteDate);
            const dateB = this.parseDate(b.quoteDate);
            if (this.sortDirection === "asc") {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    }

    parseDate(dateString: string): number {
        const [datePart, timePart] = dateString.split(", ");

        const [month, day, year] = datePart.split("/").map(Number);

        let [time, modifier] = timePart.match(/(\d{1,2}:\d{2}:\d{2})(AM|PM)/i)!.slice(1, 3);
        let [hours, minutes, seconds] = time.split(":").map(Number);

        if (modifier.toUpperCase() === "PM" && hours < 12) {
            hours += 12;
        }
        if (modifier.toUpperCase() === "AM" && hours === 12) {
            hours = 0;
        }

        const fullYear = year + 2000;

        const parsedDate = new Date(fullYear, month - 1, day, hours, minutes, seconds);

        return parsedDate.getTime();
    }
}
// TODO render table dynamically.

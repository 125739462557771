import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaveButtonService {
  private canSaveSubject = new BehaviorSubject<boolean>(true);
  canSave$ = this.canSaveSubject.asObservable();

  setCanSave(value: boolean) {
    this.canSaveSubject.next(value);
  }
}
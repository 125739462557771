import { DecimalPipe, PercentPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";
import { FormatUtils } from "src/app/utilities/formatUtils";

@Component({
    selector: "app-order-rates",
    templateUrl: "./order-rates.component.html",
    styleUrls: ["./order-rates.component.css"]
})
export class OrderRatesComponent implements OnInit {
    @Input() transactionData: TransactionData | null = null;
    @Input() originData: OriginDataObject | null = null;
    @Input() rateData: any | null = null;

    constructor(
        private decimalPipe: DecimalPipe,
        private percentPipe: PercentPipe,
        public formatUtils: FormatUtils
    ) {}

    ngOnInit(): void {}
}

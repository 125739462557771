import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl, ValidatorFn, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminSettings } from "src/app/models/AdminSettings";
import { MiCoverageBracket } from "src/app/models/MiCoverageBracket";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";
import { AffordableHousing } from "src/app/models/enums/AffordableHousing";
import { AmortizationPlan } from "src/app/models/enums/AmortizationPlan";
import { LoanData } from "src/app/models/loan-data";

@Component({
    selector: "app-mi-table",
    templateUrl: "./mi-table.component.html",
    styleUrls: ["./mi-table.component.css"]
})
export class MiTableComponent implements OnInit {
    settings: AdminSettings;
    originData: OriginDataObject;
    transactionData: TransactionData;
    miCoverageValueRecieved: any;
    affordableHousingTypeValueRecieved: AffordableHousing;

    affordableHousing = AffordableHousing;
    affordableHousingKeys = Object.keys(AffordableHousing) as (keyof typeof AffordableHousing)[];
    selectAffordableHousingType = new FormControl();
    selectMiCoverage = new FormControl("", [
        Validators.required,
        Validators.pattern("^(100|[1-9]?[0-9])?(\\.\\d{1,2})?%?$")
    ]);
    backgroundColor: string;
    fnmaLendingProduct = new FormControl();
    fhlmcOfferingIdentifier = new FormControl();
    amortizationType = new FormControl();
    ltvValue = new FormControl();
    termInYears = new FormControl();
    termInMonths = new FormControl();
    agencyAbriviation = "";
    miTableData = [
        {
            baseLTV: "95.01% - 97%",
            standardCoverage: { greaterThan20Years: "35", lessThan20Years: "35" },
            specialCoverage: { greaterThan20Years: "25", lessThan20Years: "25" },
            hfaCoverage: { allYears: "18" }
        },
        {
            baseLTV: "90.01% - 95%",
            standardCoverage: { greaterThan20Years: "30", lessThan20Years: "25" },
            specialCoverage: { greaterThan20Years: "25", lessThan20Years: "25" },
            hfaCoverage: { allYears: "16" }
        },
        {
            baseLTV: "85.01% - 90%",
            standardCoverage: { greaterThan20Years: "25", lessThan20Years: "12" },
            specialCoverage: { greaterThan20Years: "25", lessThan20Years: "12" },
            hfaCoverage: { allYears: "12" }
        },
        {
            baseLTV: "85% & Under",
            standardCoverage: { greaterThan20Years: "12", lessThan20Years: "6" },
            specialCoverage: { greaterThan20Years: "12", lessThan20Years: "6" },
            hfaCoverage: { allYears: "6" }
        }
    ];
    selectedCell: string;

    constructor(
        public cdr: ChangeDetectorRef,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        this.loadStandardCoverage();
        this.loadLoanData(this.originData, this.transactionData);
        if (this.affordableHousingTypeValueRecieved) {
            this.selectAffordableHousingType.setValue(this.affordableHousingTypeValueRecieved);
        }

        if (this.miCoverageValueRecieved) {
            this.selectMiCoverage.setValue(this.miCoverageValueRecieved);
        }
    }

    percentageValidator(): ValidatorFn {
        return (control: AbstractControl): { [keys: string]: any } | null => {
            const value = control.value.replace("%", "");
            const isValid = /^([1-9][0-9]?)?$/.test(value) && parseInt(value) <= 99;
            return isValid ? null : { percentage: { value: control.value } };
        };
    }

    appendPercentage(): void {
        let value = this.selectMiCoverage.value;
        if (value && !value.endsWith("%")) {
            this.selectMiCoverage.setValue(value + "%");
        }
    }

    onCellClick(coveragePercent: string, cell: string): void {
        // Per feedback we do not want to update the MI Coverage formControl with selected value at this time.
        // this.selectMiCoverage.setValue(coveragePercent);
        this.selectedCell = cell;
    }

    closeModal() {
        this.activeModal.close({
            miValue: this.selectMiCoverage.value,
            affordableHousing: this.selectAffordableHousingType.value
        });
    }

    loadStandardCoverage(): void {
        const tableData: MiCoverageBracket[] = this.settings.miTable;
        switch (this.settings.miCoverageType) {
            case "Freddie Mac":
                this.agencyAbriviation = "FHLMC";
                this.miTableData[0].standardCoverage.greaterThan20Years =
                    tableData[0].fhlmcCoverage.greaterThan20Years.value;
                this.miTableData[0].standardCoverage.lessThan20Years = tableData[0].fhlmcCoverage.lessThan20Years.value;

                this.miTableData[1].standardCoverage.greaterThan20Years =
                    tableData[1].fhlmcCoverage.greaterThan20Years.value;
                this.miTableData[1].standardCoverage.lessThan20Years = tableData[1].fhlmcCoverage.lessThan20Years.value;

                this.miTableData[2].standardCoverage.greaterThan20Years =
                    tableData[2].fhlmcCoverage.greaterThan20Years.value;
                this.miTableData[2].standardCoverage.lessThan20Years = tableData[2].fhlmcCoverage.lessThan20Years.value;

                this.miTableData[3].standardCoverage.greaterThan20Years =
                    tableData[3].fhlmcCoverage.greaterThan20Years.value;
                this.miTableData[3].standardCoverage.lessThan20Years = tableData[3].fhlmcCoverage.lessThan20Years.value;

                break;
            default:
                this.agencyAbriviation = "FNMA";
                this.miTableData[0].standardCoverage.greaterThan20Years =
                    tableData[0].fnmaCoverage.greaterThan20Years.value;
                this.miTableData[0].standardCoverage.lessThan20Years = tableData[0].fnmaCoverage.lessThan20Years.value;

                this.miTableData[1].standardCoverage.greaterThan20Years =
                    tableData[1].fnmaCoverage.greaterThan20Years.value;
                this.miTableData[1].standardCoverage.lessThan20Years = tableData[1].fnmaCoverage.lessThan20Years.value;

                this.miTableData[2].standardCoverage.greaterThan20Years =
                    tableData[2].fnmaCoverage.greaterThan20Years.value;
                this.miTableData[2].standardCoverage.lessThan20Years = tableData[2].fnmaCoverage.lessThan20Years.value;

                this.miTableData[3].standardCoverage.greaterThan20Years =
                    tableData[3].fnmaCoverage.greaterThan20Years.value;
                this.miTableData[3].standardCoverage.lessThan20Years = tableData[3].fnmaCoverage.lessThan20Years.value;
                break;
        }
    }

    loadLoanData(originData: OriginDataObject, TransactionData: TransactionData) {
        this.fnmaLendingProduct.disable();
        this.fhlmcOfferingIdentifier.disable();
        this.amortizationType.disable();
        this.ltvValue.disable();
        this.termInYears.disable();
        this.termInMonths.disable();

        if (originData != null) {
            this.selectAffordableHousingType.setValue(originData.affordableHousingType);
            this.fnmaLendingProduct.setValue(originData.loan?.fnmCommunityLendingProductName);
            this.fhlmcOfferingIdentifier.setValue(originData.loanProductData?.freOfferingIdentifier);
            this.amortizationType.setValue(originData.loan?.loanAmortizationType as AmortizationPlan);
            this.ltvValue.setValue(originData.loan?.ltv);
            this.termInYears.setValue((originData.loan?.loanAmortizationTermMonths / 12).toString() + " years");
            this.termInMonths.setValue((originData.loan?.loanAmortizationTermMonths).toString() + " months");
        }
    }

    // TODO Wire refresh MI Button to encompass per feedback. Not yet implemented connection front and back
    // rename this
    pullDataEncompass() {}
}

<table>
    <thead>
        <tr>
            <th class="header first-header">PERSONA</th>
            <th class="header">
                <input type="checkbox" [checked]="columnsChecked.rateQuote" (change)="checkAll('rateQuote', $event)" />
                RATE QUOTE
            </th>
            <th class="header">
                <input type="checkbox" [checked]="columnsChecked.delegated" (change)="checkAll('delegated', $event)" />
                DELEGATED
            </th>
            <th class="header">
                <input
                    type="checkbox"
                    [checked]="columnsChecked.nonDelegated"
                    (change)="checkAll('nonDelegated', $event)"
                />
                NON DELEGATED
            </th>
            <th class="header">
                <input
                    type="checkbox"
                    [checked]="columnsChecked.contractUnderwriting"
                    (change)="checkAll('contractUnderwriting', $event)"
                />
                CONTRACT UNDERWRITING
            </th>
            <th class="header last-header">
                <input
                    type="checkbox"
                    [checked]="columnsChecked.retrieveCertificate"
                    (change)="checkAll('retrieveCertificate', $event)"
                />
                RETRIEVE MI CERTIFICATE
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of personasTable; index as i">
            <td class="persona-name">{{ row.name }}</td>
            <td>
                <input type="checkbox" [checked]="row.rateQuote" (change)="handleInput('rateQuote', i, $event)" />
            </td>
            <td>
                <input type="checkbox" [checked]="row.delegated" (change)="handleInput('delegated', i, $event)" />
            </td>
            <td>
                <input type="checkbox" [checked]="row.nonDelegated" (change)="handleInput('nonDelegated', i, $event)" />
            </td>
            <td>
                <input
                    type="checkbox"
                    [checked]="row.contractUnderwriting"
                    (change)="handleInput('contractUnderwriting', i, $event)"
                />
            </td>
            <td>
                <input
                    type="checkbox"
                    [checked]="row.retrieveCertificate"
                    (change)="handleInput('retrieveCertificate', i, $event)"
                />
            </td>
        </tr>
    </tbody>
</table>

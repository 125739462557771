<form [formGroup]="miCoverageAndAus" (ngSubmit)="showMiTable()" class="mi-coverage-aus-findings-container">
    <div>
        <h3 class="h3-left">MI COVERAGE</h3>
        <div class="container-main-section">
            <div class="selection-block-quarter-left">
                <label class="selection-label-left">MI Coverage % <span class="required-field-marker">*</span></label
                ><br />
                <ng-container [formGroup]="miCoverageAndAus">
                    <form>
                        <input
                            *ngIf="!transactionData && disableMiCoverage === false"
                            class="input-selection-id-short"
                            type="text"
                            [formControl]="selectMiCoverage"
                            id="selectMiCoverage"
                            name="selectMiCoverage"
                            maxlength="3"
                            min="0"
                            max="100"
                            [ngClass]="{
                                'is-invalid':
                                    selectMiCoverage.invalid && (selectMiCoverage.dirty || selectMiCoverage.touched)
                            }"
                        />

                        <input
                            *ngIf="transactionData || disableMiCoverage === true"
                            maxlength="3"
                            min="0"
                            max="100"
                            [ngClass]="{
                                'is-invalid':
                                    selectMiCoverage.invalid && (selectMiCoverage.dirty || selectMiCoverage.touched)
                            }"
                            class="input-selection-id read-only"
                            type="text"
                            name="selectMiCoverage"
                            [formControl]="selectMiCoverage"
                        />
                        <button
                            *ngIf="disableMiCoverage === false"
                            (click)="pullDataEncompass()"
                            class="button-refresh"
                        >
                            <i class="bi bi-arrow-repeat"></i>
                        </button>
                        <button *ngIf="disableMiCoverage === true" class="button-read-only">
                            <i class="bi bi-arrow-repeat"></i>
                        </button>
                        <div
                            *ngIf="selectMiCoverage.invalid && (selectMiCoverage.dirty || selectMiCoverage.touched)"
                            class="invalid-feedback"
                        >
                            <div *ngIf="selectMiCoverage.hasError('required')">MI Coverage % is required</div>
                            <div *ngIf="selectMiCoverage.hasError('pattern')">Please enter value between 1 and 100</div>
                        </div>
                    </form>
                </ng-container>
            </div>
            <div class="selection-block-quarter-left">
                <label class="selection-label-left">Affordable Housing Type</label><br />
                <ng-container [formGroup]="miCoverageAndAus">
                    <select
                        [formControl]="selectAffordableHousingType"
                        class="selection-dropdown-left"
                        [class.read-only]="transactionData"
                        data-test-id="affordable-housing-dropdown"
                    >
                        <option
                            *ngIf="transactionData?.affordableHousingType"
                            value="{{ transactionData?.affordableHousingType }}"
                        >
                            {{ transactionData?.affordableHousingType }}
                        </option>
                        <option [value]="" selected hidden>Select</option>
                        <option *ngFor="let key of affordableHousingKeys" [value]="key">
                            {{ affordableHousing[key] }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div class="selection-block-quarter-left">
                <button type="submit" data-test-id="mi-coverage-details-button" (click)="openMiCoverageDetails()">
                    MI COVERAGE % DETAILS
                </button>
            </div>
            <div class="selection-block-quarter-left">
                <div *ngIf="showRelocationLoan">
                    <ng-container>
                        <input
                            *ngIf="!transactionData"
                            [formControl]="checkBoxRelocationLoan"
                            type="checkbox"
                            id="relocationLoan"
                            data-test-id="reloaction-loan-clickbox"
                        />
                    </ng-container>
                    <label class="check-box-label" for="relocationLoan">Relocation Loan</label>
                </div>
            </div>
        </div>
    </div>
    <div class="container-last-main-section">
        <div class="left-container-block-half">
            <h3 class="h3-left">AUS FINDINGS</h3>
            <div class="left-container-grid-half">
                <div class="container-block-quarter">
                    <label class="left-margin">AUS Service <span class="required-field-marker">*</span> </label> <br />
                    <ng-container [formGroup]="miCoverageAndAus">
                        <select
                            [formControl]="selectAusService"
                            class="wide-field-left-margin"
                            [class.read-only]="transactionData"
                            data-test-id="aus-selection"
                        >
                            <option *ngIf="transactionData" value="{{ originData?.loan?.tsum?.riskAssessmentType }}">
                                {{ originData?.loan?.tsum?.riskAssessmentType }}
                            </option>
                            <option *ngFor="let key of ausKeys" [value]="ausServiceEnum[key]">
                                {{ ausServiceEnum[key] }}
                            </option>
                        </select>
                        <div
                            class="selection-block-half"
                            *ngIf="
                                originData?.loan?.tsum?.riskAssessmentType === ausServiceEnum.DesktopUnderwriter ||
                                selectAusService?.value === ausServiceEnum.DesktopUnderwriter
                            "
                        >
                            <label class="du-text">DU Case File ID </label> <br />
                            <p *ngIf="transactionData" class="left-margin">
                                {{ originData?.loan?.fannieMae?.mornetPlusCaseFileId }}
                            </p>
                            <p *ngIf="!transactionData" class="left-margin">--</p>
                            <label class="left-margin"
                                >DU Recommendation <span class="required-field-marker">*</span>
                            </label>
                            <br />
                            <select class="wide-field-left-margin" [formControl]="selectDuFindings">
                                <option
                                    *ngIf="transactionData"
                                    value="{{ originData?.loan?.tql?.stonegateFraudBaselineReportRequired }}"
                                >
                                    {{ originData?.loan?.tql?.stonegateFraudBaselineReportRequired }}
                                </option>
                                <option *ngFor="let key of duFeedbackKeys" [value]="duFindings[key]">
                                    {{ duFindings[key] }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="selection-block-half"
                            *ngIf="
                                originData?.loan?.tsum?.riskAssessmentType === ausServiceEnum.LoanProductAdvisor ||
                                miCoverageAndAus.get('userSelectedEngineSelection')?.value ===
                                    ausServiceEnum.LoanProductAdvisor
                            "
                        >
                            <label class="du-text">LPA Case File ID </label> <br />
                            <p *ngIf="transactionData" class="left-margin">
                                {{ originData?.loan?.freddieMac?.loanProspectorId }}
                            </p>
                            <p *ngIf="!transactionData" class="left-margin">--</p>
                            <label class="left-margin"
                                >Credit Risk Class <span class="required-field-marker">*</span></label
                            >
                            <br />
                            <select class="wide-field-left-margin" [formControl]="selectRiskClass">
                                <option
                                    *ngIf="transactionData"
                                    value="{{ originData?.loan?.freddieMac?.freddieFiel14 }}"
                                >
                                    {{ originData?.loan?.freddieMac?.freddieFiel14 }}
                                </option>
                                <option *ngFor="let key of creditRiskKeys" [value]="creditRisk[key]">
                                    {{ creditRisk[key] }}
                                </option>
                            </select>
                        </div>
                        <br />
                        <div
                            class="selection-block-half"
                            *ngIf="
                                originData?.loan?.tsum?.riskAssessmentType === ausServiceEnum.LoanProductAdvisor ||
                                miCoverageAndAus.get('userSelectedEngineSelection')?.value ===
                                    ausServiceEnum.LoanProductAdvisor
                            "
                        >
                            <label class="left-margin"
                                >Purchase Eligiblity <span class="required-field-marker">*</span></label
                            >
                            <br />
                            <select class="wide-field-left-margin" [formControl]="selectPurchaseEligibility">
                                <option
                                    *ngIf="transactionData"
                                    value="{{ originData?.loan?.tql?.stonegate4506TBaseLineReport }}"
                                >
                                    {{ originData?.loan?.tql?.stonegate4506TBaseLineReport }}
                                </option>
                                <option *ngFor="let key of purchaseEligibilityKeys" [value]="purchaseEligibility[key]">
                                    {{ purchaseEligibility[key] }}
                                </option>
                            </select>
                        </div>
                    </ng-container>
                </div>
                <div class="container-block-quarter">
                    <ng-container [formGroup]="miCoverageAndAus">
                        <input
                            *ngIf="transactionData"
                            [formControl]="checkBoxAusDocumentWaiver"
                            type="checkbox"
                            id="waiver"
                            class="left-margin aus-waiver"
                            data-test-id="aus-waiver-checkbox"
                        />
                        <input
                            *ngIf="!transactionData"
                            [formControl]="checkBoxAusDocumentWaiver"
                            type="checkbox"
                            id="waiver"
                            class="left-margin aus-waiver"
                            data-test-id="aus-waiver-checkbox"
                        />
                    </ng-container>
                    <label class="click-box-label" for="waiver">AUS Document Waiver</label>
                </div>
            </div>

            <div class="selection-block-half"></div>
        </div>
        <div class="container-block-half">
            <h3 class="h3-right">ORIGINATOR INFORMATION</h3>
            <div class="selection-block-half">
                <div class="in-line">
                    <div>
                        <label class="left-margin">Originator Type<span class="required-field-marker">*</span></label>
                        <br />
                        <ng-container [formGroup]="miCoverageAndAus">
                            <select
                                [formControl]="selectOriginatorType"
                                class="wide-field-left-margin"
                                [class.read-only]="transactionData"
                                data-test-id="originator-type-dropdown"
                            >
                                <option *ngIf="transactionData" value="{{ transactionData.originatorType }}">
                                    {{ transactionData.originatorType }}
                                </option>
                                <option *ngFor="let key of originatorTypeKeys" [value]="key">
                                    {{ originatorTypes[key] }}
                                </option>
                            </select>
                        </ng-container>
                    </div>
                    <div
                        *ngIf="
                            selectOriginatorType.value === originatorTypes.Broker ||
                            selectOriginatorType.value === originatorTypes.Correspondent
                        "
                    >
                        <Form [formGroup]="tpoNameForm">
                            <label for="tpoCompanyName" class="left-margin"
                                >TPO Company Name <span class="required-field-marker">*</span></label
                            >
                            <br />
                            <input
                                type="text"
                                id="tpoCompanyName"
                                name="tpoCompanyName"
                                class="wide-field-left-margin"
                                [ngClass]="{
                                    'is-invalid':
                                        tpoCompanyName?.invalid && (tpoCompanyName?.dirty || tpoCompanyName?.touched)
                                }"
                                formControlName="tpoCompanyName"
                                required
                                maxlength="51"
                            />
                            <div
                                *ngIf="tpoCompanyName?.invalid && (tpoCompanyName?.dirty || tpoCompanyName?.touched)"
                                class="invalid-feedback"
                            >
                                <div *ngIf="tpoCompanyName?.hasError('required')">TPO Company Name is required</div>
                                <div *ngIf="tpoCompanyName?.hasError('maxlength')">
                                    TPO Company Name must be 51 characters or less
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <ng-container
                    *ngIf="
                        selectOriginatorType.value === originatorTypes.Broker ||
                        selectOriginatorType.value === originatorTypes.Correspondent
                    "
                    class="border-left"
                >
                    <div class="border-left">
                        <form [formGroup]="originatorInfoForm">
                            <div>
                                <label class="left-margin">Address</label>
                                <br />
                                <input
                                    type="text"
                                    id="address"
                                    style="width: 150%"
                                    class="wide-field-left-margin"
                                    formControlName="address"
                                    maxlength="51"
                                />
                            </div>
                            <div class="in-line">
                                <div>
                                    <label class="left-margin">City<span class="required-field-marker">*</span></label>
                                    <br />
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        class="wide-field-left-margin"
                                        [ngClass]="{ 'is-invalid': city?.invalid && (city?.dirty || city?.touched) }"
                                        formControlName="city"
                                        maxlength="51"
                                    />
                                    <div
                                        *ngIf="city?.invalid && (city?.dirty || city?.touched)"
                                        class="invalid-feedback"
                                    >
                                        <div *ngIf="city?.hasError('required')">City is required</div>
                                        <div *ngIf="city?.hasError('maxlength')">
                                            City must be 51 characters or less
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label class="left-margin">State<span class="required-field-marker">*</span></label>
                                    <br />

                                    <select
                                        [formControl]="selectState"
                                        class="wide-field-left-margin"
                                        [ngClass]="{
                                            'is-invalid':
                                                selectState.invalid && (selectState.dirty || selectState.touched)
                                        }"
                                    >
                                        <option value="" disabled>Select</option>
                                        <option *ngFor="let key of stateKeys" [value]="key">{{ states[key] }}</option>
                                    </select>
                                    <div
                                        *ngIf="selectState.invalid && (selectState.dirty || selectState.touched)"
                                        class="invalid-feedback"
                                    >
                                        <div *ngIf="selectState.hasError('required')">State is required</div>
                                    </div>
                                </div>
                                <div>
                                    <label class="left-margin"
                                        >Zipcode<span class="required-field-marker">*</span></label
                                    >
                                    <br />
                                    <input
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        class="wide-field-left-margin"
                                        [ngClass]="{
                                            'is-invalid': zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)
                                        }"
                                        formControlName="zipcode"
                                        maxlength="10"
                                    />
                                    <div
                                        *ngIf="zipcode?.invalid && (zipcode?.dirty || zipcode?.touched)"
                                        class="invalid-feedback"
                                    >
                                        <div *ngIf="zipcode?.hasError('required')">Zipcode is required</div>
                                        <div *ngIf="zipcode?.hasError('maxlength')">
                                            Zipcode must be 10 characters or less
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="showUnderwriterComments" class="underwriter-comments-container">
        <div class="underwriter-comments">
            <div class="underwriter-comments-title-container">
                <div class="underwriter-comments-title">Underwriting Comments</div>
                <div class="underwriter-comments-char-count">{{ currentCharacters }}/{{ maxCharacters }}</div>
            </div>
            <textarea
                class="underwriter-comments-textarea"
                maxlength="2000"
                placeholder="Add comments..."
                (input)="handleInput($event)"
            ></textarea>
        </div>
    </div>
</form>

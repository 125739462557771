<div class="container-inline">
    <h3>MI Coverage % Details</h3>
    <button (click)="closeModal()" class="blue">X</button>
</div>
<div class="inline title">
    <i class="bi bi-info-circle info-icon-image" alt="infoIcon"></i>
    <p>
        Refreshing the MI Coverage % will calculate the default coverage percentage using sorted loan values, which are
        shown below. This will also reset the Affordable Housing Type field to its default value. Affordable Housing
        Type may be further modified here for ordering purposes, but any changes will not be stored on the loan, in all
        cases, the calculated coverage % value result will be shown in the table below.
    </p>
</div>

<div class="selection-block-half-width">
    <div>
        <label class="selection-label-left">MI Coverage % <span class="required-field-marker">*</span></label
        ><br />
        <input
            class="selection-dropdown-left"
            type="text"
            id="selectMiCoverage"
            name="selectMiCoverage"
            [formControl]="selectMiCoverage"
            (blur)="appendPercentage()"
            [ngClass]="{
                'is-invalid': selectMiCoverage.invalid && (selectMiCoverage.dirty || selectMiCoverage.touched)
            }"
        />
        <div
            *ngIf="selectMiCoverage.invalid && (selectMiCoverage.dirty || selectMiCoverage.touched)"
            class="invalid-feedback"
        >
            <div *ngIf="selectMiCoverage.hasError('required')">MI Coverage % is required</div>
            <div *ngIf="selectMiCoverage.hasError('pattern')">Valid percentage is required</div>
        </div>
        <button (click)="pullDataEncompass()" class="button-refresh"><i class="bi bi-arrow-repeat"></i></button>
    </div>
    <div>
        <label class="selection-label-left-affordable">Affordable Housing Type</label><br />
        <ng-container>
            <select
                [formControl]="selectAffordableHousingType"
                class="selection-dropdown-left-affordable"
                data-test-id="affordable-housing-dropdown"
            >
                <option *ngFor="let key of affordableHousingKeys" [value]="key">
                    {{ affordableHousing[key] }}
                </option>
            </select>
        </ng-container>
    </div>
</div>

<div class="selection-block-half-width">
    <div>
        <label class="selection-label-left">FNMA's Lending Product</label><br />
        <input [formControl]="fnmaLendingProduct" type="text" class="selection-dropdown-left read-only" />
    </div>
    <div>
        <label class="selection-label-left">FHLMC Offering Identifier</label><br />
        <input [formControl]="fhlmcOfferingIdentifier" type="text" class="selection-dropdown-left read-only" />
    </div>
</div>
<div class="selection-block-three-quarter-width">
    <div>
        <label class="selection-label-left">Amortization Type</label><br />
        <input [formControl]="amortizationType" type="text" class="selection-dropdown-left read-only" />
    </div>
    <div>
        <label class="selection-label-left">LTV</label><br />
        <input [formControl]="ltvValue" type="text" class="selection-dropdown-left read-only" />
    </div>
    <div>
        <label class="selection-label-left">Term</label><br />
        <div class="inline">
            <input [formControl]="termInYears" type="text" class="term read-only" />
            <p>or</p>
            <input [formControl]="termInMonths" type="text" class="term read-only" />
        </div>
    </div>
</div>

<table>
    <thead>
        <tr>
            <th class="header"></th>
            <th class="header" colspan="2">{{ agencyAbriviation }} STANDARD COVERAGE</th>
            <th class="header" colspan="2">HOME READY / HOME POSSIBLE</th>
            <th class="header">HFA CHARTER</th>
        </tr>
        <tr>
            <th class="subheader">BASE LTV</th>
            <th class="subheader">TERM &gt; 20 YEARS OR ARM</th>
            <th class="subheader">TERM &lt;= 20 YEARS</th>
            <th class="subheader">TERM &gt; 20 YEARS OR ARM</th>
            <th class="subheader">TERM &lt;= 20 YEARS</th>
            <th class="subheader">ANY TERM</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of miTableData; index as i">
            <td>{{ row.baseLTV }}</td>
            <td
                (click)="onCellClick(row.standardCoverage.greaterThan20Years, 'row-' + i + '--standardGreater')"
                [ngClass]="{ 'green-bg': selectedCell === 'row-' + i + '--standardGreater' }"
            >
                {{ row.standardCoverage.greaterThan20Years }}
            </td>
            <td
                (click)="onCellClick(row.standardCoverage.lessThan20Years, 'row-' + i + '--standardLessThan')"
                [ngClass]="{ 'green-bg': selectedCell === 'row-' + i + '--standardLessThan' }"
            >
                {{ row.standardCoverage.lessThan20Years }}
            </td>
            <td
                (click)="onCellClick(row.specialCoverage.greaterThan20Years, 'row-' + i + '--specialCoverageGreater')"
                [ngClass]="{ 'green-bg': selectedCell === 'row-' + i + '--specialCoverageGreater' }"
            >
                {{ row.specialCoverage.greaterThan20Years }}
            </td>
            <td
                (click)="onCellClick(row.specialCoverage.lessThan20Years, 'row-' + i + '--specialCoverageLessThan')"
                [ngClass]="{ 'green-bg': selectedCell === 'row-' + i + '--specialCoverageLessThan' }"
            >
                {{ row.specialCoverage.lessThan20Years }}
            </td>
            <td
                (click)="onCellClick(row.hfaCoverage.allYears, 'row-' + i + '--hfaCoverage')"
                [ngClass]="{ 'green-bg': selectedCell === 'row-' + i + '--hfaCoverage' }"
                class="data-row-end"
            >
                {{ row.hfaCoverage.allYears }}
            </td>
        </tr>
    </tbody>
</table>
<div class="buttons-container">
    <button class="button-primary" (click)="closeModal()">Close</button>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Persona } from "src/app/models/Persona";

@Component({
    selector: "app-admin-personas-table",
    templateUrl: "./admin-personas-table.component.html",
    styleUrls: ["./admin-personas-table.component.css"]
})
export class AdminPersonasTableComponent implements OnInit {
    @Input() personas: Persona[];
    @Output() editedPersonas = new EventEmitter<any>();
    @Output() validPersonas = new EventEmitter<boolean>();

    personasTable: any;
    columnsChecked: any = {
        rateQuote: true,
        delegated: true,
        nonDelegated: true,
        contractUnderwriting: true,
        retrieveCertificate: true
    };
    valid: boolean = true;

    ngOnInit(): void {
        this.personasTable = this.personas;
        this.getColumnsChecked();
    }

    handleInput(cell: any, index: number, event: any): void {
        this.personasTable[index][cell] = event.target.checked;
        this.editedPersonas.emit(this.personasTable);
        this.validatePersonas();
    }

    validatePersonas(): void {
        // Check if at least one order type can be placed by any persona
        let valid = false;
        this.personasTable.forEach((persona: any) => {
            for (const [key, value] of Object.entries(persona)) {
                if (value && key !== "name") {
                    valid = true;
                    break;
                }
            }
        });

        this.validPersonas.emit(valid);
    }

    getColumnsChecked(): void {
        this.personasTable.forEach((persona: any) => {
            for (const [key, value] of Object.entries(persona)) {
                if (!value) {
                    this.columnsChecked[key] = false;
                }
            }
        });
    }

    checkAll(orderType: string, event: any) {
        this.personasTable.forEach((persona: any, index: number) => {
            for (const [key, value] of Object.entries(persona)) {
                if (key === orderType) {
                    this.personasTable[index][key] = event.target.checked;
                }
            }
        });
    }
}

import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { LoanData } from "../../../../app/models/loan-data";
import { SaveButtonService } from "../../../shared/save-button.service";

@Component({
    selector: "app-admin-footer",
    templateUrl: "./admin-footer.component.html",
    styleUrls: ["./admin-footer.component.css"]
})
export class AdminFooterComponent implements OnInit {
    @Input() loanData: LoanData | null = null;
    canSave: boolean = true;

    constructor(private saveButtonService: SaveButtonService) {}

    ngOnInit(): void {
        this.saveButtonService.canSave$.subscribe(value => {
            this.canSave = value;
        })
      
    }

    ngOnChanges(changes: SimpleChanges) {
        // if (changes.canSave) {
        // }
    }

    onCancel() {
        // TODO: IMPLEMENT TEMPLATE CLOSE
        console.log("cancel button was clicked");
    }

    onFormSubmit() {
        // TODO: IMPLEMENT TEMPLATE SAVE
        console.log("submit button clicked for entire form");
    }

}

import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function exactStringValidator(requiredString: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (value !== requiredString) {
            return { exactString: { value: `Value must be "${requiredString}"` } };
        }
        return null;
    };
}

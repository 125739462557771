<div class="search-rate-quote-modal">
    <form [formGroup]="tableRowSelected">
        <div class="container-search-results">
            <div class="container-search-results-header">
                <h3>Search Rate Quote ID</h3>
                <button class="close-icon" (click)="activeModal.close()">X</button>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th colspan="7"></th>
                    <th colspan="3">INITIAL PREMIUM AT CLOSING</th>
                    <th colspan="3">FIRST RENEWAL</th>
                    <th colspan="3">SECOND RENEWAL</th>
                </tr>
                <tr>
                    <th class="th-left-empty"></th>
                    <th class="th-left-empty"></th>
                    <th class="th-main"><strong>RATE QUOTE ID</strong></th>
                    <th class="th-main"><strong>PRODUCT DESCRIPTION</strong></th>
                    <th class="th-main"><strong>QUOTE TYPE</strong></th>
                    <div class="inline">
                        <th class="th-main" (click)="sortByQuoteDate()">
                            <strong>QUOTE DATE/TIME</strong>
                            <i *ngIf="sortDirection === 'asc'" class="bi bi-arrow-up"></i>
                            <i *ngIf="sortDirection === 'desc'" class="bi bi-arrow-down"></i>
                        </th>
                    </div>

                    <th class="th-main"><strong>EXPIRATION DATE</strong></th>
                    <th class="th-main"><strong>PERCENT %</strong></th>
                    <th class="th-main"><strong>AMOUNT</strong></th>
                    <th class="th-main"><strong>DURATION</strong></th>
                    <th class="th-main"><strong>PERCENT %</strong></th>
                    <th class="th-main"><strong>AMOUNT</strong></th>
                    <th class="th-main"><strong>DURATION</strong></th>
                    <th class="th-main"><strong>PERCENT %</strong></th>
                    <th class="th-main"><strong>AMOUNT</strong></th>
                    <th class="th-main"><strong>DURATION</strong></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of searchData">
                    <tr [style.background-color]="item.bgColor">
                        <td class="td-button">
                            <input
                                type="radio"
                                id="pickedLineRadial"
                                name="pickedLine"
                                [checked]="item.isSelected"
                                (click)="selectedLine(item)"
                                data-test-id="radial-button-select-row"
                                
                            />
                        </td>
                        <td class="td-button">
                            <button (click)="showDetails(item)" [style.background]="item.bgColor" class="button-no-border">
                                <i class="bi bi-caret-right"></i>
                            </button>
                        </td>
                        <td>{{ item.rateQuoteId }}</td>
                        <td>{{ item.productDescription }}</td>
                        <td>{{ item.quoteType }}</td>
                        <td>{{ item.quoteDate }}</td>
                        <td>{{ item.expirationeDate }}</td>
                        <td>{{ item.closing.percent }}</td>
                        <td>${{ item.closing.amount }}</td>
                        <td>{{ item.closing.duration }} month</td>
                        <td>{{ item.firstRenewal.percent }}</td>
                        <td>${{ item.firstRenewal.amount }}</td>
                        <td>{{ item.firstRenewal.duration }} month</td>
                        <td>{{ item.secondRenewal.percent }}</td>
                        <td>${{ item.secondRenewal.amount }}</td>
                        <td>{{ item.secondRenewal.duration }} months</td>
                    </tr>
                    <tr *ngIf="item.showDetails">
                        <td colspan="16">
                            <div class="rate-quote-details-container">
                                <p><strong>Rate Quote ID Details</strong></p>
                                <div class="rate-quote-data-block-first">
                                    <p class="rate-quote-data-label">Premium Plan</p>
                                    <p class="rate-quote-data-value">
                                        <strong>{{ sampleSearchData.premiumPlan }}</strong>
                                    </p>
                                </div>
                                <div class="rate-quote-data-block">
                                    <p class="rate-quote-data-label">Premium at Closing</p>
                                    <p class="rate-quote-data-value">
                                        <strong>{{ sampleSearchData.premiumAtClosing }}</strong>
                                    </p>
                                </div>
                                <div class="rate-quote-data-block">
                                    <p class="rate-quote-data-label">Paid By</p>
                                    <p class="rate-quote-data-value">
                                        <strong>{{ sampleSearchData.paidBy }}</strong>
                                    </p>
                                </div>
                                <div class="rate-quote-data-block">
                                    <p class="rate-quote-data-label">Refund Option</p>
                                    <p class="rate-quote-data-value">
                                        <strong>{{ sampleSearchData.refundOption }}</strong>
                                    </p>
                                </div>
                                <div class="rate-quote-data-block">
                                    <p class="rate-quote-data-label">Renewal Option</p>
                                    <p class="rate-quote-data-value">
                                        <strong>{{ sampleSearchData.renewalOption }}</strong>
                                    </p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                
                
            </tbody>
        </table>

        <div class="buttons-container">
            <button class="button-secondary" (click)="activeModal.close()">Cancel</button>
            <button [class.button-primary-disabled]="pickedLine === null" class="button-primary" (click)="onSave()">Select</button>
        </div>
    </form>
</div>

import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Branch } from "src/app/models/Branch";

@Component({
    selector: "app-branch",
    templateUrl: "./branch.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrl: "./branch.component.css"
})
export class BranchComponent implements OnInit {
    @Input() editing: boolean;
    @Input() branchToEdit: Branch;
    header: string = "Add Branch";
    branchForm: FormGroup;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder
    ) {
        this.branchForm = this.fb.group({
            branchId: new FormControl("", Validators.required),
            branchName: new FormControl("", Validators.required),
            branchDefault: new FormControl(false)
        });
    }

    ngOnInit(): void {
        if (this.editing) {
            this.setBranch();
            this.header = "Edit Branch";
        }
    }

    setBranch(): void {
        this.branchForm.setValue({
            branchId: this.branchToEdit.id,
            branchName: this.branchToEdit.name,
            branchDefault: this.branchToEdit.default
        });
    }

    onSave(): void {
        if (this.branchForm.valid) {
            this.activeModal.close(this.formatBranch());
        } else {
            this.branchForm.markAllAsTouched();
        }
    }

    formatBranch(): Branch {
        return {
            id: this.branchForm.value.branchId,
            name: this.branchForm.value.branchName,
            default: this.branchForm.value.branchDefault
        };
    }

    get branchName() {
        return this.branchForm.get("branchName");
    }

    get branchId() {
        return this.branchForm.get("branchId");
    }
}

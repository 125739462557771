import { Component, Input, OnInit } from "@angular/core";
import { ApplicationState } from "src/app/models/ApplicationState";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { RequestDataOptions } from "src/app/models/RequestDataOptions";
import { TransactionData } from "src/app/models/TransactionData";
import { TransactionRequest } from "src/app/models/TransactionRequest";
import { cancelInteraction, createTransaction } from "src/app/utilities/elli";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
    @Input() originData: OriginDataObject | null = null;
    @Input() transactionData: TransactionData | null = null;
    @Input() applicationState: ApplicationState;
    @Input() transactionRequestOptions: RequestDataOptions;

    ngOnInit(): void {}

    onCancel() {
        cancelInteraction();
    }

    async onFormSubmit() {
        const transactionRequest: TransactionRequest = {
            options: this.transactionRequestOptions,
            resources: []
        };

        console.log("Application State", this.applicationState);
        console.log("Transaction Request", transactionRequest);

        await createTransaction(transactionRequest.options, this.applicationState, "MIApplication");

        // TODO: Change to order update screen
    }
}

<div class="app-container">
    <app-admin-header [showInfo]="showInfo" (toggleInfo)="toggleInfo()"></app-admin-header>
    <div *ngIf="showAlert" id="infoAlert" class="info-alert primary-container">
        <div class="info-icon">
            <i class="bi bi-info-circle"></i>
        </div>
        <div class="info-text">
            <strong>Info</strong> — Only new orders are supported via automation at this time; order resubmissions must
            be sent using the MGIC order form in Encompass.
        </div>
        <button class="close-btn" (click)="closeAlert()">&times;</button>
    </div>
    <div class="app-body-container">
        <div class="body-container">
            <app-admin-title-template></app-admin-title-template>
            <app-admin-order-type (requestType)="handleRequestTypeChange($event)"></app-admin-order-type>
            <app-admin-order-parameters [requestTypeString]="requestTypeString" [settings]="settings" (disableSave)="handleDisableSave($event)" *ngIf="requestTypeString !== 'Multi Quote'"></app-admin-order-parameters>
            <app-admin-multi-quote *ngIf="requestTypeString === 'Multi Quote'" ></app-admin-multi-quote>
            <app-admin-footer></app-admin-footer>
        </div>
        <div class="info-popout-container">
            <app-admin-info *ngIf="showInfo" (closeInfo)="toggleInfo()"></app-admin-info>
        </div>
    </div>
</div>

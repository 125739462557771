import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MultiRateQuoteTable } from "../../../models/MultiRateQuoteTable";
import { MultiQuoteSplitPremium } from "../../../models/enums/MultiQuoteSplitPremium";
import { MultiQuotePremiumPlan } from "../../../models/enums/MultiQuotePremiumPlan";
import { MultiQuotePremiumPaidBy } from "../../../models/enums/MultiQuotePremiumPaidBy";
import { MultiQuoteRefundOption } from "../../../models/enums/MultiQuoteRefundOption";
import { MultiQuoteRenewalOption } from "src/app/models/enums/MultiQuoteRenewalOption";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";
import { SplitPremium } from "src/app/models/enums/SplitPremium";
import { FormatUtils } from "src/app/utilities/formatUtils";

@Component({
    selector: "app-admin-multi-quote",
    templateUrl: "./admin-multi-quote.component.html",
    styleUrl: "./admin-multi-quote.component.css"
})
export class AdminMultiQuoteComponent {
    @Input() originData: OriginDataObject | null = null;
    @Input() transactionData: TransactionData | null = null;
    premiumPlans = Object.values(MultiQuotePremiumPlan);
    splitPremiumUpfrontRates = Object.values(MultiQuoteSplitPremium);
    premiumPaidByOptions = Object.values(MultiQuotePremiumPaidBy);
    refundOptions = Object.values(MultiQuoteRefundOption);
    renewalOptions = Object.values(MultiQuoteRenewalOption);
    backEndDTI = this.originData?.debtRatioWithoutMi;
    frontEndDTI = this.originData?.housingRatioWithoutMi;
    cellClicked = false;
    clickedElement: string | null = null;
    clickedRowIndex: number | null = null;
    showIcon: boolean = false;
    formattedValue: string;

    initialMultiQuoteTableData: MultiRateQuoteTable[] = [
        {
            premiumPlan: MultiQuotePremiumPlan.DeferredMonthly,
            splitPremiumUpfrontRate: null,
            premiumPaidBy: MultiQuotePremiumPaidBy.BorrowerPaid,
            coveragePercentage: 30,
            refundOption: MultiQuoteRefundOption.NotRefundable,
            renewalOption: MultiQuoteRenewalOption.Constant,
            choiceMonthlyUpfrontPremium: "0",
            financePremium: false,
            specialProgramId: "",
            showIcon: false,
            isSplit: false,
            showDisabled: false
        },
        {
            premiumPlan: MultiQuotePremiumPlan.Single,
            splitPremiumUpfrontRate: null,
            premiumPaidBy: MultiQuotePremiumPaidBy.BorrowerPaid,
            coveragePercentage: 30,
            refundOption: MultiQuoteRefundOption.NotRefundable,
            renewalOption: MultiQuoteRenewalOption.NoRenewals,
            choiceMonthlyUpfrontPremium: "0",
            financePremium: false,
            specialProgramId: "",
            showIcon: false,
            isSplit: false,
            showDisabled: false
        },
        {
            premiumPlan: MultiQuotePremiumPlan.Single,
            splitPremiumUpfrontRate: null,
            premiumPaidBy: MultiQuotePremiumPaidBy.LenderPaid,
            coveragePercentage: 30,
            refundOption: MultiQuoteRefundOption.NotRefundable,
            renewalOption: MultiQuoteRenewalOption.NoRenewals,
            choiceMonthlyUpfrontPremium: "0",
            financePremium: false,
            specialProgramId: "",
            showIcon: false,
            isSplit: false,
            showDisabled: false
        }
    ];

    constructor(
        public formatUtils: FormatUtils,
        public cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.backEndDTI = this.originData?.debtRatioWithoutMi;
        this.frontEndDTI = this.originData?.housingRatioWithoutMi;
    }

    deleteRow(index: number): void {
        this.initialMultiQuoteTableData.splice(index, 1);
    }

    addRow(): void {
        const newRow = {
            premiumPlan: MultiQuotePremiumPlan.DeferredMonthly,
            splitPremiumUpfrontRate: null,
            premiumPaidBy: MultiQuotePremiumPaidBy.BorrowerPaid,
            coveragePercentage: 30,
            refundOption: MultiQuoteRefundOption.NotRefundable,
            renewalOption: MultiQuoteRenewalOption.Constant,
            choiceMonthlyUpfrontPremium: "0",
            financePremium: false,
            specialProgramId: "",
            showIcon: false,
            isSplit: false,
            showDisabled: false
        };
        this.initialMultiQuoteTableData.push(newRow);
    }

    isAddButtonDisabled(): boolean {
        return this.initialMultiQuoteTableData.length >= 6;
    }

    isDeleteButtonDisabled(): boolean {
        return this.initialMultiQuoteTableData.length <= 1;
    }

    isSplitPlan(row: any): boolean {
        const isSplit: boolean = row.premiumPlan === SplitPremium;
        if (isSplit) {
            row.splitPremiumUpfrontRate = null;
        }
        return isSplit;
    }

    onInputFocus(index: any) {
        this.clickedRowIndex = index;
    }

    isCellClicked(rowIndex: any): boolean {
        return this.clickedRowIndex === rowIndex;
    }

    onInputBlur() {
        this.clickedRowIndex = null;
    }

    validateDollar(number: string, i: number) {
        let formatedNum: string;
        formatedNum = this.formatUtils.formatDollarfromString(number);
        this.initialMultiQuoteTableData[i].choiceMonthlyUpfrontPremium = formatedNum;
    }

    keyPress(event: KeyboardEvent) {
        const pattern = /^\$?[0-9]+\.?[0-9]*$/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    printMe() {
        console.log("button clicked");
    }

    onCellClick(type: string, i: number, row: any) {
        const selectElementId = `${type}_${i}`;
        const selectElement = document.getElementById(selectElementId) as HTMLSelectElement;

        if (selectElement) {
            selectElement.focus();
            selectElement.click();
        }
        if (selectElement.toString().includes("isSplitPlanSelect")) {
            if (this.isSplitPlan(i) === false) {
                row.showDisabled = true;
            }
        } else {
            console.log("Select element not found");
        }
    }

    updateValue(row: any, key: string, event: any, rowNumber: number) {
        const value: string = event.target.value;
        row[key] = value;

        if (key === "premiumPlan") {
            row["isSplit"] = value === MultiQuotePremiumPlan.SplitPremium;
        }
    }
}

<form [formGroup]="orderParamForm">
    <div class="container-main">
        <h3>ORDER PARAMETERS</h3>
        <div class="container-param">
            <div *ngIf="showPremiumPaymentPlan" class="selection-block-quarter">
                <label class="selection-label-left"
                    >Premium Payment Plan<span class="required-field-marker">*</span></label
                >
                <br />
                <ng-container>
                    <select
                        [formControl]="selectControlPremiumPaymentPlan"
                        id="premiumPaymentPlanSelected"
                        [class.read-only]="transactionData"
                        class="selection-dropdown-left"
                        data-test-id="premium-payment-plan"
                    >
                        <option *ngIf="transactionData" value="{{ transactionData.premiumPaymentPlan }}">
                            {{ transactionData.premiumPaymentPlan }}
                        </option>
                        <option *ngFor="let plan of PremiumPaymentPlans | keyvalue" value="{{ plan.value }}">
                            {{ plan.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showSplitPremiumUpFrontRate" class="selection-block-quarter">
            <label *ngIf="selectControlPremiumPaymentPlan.value ==='Split Premium'" class="selection-label-left"
                >Split Premium Up Front Rate<span class="required-field-marker" >*</span></label
            >
            <label *ngIf="selectControlPremiumPaymentPlan.value !=='Split Premium'" class="selection-label-left"
                >Split Premium Up Front Rate</label
            >
                <br />
                <ng-container>
                    <select
                        [formControl]="selectSplitPremiumUpFrontRate"
                        id="splitPremiumRate"
                        class="selection-dropdown-left"
                        [class.read-only]="transactionData"
                        data-test-id="split-premium-up-front-rate"
                    >
                        <option *ngIf="transactionData" value="{{ transactionData.splitPremiumUpFrontRate }}">
                            {{ transactionData.splitPremiumUpFrontRate }}
                        </option>
                        <option
                            *ngFor="let splitPremiumRate of SplitPremium | keyvalue"
                            value="{{ splitPremiumRate.value }}"
                        >
                            {{ splitPremiumRate.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showPremiumPaidBy" class="selection-block-quarter">
                <label class="selection-label-left">Premium Paid By<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectPremiumPaidBy"
                        id="premiumPaidBy"
                        [class.read-only]="transactionData"
                        class="selection-dropdown-left"
                        data-test-id="premium-paid-by"
                    >
                        <option *ngIf="transactionData" value="{{ transactionData.premiumPaidBy }}">
                            {{ transactionData.premiumPaidBy }}
                        </option>
                        <option
                            *ngFor="let premiumPaidBy of PremiumPaidBy | keyvalue"
                            value="{{ premiumPaidBy.value }}"
                        >
                            {{ premiumPaidBy.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showRefundOption" class="selection-block-quarter">
                <label class="selection-label-left">Refund Option<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectRefundOption"
                        id="refundOptionSelection"
                        [class.read-only]="transactionData"
                        class="selection-dropdown-left"
                        data-test-id="refund-option"
                    >
                        <option *ngIf="transactionData" value="{{ transactionData.refundOption }}">
                            {{ transactionData.refundOption }}
                        </option>
                        <option *ngFor="let option of RefundOptions | keyvalue" value="{{ option.value }}">
                            {{ option.value }}
                        </option>
                    </select>
                    
                </ng-container>
                <div *ngIf="validateRefundOption()"
                    class="error-text"
                >
                    Refund Option must be Refundable when Premium Payment Plan is Annual
                </div>
               
                <div *ngIf="validPayeeAndRefund === false"
                class="error-text"
            >
                Refundable is available only when Borrower Paid is selected
            </div>
            </div>
        </div>
        <div class="container-param">
            <div *ngIf="showRenewalOption" class="selection-block-quarter">
                <label class="selection-label-left">Renewal Options<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectRenewalOption"
                        id="renewalOptionSelected"
                        [class.read-only]="transactionData"
                        class="selection-dropdown-left"
                        data-test-id="renewal-options"
                    >
                        <option *ngIf="transactionData" value="{{ transactionData.renewalOption }}">
                            {{ transactionData.renewalOption }}
                        </option>
                        <option
                            *ngFor="let renewalOption of RenewalOptions | keyvalue"
                            value="{{ renewalOption.value }}"
                        >
                            {{ renewalOption.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showPremiumFinanced" class="selection-block-quarter">
                <ng-container>
                    <input
                        *ngIf="transactionData"
                        [formControl]="checkBoxPremiumFinanced"
                        type="checkbox"
                        id="premiumFinanced"
                        data-test-id="premium-financed-clickbox"
                        class="locked-check-box read-only"
                    />
                    <input
                        *ngIf="!transactionData"
                        [formControl]="checkBoxPremiumFinanced"
                        type="checkbox"
                        id="premiumFinanced"
                        data-test-id="premium-financed-clickbox"
                    />
                </ng-container>
                <label class="check-box-label" for="premiumFinanced">Premium Financed</label>
            </div>
            <div *ngIf="showRelocationLoan" class="selection-block-quarter">
                <ng-container>
                    <input
                        *ngIf="transactionData"
                        [formControl]="checkBoxRelocationLoan"
                        type="checkbox"
                        id="relocationLoan"
                        data-test-id="reloaction-loan-clickbox"
                        class="locked-check-box read-only"
                    />
                    <input
                        *ngIf="!transactionData"
                        [formControl]="checkBoxRelocationLoan"
                        type="checkbox"
                        id="relocationLoan"
                        data-test-id="reloaction-loan-clickbox"
                    />
                </ng-container>
                <label class="check-box-label" for="relocationLoan">Relocation Loan</label>
            </div>
            <div *ngIf="showRush" class="selection-block-quarter">
                <ng-container>
                    <input
                        *ngIf="transactionData"
                        [formControl]="checkBoxRush"
                        type="checkbox"
                        id="rushLoan"
                        data-test-id="rush-loan-clickbox"
                        class="locked-check-box read-only"
                    />
                    <input
                        *ngIf="!transactionData"
                        [formControl]="checkBoxRush"
                        type="checkbox"
                        id="rushLoan"
                        data-test-id="rush-loan-clickbox"
                    />
                </ng-container>
                <label class="check-box-label" for="rushLoan">Rush</label>
            </div>
        </div>
        <div class="container-param">
            <div *ngIf="showSpecialProgramId" class="selection-block-quarter">
                <label class="selection-label-left" for="specialProgramId">Special Program ID</label>
                <ng-container>
                    <input
                        *ngIf="transactionData"
                        [formControl]="textInputSpecialProgramId"
                        type="text"
                        id="specialProgramId"
                        class="input-selection-id read-only"
                        data-test-id="special-program-id"
                    />
                    <input
                        *ngIf="!transactionData"
                        [formControl]="textInputSpecialProgramId"
                        type="text"
                        id="specialProgramId"
                        class="input-selection-id"
                        data-test-id="special-program-id"
                    />
                </ng-container>
            </div>
            <div *ngIf="showChoiceMonthlyUpfrontPremium" class="selection-block-quarter">
                <label class="selection-label-left" for="choiceMonthlyUpfrontPremium"
                    >Choice Monthly Upfront Premium</label
                >
                <p
                    *ngIf="!textInputChoiceMonthlyUpfrontPremium.valid && textInputChoiceMonthlyUpfrontPremium.touched"
                    class="alert-text"
                >
                    Invalid Choice Monthly Upfront Premium. Please enter numeric values only.
                </p>
                <ng-container>
                    <input
                        *ngIf="transactionData"
                        [formControl]="textInputChoiceMonthlyUpfrontPremium"
                        type="text"
                        id="choiceMonthlyUpfrontPremium"
                        class="input-selection-id read-only"
                        data-test-id="choice-monthly-upfront-premium"
                    />
                    <input
                        *ngIf="!transactionData"
                        [formControl]="textInputChoiceMonthlyUpfrontPremium"
                        type="text"
                        id="choiceMonthlyUpfrontPremium"
                        class="input-selection-id-short"
                        data-test-id="choice-monthly-upfront-premium"
                        (blur)="validateChoiceMonthlyUpfrontPremium()"
                    />
                </ng-container>
                <button
                    class="text-overlay"
                    type="button"
                    ngbPopover="Choice Monthly allows payment of an upfront premium amount you choose to lower the monthly MI premium
            rate. The Upfront Premium may be lower on the quote we provide due to rounding. We always round down to
            ensure the Upfront Premium doesn't exceed what you've requested. Please note that Choice Monthly is only
            available to customers using risk-based pricing"
                    triggers="mouseover:mouseout"
                >
                    <i
                        class="bi bi-info-circle info-icon-image"
                        alt="infoIcon"
                        (mouseover)="showText = true"
                        (mouseout)="showText = false"
                        data-test-id="info-button"
                    ></i>
                </button>
            </div>
            <div *ngIf="showGuidelines" class="selection-block-quarter">
                <label class="selection-label-left" for="selectGuidelines"> Guidelines </label>
                <ng-container>
                    <select
                        [formControl]="selectGuidelines"
                        id="guidelinesSelected"
                        class="selection-dropdown-left"
                        data-test-id="guidelines-options"
                    >
                        <option value="Fannie Mae">Fannie Mae</option>
                        <option value="Freddie Mac">Freddie Mac</option>
                    </select>
                </ng-container>
            </div>
        </div>
        <div class="container-param">
            <div *ngIf="showDebtRatioWithoutMi" class="selection-block-quarter">
                <p class="text">Debt Ratio without MI</p>
                <ng-container *ngIf="originData; else defaultDebtRatioWithoutMi">
                    <p class="text text-bold">{{ originData.debtRatioWithoutMi }}</p>
                </ng-container>
                <ng-template #defaultDebtRatioWithoutMi>
                    <p class="text text-bold">{{ backEndDTI }}</p>
                </ng-template>
            </div>
            <div *ngIf="showHousingDebtRatioWithoutMi" class="selection-block-quarter">
                <p class="text">Housing Ratio without MI</p>
                <ng-container *ngIf="originData; else defaultHousingRatioWithoutMi">
                    <p class="text text-bold">{{ originData.housingRatioWithoutMi }}</p>
                </ng-container>
                <ng-template #defaultHousingRatioWithoutMi>
                    <p class="text text-bold">{{ frontEndDTI }}</p>
                </ng-template>
            </div>
        </div>
    </div>
</form>

import { DecimalPipe, PercentPipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class FormatUtils {
    constructor(
        private decimalPipe: DecimalPipe,
        private percentPipe: PercentPipe
    ) {}

    formatDollar(num: number) {
        return "$" + this.decimalPipe.transform(num, "1.2-2");
    }

    formatDollarfromString(value: String) {
        const numericValue = Number(value.replace(/,/g, ""));
        return isNaN(numericValue) ? "" : "$" + this.decimalPipe.transform(numericValue, "1.2-2");
    }

    formatPercent(num: number, decimalPlaces: number) {
        const format = `1.${decimalPlaces}-${decimalPlaces}`;
        return this.percentPipe.transform(num, format);
    }
}

<div class="branch-container">
    <div class="branch-header">
        <div class="branch-title">{{ header }}</div>
        <div>
            <button class="close-button" (click)="activeModal.close()">x</button>
        </div>
    </div>
    <form [formGroup]="branchForm">
        <div class="form-container">
            <div class="subsection-header">Branch ID <span class="required-field-marker">*</span></div>
            <div class="subsection-content">
                <input formControlName="branchId" class="input-item" type="text" id="branchId" required />
                <div
                    *ngIf="branchId?.invalid && (branchId?.dirty || branchId?.touched)"
                    class="required-message-container"
                >
                    <div class="required-message">This field is required.</div>
                </div>
            </div>
            <div class="subsection-header">Branch Name <span class="required-field-marker">*</span></div>
            <div class="subsection-content">
                <input
                    formControlName="branchName"
                    class="input-item"
                    type="text"
                    id="branchName"
                    name="branchName"
                    required
                />
                <div
                    *ngIf="branchName?.invalid && (branchName?.dirty || branchName?.touched)"
                    class="required-message-container"
                >
                    <div class="required-message">This field is required.</div>
                </div>
            </div>
        </div>
        <div class="branch-checkbox-item-container">
            <input formControlName="branchDefault" class="checkbox-item" type="checkbox" id="branchDefault" />
            <label class="checkbox-label" for="branchDefault">Default</label>
        </div>
    </form>
    <div class="buttons-container">
        <button class="button-secondary" (click)="activeModal.close()">Close</button>
        <button class="button-primary" (click)="onSave()">Save</button>
    </div>
</div>

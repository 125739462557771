<div class="contact-info">
    <header class="small-container-inline">
        <p class="header-text">Contact Information</p>
        <button (click)="close()" class="close-button-contactinfo"><i class="bi bi-x-lg"></i></button>
    </header>
    <Body>
        <div class="small-container-inline-child">
            <img src="../../../assets/mgic-header-logo.svg" alt="mgicLogo" class="mgic-logo" />
            <a class="small-text" href="https://www.mgic.com/get-started" target="_blank" rel="noopener noreferrer"
                >New to MGIC</a
            >
        </div>
        <p class="small-text-body">
            MGIC has been providing competitive rates backed by amazing customer service since we founded the modern
            private mortgage insurance industry in 1957. In those decades, we've insured nearly 13 million mortgage
            loans. We remain committed to helping homebuyers over the down payment hurdle while helping lenders manage
            their risk.<br />
            <br />
            We take pride in being a true partner with lenders who chose MGIC for their MI loans. We'll go the extra
            mile for you - whether it's customized training, exclusive strategies and insights to help you build your
            business, or marketing and education tools to use with borrowers. Whatever's in your way, we'll help you get
            over it, around it, or roll through it. And when you call us, we'll pick up the phone.<br />
            <br />
        </p>

        <p class="small-text-contactinfo">For assistance contact MGIC:</p>
        <div class="contact-method-inline">
            <i class="bi bi-telephone"></i>
            <p class="small-text-blue">1-800-424-6442</p>
        </div>
        <div class="contact-method-inline">
            <i class="bi bi-envelope"></i>
            <p class="small-text-blue">
                <a href="mailto:customer_service&#64;mgic.com" class="custom-link">customer_service&#64;mgic.com</a>
            </p>
        </div>
        <div class="contact-method-inline">
            <i class="bi bi-globe-americas"></i>
            <p class="small-text-blue">
                <a href="https://www.mgic.com" target="blank" class="custom-link">https://www.mgic.com</a>
            </p>
        </div>
    </Body>
</div>

<div class="main-container">
    <header>
        <h3>ORDER STATUS</h3>
        <button class="header-button" (click)="openMessages()">Open All Messages</button>
    </header>
    <div class="title-row">
        <div class="left-title">
            <p>STATUS</p>
        </div>
        <div class="right-title">
            <p>DESCRIPTION</p>
        </div>
    </div>
    <div class="status-row">
        <div class="status-left">
            <p>{{ transactionData?.status?.state }}</p>
        </div>
        <div class="status-right">
            <p>{{ transactionData?.status?.message }}</p>
        </div>
    </div>
</div>

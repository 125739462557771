import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { LoanData } from "../../../../app/models/loan-data";
import { SaveButtonService } from "../../../shared/save-button.service";
import { closeAdminInteraction, createTransactionTemplate } from "src/app/utilities/elli";
import { TransactionTemplate } from "src/app/models/TransactionTemplate";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "app-admin-footer",
    templateUrl: "./admin-footer.component.html",
    styleUrls: ["./admin-footer.component.css"]
})
export class AdminFooterComponent implements OnInit {
    @Input() loanData: LoanData | null = null;
    @Input() adminApoTitle: string;
    @Input() requestType: string;
    @Input() transactionTemplate: TransactionTemplate;
    canSave: boolean = true;

    constructor(private saveButtonService: SaveButtonService) {}

    ngOnInit(): void {
        this.saveButtonService.canSave$.subscribe((value: any) => {
            this.canSave = value;
        });
    }

    onCancel() {
        closeAdminInteraction();
    }

    onFormSubmit() {
        console.log(this.transactionTemplate);
        createTransactionTemplate(this.transactionTemplate);
    }
}

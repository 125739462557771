import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { RequestType } from "src/app/models/enums/RequestType";
import { SearchRateQuoteIdComponent } from "../search-rate-quote-id/search-rate-quote-id.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import AdminSettings from "src/app/models/AdminSettings";
import { OriginData } from "src/app/models/OriginData";
import { TransactionData } from "src/app/models/TransactionData";

@Component({
    selector: "app-order-type",
    templateUrl: "./order-type.component.html",
    styleUrls: ["./order-type.component.css"]
})
export class OrderTypeComponent implements OnInit, OnChanges {
    @Input() persona: string;
    @Input() settings: AdminSettings;
    @Input() originData: OriginData | null = null;
    @Input() transactionData: TransactionData | null = null;
    @Input() requestTypeInput: string;
    @Output() requestType = new EventEmitter<String>();

    modalRef?: NgbModalRef;
    requestTypeList: any = Object.keys(RequestType) as (keyof typeof RequestType)[];
    requestTypeEnum: any = RequestType;
    selectControlRequestType = new FormControl(RequestType.RateQuote);
    certificateNumberToRetrieve = new FormControl("");
    requestTypeString: any = null;
    searchIdForm = new FormControl();
    showSearchComponent = false;
    showRateQuoteId = false;
    showCertificateRetrieval: boolean = false;
    orderTypeForm: FormGroup;
    loading = true;

    constructor(
        private modalService: NgbModal,
        private orderTypeFB: FormBuilder
    ) {
        this.orderTypeForm = this.orderTypeFB.group({
            requestTypeForm: new FormControl({ value: "", disabled: false }, Validators.required),
            searchIdForm: new FormControl({ value: "", disabled: false })
        });
    }

    ngOnInit(): void {
        if (this.settings && !this.loading) {
            this.getRequestTypes();
        }
        this.getFields();

        this.selectControlRequestType.valueChanges.subscribe(value => {
            this.requestTypeString = value !== null ? value : RequestType.RateQuote;
            this.requestType.emit(this.requestTypeString);

            if (
                this.requestTypeString === RequestType.DelegatedMi ||
                this.requestTypeString === RequestType.NonDelegatedMi ||
                this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
                this.requestTypeString === RequestType.ContractUnderwritingWithMi
            ) {
                this.searchIdForm.enable();
                if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                    this.searchIdForm.disable();
                }
                this.showCertificateRetrieval = false;
                this.showRateQuoteId = true;
            } else {
                if (this.requestTypeString === RequestType.RetrieveCert) {
                    this.showCertificateRetrieval = true;
                }
                this.showRateQuoteId = false;
            }

            if (this.requestTypeString !== RequestType.ContractUnderwritingWithoutMi) {
                this.searchIdForm.enable();
            }
        });

        this.loadLoanData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.loading) {
            if (changes.settings) {
                this.getRequestTypes();
            }
        }
    }

    searchById(): void {
        this.showSearchComponent = !this.showSearchComponent;
    }

    onSubmit(): void {
        this.showSearchComponent = !this.showSearchComponent;
    }

    getRequestTypes(): void {
        // Set list of request types based on admin settings
        const personaRequests: any = this.settings.personas.filter(p => {
            return p.name === this.persona;
        });

        const listOfRequestTypes = [];

        if (personaRequests.length) {
            const requests = personaRequests[0];

            if (requests.delegated) {
                listOfRequestTypes.push(
                    Object.keys(RequestType)[Object.values(RequestType).indexOf(RequestType.DelegatedMi)]
                );
            }

            if (requests.nonDelegated) {
                listOfRequestTypes.push(
                    Object.keys(RequestType)[Object.values(RequestType).indexOf(RequestType.NonDelegatedMi)]
                );
            }

            if (requests.rateQuote) {
                listOfRequestTypes.push(
                    Object.keys(RequestType)[Object.values(RequestType).indexOf(RequestType.RateQuote)]
                );

                listOfRequestTypes.push(
                    Object.keys(RequestType)[Object.values(RequestType).indexOf(RequestType.MultiQuote)]
                );
            }

            if (requests.contractUnderwriting) {
                listOfRequestTypes.push(
                    Object.keys(RequestType)[Object.values(RequestType).indexOf(RequestType.ContractUnderwritingWithMi)]
                );

                listOfRequestTypes.push(
                    Object.keys(RequestType)[
                        Object.values(RequestType).indexOf(RequestType.ContractUnderwritingWithoutMi)
                    ]
                );
            }

            if (requests.retrieveCertificate) {
                listOfRequestTypes.push(
                    Object.keys(RequestType)[Object.values(RequestType).indexOf(RequestType.RetrieveCert)]
                );
            }

            this.getSelectedOrderType(listOfRequestTypes);
        }
    }

    getSelectedOrderType(requestTypeList: string[]): void {
        if (requestTypeList.length > 0) {
            const key: string = requestTypeList[0];
            const value: string = RequestType[key as keyof typeof RequestType];

            this.requestTypeString = value;
            this.requestTypeList = requestTypeList;
            this.selectControlRequestType.setValue(this.requestTypeString);
        }
    }

    getFields(): void {
        if (
            this.requestTypeString === RequestType.DelegatedMi ||
            this.requestTypeString === RequestType.NonDelegatedMi ||
            this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
            this.requestTypeString === RequestType.ContractUnderwritingWithMi
        ) {
            if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                this.searchIdForm.disable();
            }
            this.showCertificateRetrieval = false;
            this.showRateQuoteId = true;
        } else {
            if (this.requestTypeString === RequestType.RetrieveCert) {
                this.showCertificateRetrieval = true;
            }
            this.showRateQuoteId = false;
        }

        if (this.requestTypeString !== RequestType.ContractUnderwritingWithoutMi) {
            this.searchIdForm.enable();
        }
    }

    loadLoanData(): void {
        if (this.transactionData) {
            this.selectControlRequestType.setValue(this.transactionData?.requestType);
            this.selectControlRequestType.disable();
            this.searchIdForm.setValue(this.transactionData?.rateQuoteId);
            this.searchIdForm.disable();
        }
    }

    openSearchRateQuote(searchId?: String | null, requestType?: string | null): void {
        this.modalRef = this.modalService.open(SearchRateQuoteIdComponent, {
            windowClass: "search-rate-quote-id-modal",
            backdrop: "static"
        });
        this.modalRef.componentInstance.searchId = searchId;
        this.modalRef.componentInstance.requestType = requestType;
    }
}

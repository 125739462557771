import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-admin-title-template",
    templateUrl: "./admin-title-template.component.html",
    styleUrl: "./admin-title-template.component.css"
})
export class AdminTitleTemplateComponent {
    adminTitleTemplateForm: FormGroup;

    constructor(private adminTitleTemplate: FormBuilder) {
        this.adminTitleTemplateForm = this.adminTitleTemplate.group({
            templateName: ["", Validators.required]
        });
    }
}

<footer class="footer">
    <button class="cancel-button-secondary" (click)="onCancel()" id="cancelButton">Cancel</button>
    <button
        [attr.disabled]="canSave ? true : null"
        class="order-button-primary"
        [class.button-disabled]
        (click)="onFormSubmit()"
        id="submitButton"
    >
        Save
    </button>
</footer>

<form [formGroup]="orderTypeForm">
    <div class="primary-container">
        <h3>Order Type</h3>
        <div class="container-order-type">
            <div class="selection-block-quarter">
                <label class="selection-label-left">Request Type<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectControlRequestType"
                        class="selection-dropdown-left"
                        data-test-id="request-type-dropdown"
                    >
                        <option *ngFor="let key of requestTypeList" id="requestTypeForm" [value]="requestTypeEnum[key]">
                            {{ requestTypeEnum[key] }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div class="selection-block-quarter">
                <label class="selection-label-left"
                    >Master Policy Number<span class="required-field-marker">*</span></label
                >
                <br />
                <ng-container>
                    <input
                        [formControl]="masterPolicyNumberForm"
                        type="text"
                        class="input-selection-id"
                        data-test-id="master-policy-number-id"
                    />
                </ng-container>
                <button class="text-overlay" type="button" [ngbPopover]="getPopoutText()" triggers="mouseover:mouseout">
                    <i
                        class="bi bi-info-circle info-icon-image"
                        alt="infoIcon"
                        (mouseover)="showText = true"
                        (mouseout)="showText = false"
                        data-test-id="info-button"
                    ></i>
                </button>
            </div>
        </div>
    </div>
</form>

export enum AffordableHousingCodes {
    "HFA Preferred" = "07" as any,
    "HFA Preferred Risk Sharing" = "06" as any,
    "Home Ready" = "08" as any,
    "Home Possible" = "241" as any,
    "Home Possible Neighborhood Solutions" = "243" as any,
    "Home Possible Advantage" = "250" as any,
    "Home Possible Advantage for HFA" = "251" as any,
    "Relief Refinance - Open Acces" = "310" as any,
    "Enhanced Relief Refinance" = "320" as any,
    "My Community Mortgage" = "04" as any
}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AdminSettingsComponent } from "../admin-settings/admin-settings.component";
import { AdminSettings } from "src/app/models/AdminSettings";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";
import { cancelInteraction } from "src/app/utilities/elli";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit, OnChanges {
    @Input() originData: OriginDataObject | null = null;
    @Input() transactionData: TransactionData | null = null;
    @Input() adminSettings: AdminSettings;
    @Input() clientId: String;
    @Output() settings = new EventEmitter<AdminSettings>();
    @Output() openContactInfo = new EventEmitter<void>();

    title = "MGIC: New Order";
    modalRef?: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.transactionData) {
            if (this.transactionData != null && this.transactionData != undefined) {
                this.title = "MGIC: Edit Order";
            }
        }
    }

    openAdminSettings(): void {
        this.modalRef = this.modalService.open(AdminSettingsComponent, {
            windowClass: "admin-settings-modal",
            backdrop: "static"
        });

        this.modalRef.componentInstance.adminSettings = this.adminSettings;
        this.modalRef.componentInstance.clientId = this.clientId;

        this.modalRef.result.then(adminSettings => {
            if (adminSettings) {
                this.adminSettings = adminSettings;
                this.settings.emit(this.adminSettings);
            }
        });
    }

    openContactInfoPanel() {
        this.openContactInfo.emit();
    }

    handleNav() {
        cancelInteraction();
    }
}

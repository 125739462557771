import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RateData } from "./models/rate-data";
import { TransactionData } from "./models/TransactionData";
import { RequestType } from "./models/enums/RequestType";
import { AffordableHousingCodes } from "./models/enums/AffordableHousingCodes";
import { AdminSettings } from "./models/AdminSettings";

import { ApplicationApisService } from "./services/application-apis/application-apis.service";
import { EncompassApisService } from "./services/encompass-apis/encompass-apis.service";
import { ApplicationState } from "./models/ApplicationState";
import { OriginDataObject } from "./models/OriginDataObject";
import { initializeOriginationContext, refreshOriginationContext } from "./utilities/elli";
import { RequestDataOptions } from "./models/RequestDataOptions";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
    applicationState: ApplicationState = {
        originId: "",
        partnerAccessToken: "",
        transactionId: ""
    };
    persona: string = "";
    isContactInfoOpen: boolean = false;
    requestTypeString: string = RequestType.RateQuote;
    requestType = RequestType;
    settings: AdminSettings;
    title = "ui";
    originData: OriginDataObject;
    transactionData: TransactionData;
    rateData: RateData;
    showWarning: boolean = false;
    warningMessage: string = "";
    clientId: string;
    loading: boolean = false;
    transactionRequestOptions: RequestDataOptions;

    constructor(
        private cd: ChangeDetectorRef,
        private applicationApisService: ApplicationApisService,
        private encompassApisService: EncompassApisService,
        public router: Router
    ) {}

    ngOnInit(): void {
        this.intialize();
        this.handleWarning();
    }

    async intialize() {
        this.initializeTransactionRequest();
        await initializeOriginationContext(this.applicationState).then((state: any) => {
            if (state && state?.originId && state?.partnerAccessToken) {
                this.applicationState = state;
                this.retrieveEncompassData();
            } else {
                refreshOriginationContext(this.applicationState).then(state => {
                    if (state && state.originId && state.partnerAccessToken) {
                        this.applicationState = state;
                        this.retrieveEncompassData();
                    } else {
                        // TODO: Error handling
                        console.log("Error, no originId found");
                    }
                });
            }
        });
    }

    initializeTransactionRequest() {
        this.transactionRequestOptions = {
            aus: {
                ausType: "",
                frePurchaseEligibilityType: "",
                underwritingCommentsDescription: "",
                desktopUnderwriterRecommendationType: "",
                loanProspectorCreditRiskClassificationDescription: "",
                automatedUnderwritingSystemDocumentWaiverIndicator: false
            },
            tpo: {
                tpoCityName: "",
                tpoStateCode: "",
                tpoPostalCode: "",
                tpoCompanyName: "",
                tpoCompanyAddress: "",
                originationType: ""
            },
            integration: {
                autoImport: false,
                miServiceMethodType: ""
            },
            miApplication: {
                investor: "",
                rateQuoteId: "",
                miServiceType: "",
                miDurationType: "",
                miCoveragePercent: 0.0,
                miLenderIdentifier: "",
                miPremiumSourceType: "",
                affordableHousingType: "",
                investorProgramNameType: "",
                miCertificateIdentifier: "",
                miPremiumRefundableType: "",
                miPremiumUpfrontPercent: 0.5,
                relocationLoanIndicator: false,
                miApplicationRequestType: RequestType.RateQuote,
                miPremiumCalculationType: "",
                rushUnderwritingIndicator: false,
                miPremiumFinancedIndicator: false,
                miSpecialPricingDescription: "",
                contractUnderwriteIdentifier: "",
                miLenderSpecialProgramDescription: "",
                investorProgramNameTypeOtherDescription: ""
            }
        };
    }

    handleRequestTypeChange(requestType: any) {
        this.requestTypeString = requestType;
        this.handleWarning();
    }

    handleSettingsChange(settings: AdminSettings) {
        this.settings = settings;
    }

    handleWarning(): void {
        switch (this.requestTypeString) {
            case RequestType.NonDelegatedMi:
                this.warningMessage = "— Documents must be attached when submitting Non Delegated order";
                this.showWarning = true;
                break;

            case RequestType.ContractUnderwritingWithMi:
                this.warningMessage = "— Documents must be attached when submitting Contract Underwriting order";
                this.showWarning = true;
                break;

            case RequestType.ContractUnderwritingWithoutMi:
                this.warningMessage = "— Documents must be attached when submitting Contract Underwriting order";
                this.showWarning = true;
                break;
            default:
                this.warningMessage = "";
                this.showWarning = false;

                break;
        }
    }

    closeWarning(): void {
        this.showWarning = false;
    }

    openContactInfo() {
        this.isContactInfoOpen = true;
    }

    closeContactInfo() {
        this.isContactInfoOpen = false;
    }

    retrieveEncompassData() {
        if (localStorage.getItem("CURRENT_EPC_TOKEN")) {
            // If we have a token stored, introspect and see if it is active
            // else, get generate new token
            this.encompassApisService.introspectToken().subscribe(
                (data: any) => {
                    if (data.active) {
                        this.retrieveTransactionData();
                    } else {
                        this.retrieveToken();
                    }
                },
                (error: any) => {
                    const status = parseInt(error.status);
                    if (status >= 400 && status < 500) {
                        this.retrieveToken();
                    }
                    if (status >= 500 && status < 600) {
                        console.log("error: ", error);
                    }
                }
            );
        } else {
            this.retrieveToken();
        }
    }

    retrieveToken() {
        this.encompassApisService.generateToken().subscribe(
            (data: any) => {
                if (data.access_token) {
                    localStorage.setItem("CURRENT_EPC_TOKEN", data.access_token);
                    this.retrieveTransactionData();
                }
            },
            (error: any) => {
                const status = parseInt(error.status);

                if (status >= 400 && status < 500) {
                    this.encompassApisService.generateToken().subscribe((data: any) => {
                        if (data.access_token) {
                            localStorage.setItem("CURRENT_EPC_TOKEN", data.access_token);
                            this.retrieveTransactionData();
                        }
                    });
                }

                if (status >= 500 && status < 600) {
                    console.log("error: ", error);
                }
            }
        );
    }

    retrieveTransactionData() {
        if (
            this.applicationState.transactionId !== "" &&
            this.applicationState.transactionId !== null &&
            this.applicationState.transactionId !== undefined
        ) {
            this.encompassApisService.getTransactionDetailsById(this.applicationState.transactionId).subscribe(
                (data: any) => {
                    this.retrieveOrderData(data);
                },
                (error: any) => {
                    const status = parseInt(error.status);
                    if (status >= 400 && status < 500) {
                        this.encompassApisService
                            .getTransactionDetailsById(this.applicationState?.transactionId)
                            .subscribe((data: any) => {
                                this.retrieveOrderData(data);
                            });
                    }
                    if (status >= 500 && status < 600) {
                        console.log("error: ", error);
                    }
                }
            );
        } else if (this.applicationState?.transactionId === "") {
            this.retrieveOriginDetails();
        }
    }

    retrieveOrderData(data: any) {
        if (data != null && data != undefined) {
            console.log(data);
            this.transactionData = {
                orderStatus: data.request.status,
                requestType: data.request.options.requestType,
                rateQuoteId: data.request.options.rateQuoteId != null ? data.request.options.rateQuoteId : "",
                receivedDocuments: data.options.resources.length,
                uploadedDocuments: data.options.resources.length,
                premiumPaymentPlan: data.request.options.miApplication.miDurationType,
                splitPremiumUpFrontRate: data.request.options.miApplication.miPremiumUpfrontPercent,
                premiumPaidBy: data.request.options.miApplication.miPremiumSourceType,
                refundOption: data.request.options.miApplication.miPremiumRefundableType,
                renewalOption: data.request.options.miApplication.miPremiumCalculationType,
                premiumFinanced: data.request.options.miApplication.miPremiumFinancedIndicator,
                relocationLoan: data.request.options.miApplication.relocationLoanIndicator,
                specialProgramId: data.request.options.miLenderSpecialProgramDescription,
                choiceMonthlyUpfrontPremium: "",
                miCoverage: data.request.options.miApplication.miCoveragePercent,
                affordableHousingType: data.request.options.miApplication.affordableHousingType,
                purchasePrice: data.request.purchasePrice,
                ausWaiver: data.request.options.aus.automatedUnderwritingSystemDocumentWaiverIndicator,
                originatorType: data.request.options.tpo.originationType,
                status: {
                    state: data.request.status,
                    message: data.request.statusMessage
                },
                loanAmount: data.request.loanAmount
            };
        }

        this.retrieveOriginDetails();
    }

    retrieveOriginDetails() {
        if (
            this.applicationState.originId !== "" &&
            this.applicationState.originId !== null &&
            this.applicationState.originId !== undefined
        ) {
            this.encompassApisService.getOriginDetails(this.applicationState?.originId, this.applicationState?.partnerAccessToken).subscribe((data: any) => {
                let updatedOriginData: OriginDataObject = data;
                updatedOriginData.isAdmin = false;
                
                const debtRatioWithoutMi: string = (
                    (data?.loan?.applications[0]?.totalPaymentsAmount /
                        data?.loan?.applications[0]?.totalGrossMonthlyIncomeAmount) *
                    100
                )
                    .toFixed(3)
                    .toString();

                const housingRatioWithoutMi: string = (
                    (data?.loan?.applications[0]?.totalPrimaryHousingExpenseAmount /
                        data?.loan?.applications[0]?.totalGrossMonthlyIncomeAmount) *
                    100
                )
                    .toFixed(3)
                    .toString();

                let affordableHousingType: string = "";
                if (data?.loan?.fnmCommunityLendingProductName) {
                    affordableHousingType =
                        Object.keys(AffordableHousingCodes)[
                            Object.values(AffordableHousingCodes).indexOf(
                                data?.loan?.fnmCommunityLendingProductName?.toString()
                            )
                        ];
                }

                let personas: string[] = [];

                if (updatedOriginData?.originatingParty?.pointOfContact?.personas &&
                    updatedOriginData?.originatingParty?.pointOfContact?.personas.length > 0
                ) {
                    // Personas come in a key value map
                    for (let i = 0; i < updatedOriginData?.originatingParty?.pointOfContact?.personas.length; i++) {
                        personas.push(updatedOriginData?.originatingParty?.pointOfContact?.personas[i]);
                    }

                    if (personas && personas.length > 0) {
                        updatedOriginData.isAdmin =
                            personas.includes("Administrator") || personas.includes("Super Administrator");
                    }
                }

                updatedOriginData.debtRatioWithoutMi = debtRatioWithoutMi;
                updatedOriginData.housingRatioWithoutMi = housingRatioWithoutMi;
                updatedOriginData.affordableHousingType = affordableHousingType;

                this.originData = updatedOriginData;

                this.retrieveAdminSettings(data.credentials.masterPolicyNumber);
            });
        }
    }

    retrieveAdminSettings(clientId: string): void {
        this.clientId = clientId;
        this.applicationApisService.getAdminSettings(clientId).subscribe(
            (data: string) => {
                this.settings = JSON.parse(data);
                this.loading = false;
            },
            (error: any) => {
                const status = parseInt(error.status);
                if (status >= 400 && status < 500) {
                    this.applicationApisService.getAdminSettings(clientId).subscribe((data: string) => {
                        this.settings = JSON.parse(data);
                        this.loading = false;
                    });
                }
                if (status >= 500 && status < 600) {
                    console.log("error: ", error);
                }
            }
        );
    }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { exactStringValidator } from "../../../utilities/validators";
import { PremiumPaymentPlan } from "../../../models/enums/PremiumPaymentPlan";
import { SplitPremium } from "../../../models/enums/SplitPremium";
import { PremiumPaidBy } from "../../../models/enums/PremiumPaidBy";
import { RefundOption } from "../../../models/enums/RefundOption";
import { RenewalOption } from "../../../models/enums/RenewalOption";
import { RequestType } from "../../../models/enums/RequestType";
import { combineLatest } from "rxjs";
import { FormatUtils } from "../../../utilities/formatUtils";
import { AdminSettings } from "../../../models/AdminSettings";
import { KeyValue } from "@angular/common";
import { SaveButtonService } from "../../../shared/save-button.service";
import { TransactionTemplate } from "src/app/models/TransactionTemplate";

@Component({
    selector: "app-admin-order-parameters",
    templateUrl: "./admin-order-parameters.component.html",
    styleUrls: ["./admin-order-parameters.component.css"]
})
export class AdminOrderParametersComponent implements OnInit {
    @Input() requestTypeString: string;
    @Input() settings: AdminSettings;
    @Input() transactionTemplate: TransactionTemplate;
    @Output() disableSave = new EventEmitter<boolean>();
    @Output() uiApoForm = new EventEmitter<FormGroup>();
    loading: boolean;

    PremiumPaymentPlans: any = PremiumPaymentPlan;
    SplitPremium: any = SplitPremium;
    PremiumPaidBy: any = PremiumPaidBy;
    RefundOptions: any = RefundOption;
    RenewalOptions: any = RenewalOption;

    selectControlPremiumPaymentPlan = new FormControl();
    selectSplitPremiumUpFrontRate = new FormControl();
    selectPremiumPaidBy = new FormControl();
    selectRefundOption = new FormControl();
    selectRenewalOption = new FormControl();
    selectGuidelines = new FormControl();
    checkBoxPremiumFinanced = new FormControl();
    checkBoxRelocationLoan = new FormControl();
    checkBoxRush = new FormControl();
    textInputSpecialProgramId = new FormControl();
    textInputChoiceMonthlyUpfrontPremium = new FormControl();
    textDebtRatioWithoutMi = new FormControl();
    textHousingRatioWithoutMi = new FormControl();

    // TODO: Calculate front and back DTI ratios
    backEndDTI = "28.342";
    frontEndDTI = "17.361";

    showText = false;
    showRush = false;
    showPremiumPaymentPlan = false;
    showSplitPremiumUpFrontRate = false;
    showPremiumPaidBy = false;
    showRefundOption = false;
    showRenewalOption = false;
    showPremiumFinanced = false;
    showRelocationLoan = false;
    showSpecialProgramId = false;
    showChoiceMonthlyUpfrontPremium = false;
    showDebtRatioWithoutMi = false;
    showHousingDebtRatioWithoutMi = false;
    showGuidelines = false;
    validPayeeAndRefund = true;
    apoFormGroup: FormGroup;

    constructor(
        private orderParamFB: FormBuilder,
        public formatUtils: FormatUtils,
        public cdr: ChangeDetectorRef,
        private saveButtonService: SaveButtonService
    ) {
        this.apoFormGroup = this.orderParamFB.group({
            selectControlPremiumPaymentPlan: ["", Validators.required],
            selectSplitPremiumUpFrontRate: [""],
            selectPremiumPaidBy: ["", Validators.required],
            selectRefundOption: ["", Validators.required],
            selectRenewalOption: ["", Validators.required],
            selectGuidelines: [""],
            checkBoxPremiumFinanced: [""],
            checkBoxRelocationLoan: [""],
            textInputSpecialProgramId: [""],
            textInputChoiceMonthlyUpfrontPremium: [""]
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showFields();
        this.enableInputs();
        
        if (changes.settings && this.settings) {
            this.loadSettings();
            if(this.transactionTemplate.name !== "") {
                this.handleTransactionTemplate()
            }
            this.requestTypeChangeListener();
        }
        if (changes.requestTypeString) {
            if (this.requestTypeString === RequestType.ContractUnderwritingWithMi) {
                this.showChoiceMonthlyUpfrontPremium = true;
            }
        }

        if (changes.requestTypeString) {
            if (
                this.requestTypeString === RequestType.DelegatedMi ||
                this.requestTypeString === RequestType.RateQuote ||
                this.requestTypeString === RequestType.NonDelegatedMi ||
                this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
                this.requestTypeString === RequestType.ContractUnderwritingWithMi
            ) {
                if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                    this.showChoiceMonthlyUpfrontPremium = false;
                    this.showRush = true;
                    this.disableInputs();
                }

                if (this.requestTypeString === RequestType.RateQuote) {
                    this.showRush = false;
                }
                if (
                    this.requestTypeString === RequestType.ContractUnderwritingWithMi ||
                    this.requestTypeString === RequestType.DelegatedMi ||
                    this.requestTypeString === RequestType.RateQuote ||
                    this.requestTypeString === RequestType.NonDelegatedMi
                ) {
                    this.showChoiceMonthlyUpfrontPremium = true;
                }
                if (
                    this.requestTypeString === RequestType.DelegatedMi ||
                    this.requestTypeString === RequestType.NonDelegatedMi ||
                    this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
                    this.requestTypeString === RequestType.ContractUnderwritingWithMi
                ) {
                    this.showRush = true;
                }
            }
            if (
                this.requestTypeString !== RequestType.ContractUnderwritingWithoutMi &&
                this.requestTypeString !== RequestType.ContractUnderwritingWithMi
            ) {
                this.showGuidelines = false;
                this.enableInputs();
            }

            if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                this.showChoiceMonthlyUpfrontPremium = false;
                this.showGuidelines = true;
            }
            if (this.requestTypeString === RequestType.ContractUnderwritingWithMi) {
                this.showChoiceMonthlyUpfrontPremium = true;
                this.showGuidelines = true;
            }

            if (this.requestTypeString === RequestType.RetrieveCert) {
                this.disableInputs();
            }
        }
        if (changes.selectControlPremiumPaymentPlan) {
            this.selectPremiumPaidBy?.valueChanges.subscribe(value => {
                if (value === PremiumPaidBy.LenderPaid) {
                    this.checkBoxPremiumFinanced?.disable();
                } else {
                    this.checkBoxPremiumFinanced?.enable();
                }
            });
        
            combineLatest([
                this.selectControlPremiumPaymentPlan?.valueChanges,
                this.selectPremiumPaidBy?.valueChanges,
            ]).subscribe(([premiumPaymentPlanValue, premiumPaidByValue]) => {
                if (
                    premiumPaymentPlanValue === PremiumPaymentPlan.DeferredMonthly &&
                    premiumPaidByValue === PremiumPaidBy.BorrowerPaid
                ) {
                    this.textInputChoiceMonthlyUpfrontPremium.enable();
                }
                if (
                    premiumPaymentPlanValue !== PremiumPaymentPlan.DeferredMonthly ||
                    premiumPaidByValue !== PremiumPaidBy.BorrowerPaid
                ) {
                    this.textInputChoiceMonthlyUpfrontPremium.disable();
                }
                   // Handle Annual Payment Plan
                if (premiumPaymentPlanValue === PremiumPaymentPlan.Annual && premiumPaidByValue === PremiumPaidBy.BorrowerPaid) {
                    this.selectRefundOption.setValue(RefundOption.Refundable);
                    this.apoFormGroup.get("premiumPaymentPlanSelected")?.setValue(RefundOption.Refundable);
                    this.selectRefundOption.addValidators([
                        Validators.required,
                        exactStringValidator(RefundOption.Refundable)
                    ]);
                    this.disableSave.emit(
                        premiumPaidByValue === PremiumPaidBy.LenderPaid &&
                        this.selectRenewalOption.value === RefundOption.NotRefundable
                    );
                } else{
                    this.selectRefundOption.setValue(RefundOption.NotRefundable);
                }
            });
        }
        combineLatest([
            this.selectRefundOption?.valueChanges,
            this.selectPremiumPaidBy?.valueChanges
        ]).subscribe(([premiumRefundOption, premiumPaidByValue]) => {
            if (
                (premiumRefundOption === RefundOption.Refundable &&
                    premiumPaidByValue === PremiumPaidBy.BorrowerPaid) || 
                (premiumRefundOption === RefundOption.NotRefundable &&
                    premiumPaidByValue === PremiumPaidBy.BorrowerPaid) ||
                (premiumRefundOption === RefundOption.NotRefundable &&
                    premiumPaidByValue === PremiumPaidBy.LenderPaid)
            ) {
                this.validPayeeAndRefund = true;
            }
            if (
                premiumRefundOption === RefundOption.Refundable &&
                premiumPaidByValue === PremiumPaidBy.LenderPaid
            ) {
                this.validPayeeAndRefund = false;
            }
        });
    }

    ngOnInit(): void {
        this.selectSplitPremiumUpFrontRate?.disable();
        this.selectSplitPremiumUpFrontRate?.setValue("");
        this.selectRefundOption?.setValue(RefundOption.NotRefundable);
        this.selectPremiumPaidBy?.valueChanges.subscribe(value => {
            if (value === PremiumPaidBy.LenderPaid) {
                this.checkBoxPremiumFinanced?.disable();
                this.selectRefundOption.setValue(RefundOption.NotRefundable);
            } else {
                this.checkBoxPremiumFinanced?.enable();
                this.selectRefundOption.setValue(RefundOption.Refundable);
            }
        });
        this.selectControlPremiumPaymentPlan?.valueChanges.subscribe(value => {
            if (value !== null) {
                if (value === PremiumPaymentPlan.SplitPremium) {
                    this.selectSplitPremiumUpFrontRate.setValue(SplitPremium.Percent050);
                    this.selectSplitPremiumUpFrontRate?.enable();
                } else {
                    this.selectSplitPremiumUpFrontRate.setValue("");
                    this.selectSplitPremiumUpFrontRate?.disable();
                }
                if(value === PremiumPaymentPlan.Annual){
                    this.selectRefundOption.setValue(RefundOption.Refundable);
                } else {
                    this.selectRefundOption.setValue(RefundOption.NotRefundable);
                }
                if (
                    value === PremiumPaymentPlan.DeferredMonthly &&
                    this.selectPremiumPaidBy.value === PremiumPaidBy.BorrowerPaid
                ) {
                    this.textInputChoiceMonthlyUpfrontPremium.enable();
                } else {
                    this.textInputChoiceMonthlyUpfrontPremium.disable();
                }
            }
        });

        combineLatest([
            this.selectControlPremiumPaymentPlan.valueChanges,
            this.selectPremiumPaidBy.valueChanges,
        ]).subscribe(([premiumPaymentPlan, premiumPaidBy]) => {
            // Handle Split Premium case
            if (premiumPaymentPlan === PremiumPaymentPlan.SplitPremium) {
                this.selectSplitPremiumUpFrontRate.setValue(SplitPremium.Percent050);
                this.selectSplitPremiumUpFrontRate.enable();
            } else {
                this.selectSplitPremiumUpFrontRate.setValue("");
                this.selectSplitPremiumUpFrontRate.disable();
            }

            // Handle Premium Paid By = Lender case
            if (premiumPaidBy === PremiumPaidBy.LenderPaid) {
                this.selectRefundOption.setValue(RefundOption.NotRefundable);
                this.checkBoxPremiumFinanced.disable();
            } else {
                this.checkBoxPremiumFinanced.enable();
            }

            // Handle Choice Monthly Upfront Premium case
            if (
                premiumPaymentPlan === PremiumPaymentPlan.DeferredMonthly &&
                premiumPaidBy === PremiumPaidBy.BorrowerPaid
            ) {
                this.textInputChoiceMonthlyUpfrontPremium.enable();
            } else {
                this.textInputChoiceMonthlyUpfrontPremium.disable();
            }

            // Handle Annual Payment Plan
            if (premiumPaymentPlan === PremiumPaymentPlan.Annual && premiumPaidBy === PremiumPaidBy.BorrowerPaid) {
                    this.selectRefundOption.setValue(RefundOption.Refundable);
                    this.selectRefundOption.addValidators([
                        Validators.required,
                        exactStringValidator(RefundOption.Refundable)
                    ]);
                    this.disableSave.emit(
                        premiumPaidBy === PremiumPaidBy.LenderPaid &&
                        this.selectRenewalOption.value === RefundOption.NotRefundable
                    );
                } else{
                    this.selectRefundOption.setValue(RefundOption.NotRefundable);
                }

        });

        if (this.requestTypeString !== RequestType.MultiQuote && this.requestTypeString !== RequestType.RetrieveCert) {
            this.showFields();
            this.showChoiceMonthlyUpfrontPremium = true;

            if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                this.showChoiceMonthlyUpfrontPremium = false;
                this.showGuidelines = true;
                this.disableInputs();
            }

            if (this.requestTypeString === RequestType.ContractUnderwritingWithMi) {
                this.showChoiceMonthlyUpfrontPremium = true;
                this.showGuidelines = true;
            }

            if (this.requestTypeString === RequestType.RateQuote) {
                this.showRush = false;
            }
        } else {
            this.showPremiumPaidBy = false;
        }

        this.apoFormGroup.valueChanges.subscribe((form: any) => {
            const miApplication = this.transactionTemplate.request.options.miApplication;
            miApplication.miDurationType = form.selectControlPremiumPaymentPlan != null ? form.selectControlPremiumPaymentPlan : "";
            miApplication.miPremiumSourceType = form.selectPremiumPaidBy ? form.selectPremiumPaidBy : "";
            miApplication.miPremiumRefundableType = form.selectRefundOption ? form.selectRefundOption : "";
            miApplication.miPremiumCalculationType = form.selectRenewalOption ? form.selectRenewalOption : "";
            miApplication.miPremiumUpfrontPercent = form.selectSplitPremiumUpFrontRate ? form.selectSplitPremiumUpFrontRate : "";
            miApplication.miPremiumFinancedIndicator = form.checkBoxPremiumFinanced ? form.checkBoxPremiumFinanced : false;
            miApplication.miSpecialPricingDescription = form.textInputSpecialProgramId ? form.textInputSpecialProgramId : "";

            this.transactionTemplate.request.options.miApplication = miApplication;

            this.handleCanSave();
        });

        if (this.transactionTemplate.name !== "") {
            this.handleTransactionTemplate()
        }
    }

    sortPaymentPlans = (x: KeyValue<number, string>, y: KeyValue<number, string>): number => {
        if (x.value === PremiumPaymentPlan.Annual) {
            return -1;
        } else {
            return 0;
        }
    };

    sortRefundOptions = (x: KeyValue<number, string>, y: KeyValue<number, string>): number => {
        return -1;
    };

    disableInputs(): void {
        this.selectControlPremiumPaymentPlan.disable();
        this.selectPremiumPaidBy.disable();
        this.selectRefundOption.disable();
        this.selectRenewalOption.disable();
        this.checkBoxPremiumFinanced.disable();
        this.checkBoxRelocationLoan.disable();
        this.textInputSpecialProgramId.disable();
        this.textInputChoiceMonthlyUpfrontPremium.disable();
    }

    enableInputs(): void {
        this.selectControlPremiumPaymentPlan.enable();
        this.selectPremiumPaidBy.enable();
        this.selectRefundOption.enable();
        this.selectRenewalOption.enable();
        if (this.selectPremiumPaidBy.value !== PremiumPaidBy.LenderPaid) {
            this.checkBoxPremiumFinanced.enable();
        }
        this.checkBoxRelocationLoan.enable();
        this.textInputSpecialProgramId.enable();
    }

    showFields(): void {
        this.showPremiumPaymentPlan = true;
        this.showSplitPremiumUpFrontRate = true;
        this.showPremiumPaidBy = true;
        this.showRefundOption = true;
        this.showRenewalOption = true;
        this.showPremiumFinanced = true;
        this.showRelocationLoan = true;
        this.showSpecialProgramId = true;
        this.showDebtRatioWithoutMi = true;
        this.showHousingDebtRatioWithoutMi = true;
        this.showRush = true;
    }

    hideFields(): void {
        this.showPremiumPaymentPlan = false;
        this.showSplitPremiumUpFrontRate = false;
        this.showPremiumPaidBy = false;
        this.showRefundOption = false;
        this.showRenewalOption = false;
        this.showPremiumFinanced = false;
        this.showRelocationLoan = false;
        this.showSpecialProgramId = false;
        this.showChoiceMonthlyUpfrontPremium = false;
        this.showDebtRatioWithoutMi = false;
        this.showHousingDebtRatioWithoutMi = false;
        this.showRush = false;
    }

    keyPress(event: KeyboardEvent) {
        const pattern = /^\$?[0-9]+\.?[0-9]*$/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    validateDollar(number: string) {
        let formatedNum: string;
        formatedNum = this.formatUtils.formatDollarfromString(number);
        this.textInputChoiceMonthlyUpfrontPremium.setValue(formatedNum);
    }

    requestTypeChangeListener() {
        if (
            this.requestTypeString === RequestType.DelegatedMi ||
            this.requestTypeString === RequestType.RateQuote ||
            this.requestTypeString === RequestType.NonDelegatedMi ||
            this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
            this.requestTypeString === RequestType.ContractUnderwritingWithMi
        ) {
            if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                this.showChoiceMonthlyUpfrontPremium = false;
                this.disableInputs();
            }

            if (this.requestTypeString === RequestType.RateQuote) {
                this.showRush = false;
            }
        }

        if (
            this.requestTypeString !== RequestType.ContractUnderwritingWithoutMi &&
            this.requestTypeString !== RequestType.ContractUnderwritingWithMi
        ) {
            this.showGuidelines = false;
            this.enableInputs();
        }

        if (
            this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
            this.requestTypeString === RequestType.ContractUnderwritingWithMi
        ) {
            this.showChoiceMonthlyUpfrontPremium = true;
            this.showGuidelines = true;
        }

        if (this.requestTypeString === RequestType.RetrieveCert) {
            this.disableInputs();
        }
    }

    parseSettings(): any {
        const newSettings = this.settings;
        const premiumPaymentPlan = [];
        let premiumPaymentPlanDefault = "";
        const splitPremium = [];
        let splitPremiumDefault = "";
        const premiumPaidBy = [];
        let premiumPaidByDefault = "";
        const refundOption = [];
        let refundOptionDefault = "";
        const renewalOption = [];
        let renewalOptionDefault = "";

        // Premium Payment Plan
        if (newSettings.premiumPaymentPlan.deferredMonthly) premiumPaymentPlan.push(PremiumPaymentPlan.DeferredMonthly);
        if (newSettings.premiumPaymentPlan.splitPremium) premiumPaymentPlan.push(PremiumPaymentPlan.SplitPremium);
        if (newSettings.premiumPaymentPlan.single) premiumPaymentPlan.push(PremiumPaymentPlan.Single);
        if (newSettings.premiumPaymentPlan.annual) premiumPaymentPlan.push(PremiumPaymentPlan.Annual);

        premiumPaymentPlanDefault = newSettings.premiumPaymentPlan.selectedDefault;

        // Split Premium
        if (newSettings.splitPremium.percent050) splitPremium.push(SplitPremium.Percent050);
        if (newSettings.splitPremium.percent075) splitPremium.push(SplitPremium.Percent075);
        if (newSettings.splitPremium.percent1) splitPremium.push(SplitPremium.Percent1);
        if (newSettings.splitPremium.percent125) splitPremium.push(SplitPremium.Percent125);
        if (newSettings.splitPremium.percent150) splitPremium.push(SplitPremium.Percent150);
        if (newSettings.splitPremium.percent175) splitPremium.push(SplitPremium.Percent175);

        splitPremiumDefault = newSettings.splitPremium.selectedDefault;

        // Premium Paid By
        if (newSettings.premiumPaidBy.borrowerPaid) premiumPaidBy.push(PremiumPaidBy.BorrowerPaid);
        if (newSettings.premiumPaidBy.lenderPaid) premiumPaidBy.push(PremiumPaidBy.LenderPaid);

        premiumPaidByDefault = newSettings.premiumPaidBy.selectedDefault;

        // Refund Option
        if (newSettings.refundOption.refundable) refundOption.push(RefundOption.Refundable);
        if (newSettings.refundOption.notRefundable) refundOption.push(RefundOption.NotRefundable);

        refundOptionDefault = newSettings.refundOption.selectedDefault;

        // Renewal Option
        if (newSettings.renewalOption.constant) renewalOption.push(RenewalOption.Constant);
        if (newSettings.renewalOption.decliningAmortizing) renewalOption.push(RenewalOption.DecliningAmortizing);
        if (newSettings.renewalOption.noRenewals) renewalOption.push(RenewalOption.NoRenewals);

        renewalOptionDefault = newSettings.renewalOption.selectedDefault;

        this.PremiumPaymentPlans = premiumPaymentPlan;
        this.selectControlPremiumPaymentPlan.setValue(premiumPaymentPlanDefault);

        this.SplitPremium = splitPremium;
        this.selectSplitPremiumUpFrontRate.setValue(splitPremiumDefault);

        this.PremiumPaidBy = premiumPaidBy;
        this.selectPremiumPaidBy.setValue(premiumPaidByDefault);

        this.RefundOptions = refundOption;
        this.selectRefundOption.setValue(refundOptionDefault);

        this.RenewalOptions = renewalOption;
        this.selectRenewalOption.setValue(renewalOptionDefault);

        if (
            premiumPaymentPlanDefault === PremiumPaymentPlan.Single &&
            premiumPaidByDefault === PremiumPaidBy.BorrowerPaid &&
            newSettings.premiumFinanced
        ) {
            this.checkBoxPremiumFinanced.setValue(true);
        }

        this.loading = false;
    }

    loadSettings(): void {
        this.apoFormGroup.reset();
        if(this.transactionTemplate.name !== ""){
            this.handleTransactionTemplate()
        }
        else { this.parseSettings();
            this.selectControlPremiumPaymentPlan.setValue(this.settings?.premiumPaymentPlan.selectedDefault);
            this.selectSplitPremiumUpFrontRate.setValue(this.settings?.splitPremium.selectedDefault);
            this.selectPremiumPaidBy.setValue(this.settings?.premiumPaidBy.selectedDefault);
            this.selectRefundOption.setValue(this.settings?.refundOption.selectedDefault);
            this.selectRenewalOption.setValue(this.settings?.renewalOption.selectedDefault);
            this.checkBoxPremiumFinanced.setValue(this.settings?.premiumFinanced);
            this.textInputChoiceMonthlyUpfrontPremium.setValue('');
        }
    }

    validateRefundOption() {
        const saveable =
            this.selectControlPremiumPaymentPlan.value === PremiumPaymentPlan.Annual &&
            this.selectPremiumPaidBy.value === PremiumPaidBy.LenderPaid &&
            this.selectRefundOption.value === RefundOption.NotRefundable;
        this.saveButtonService.setCanSave(saveable);
        return saveable;
    }

    handleTransactionTemplate(){
        const miApplication = this.transactionTemplate.request.options.miApplication;
        this.selectControlPremiumPaymentPlan.setValue(miApplication.miDurationType);
        this.selectSplitPremiumUpFrontRate.setValue(miApplication.miPremiumUpfrontPercent);
        this.selectPremiumPaidBy.setValue(miApplication.miPremiumSourceType);
        this.selectRefundOption.setValue(miApplication.miPremiumRefundableType);
        this.selectRenewalOption.setValue(miApplication.miPremiumCalculationType);
        this.checkBoxPremiumFinanced.setValue(miApplication.miPremiumFinancedIndicator);
    }

    handleCanSave() {
        const miApplication = this.transactionTemplate.request.options.miApplication;
        let saveable = true;

        saveable = miApplication.miDurationType !== "" && miApplication.miDurationType !== null &&
                miApplication.miPremiumSourceType !== "" && miApplication.miPremiumSourceType !== null &&
                miApplication.miPremiumRefundableType !== "" && miApplication.miPremiumRefundableType !== null &&
                miApplication.miPremiumCalculationType !== "" && miApplication.miPremiumCalculationType !== null;

        if (miApplication.miDurationType === PremiumPaymentPlan.SplitPremium) {
            saveable = miApplication.miPremiumUpfrontPercent !== 0 && miApplication.miPremiumUpfrontPercent !== null;
        }

        this.saveButtonService.setCanSave(saveable);
    }
}

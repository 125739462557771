import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BranchComponent } from "../branch/branch.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

// Models
import { AdminSettings } from "src/app/models/AdminSettings";
import { AdminSettingsDropdown } from "src/app/models/AdminSettingsDropdown";
import { Branch } from "src/app/models/Branch";
import { MiCoverageBracket } from "src/app/models/MiCoverageBracket";
import { Persona } from "src/app/models/Persona";
import { AdminApiRequest } from "src/app/models/AdminApiRequest";

// Enums
import { PremiumPaidBy } from "src/app/models/enums/PremiumPaidBy";
import { RefundOption } from "src/app/models/enums/RefundOption";
import { RenewalOption } from "src/app/models/enums/RenewalOption";
import { PremiumPaymentPlan } from "src/app/models/enums/PremiumPaymentPlan";
import { SplitPremium } from "src/app/models/enums/SplitPremium";
import { MiCoverageType } from "src/app/models/enums/MiCoverageType";
import { ApplicationApisService } from "src/app/services/application-apis/application-apis.service";

@Component({
    selector: "app-admin-settings",
    templateUrl: "./admin-settings.component.html",
    styleUrls: ["./admin-settings.component.css"],
    template: `<div *ngFor="let item of data">{{ item }}</div>`
})
export class AdminSettingsComponent implements OnInit {
    @Input() adminSettings: AdminSettings;
    @Input() clientId: string;
    modalRef?: NgbModalRef;
    adminSettingsForm: FormGroup;
    adminSettingsDropdowns: AdminSettingsDropdown = {
        premiumPaidBy: [
            { key: "borrowerPaid", label: PremiumPaidBy.BorrowerPaid, selected: true },
            { key: "lenderPaid", label: PremiumPaidBy.LenderPaid, selected: true }
        ],
        refundOption: [
            { key: "refundable", label: RefundOption.Refundable, selected: true },
            { key: "notRefundable", label: RefundOption.NotRefundable, selected: true }
        ],
        renewalOption: [
            { key: "constant", label: RenewalOption.Constant, selected: true },
            { key: "decliningAmortizing", label: RenewalOption.DecliningAmortizing, selected: true },
            { key: "noRenewals", label: RenewalOption.NoRenewals, selected: true }
        ],
        premiumPaymentPlan: [
            { key: "deferredMonthly", label: PremiumPaymentPlan.DeferredMonthly, selected: true },
            { key: "single", label: PremiumPaymentPlan.Single, selected: true },
            { key: "splitPremium", label: PremiumPaymentPlan.SplitPremium, selected: true },
            { key: "annual", label: PremiumPaymentPlan.Annual, selected: true }
        ],
        splitPremium: [
            { key: "percent050", label: SplitPremium.Percent050, selected: true },
            { key: "percent075", label: SplitPremium.Percent075, selected: true },
            { key: "percent1", label: SplitPremium.Percent1, selected: true },
            { key: "percent125", label: SplitPremium.Percent125, selected: true },
            { key: "percent150", label: SplitPremium.Percent150, selected: true },
            { key: "percent175", label: SplitPremium.Percent175, selected: true }
        ]
    };
    MiCoverageType = MiCoverageType;
    fannieMae = true;
    miTable: MiCoverageBracket[];
    personas: Persona[];
    permissionsApplied: boolean = true;
    defaultCheckedId: string = "";
    showSplitPremium: boolean = true;
    validTable: boolean = true;
    validPersonas: boolean = true;
    loading: true;

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private applicationApisService: ApplicationApisService
    ) {
        this.adminSettingsForm = this.fb.group({
            premiumPaidBy: this.fb.group(
                {
                    borrowerPaid: new FormControl(true),
                    lenderPaid: new FormControl(true),
                    selectedDefault: new FormControl(PremiumPaidBy.BorrowerPaid)
                },
                Validators.required
            ),
            refundOption: this.fb.group(
                {
                    refundable: new FormControl(true),
                    notRefundable: new FormControl(true),
                    selectedDefault: new FormControl(RefundOption.NotRefundable)
                },
                Validators.required
            ),
            renewalOption: this.fb.group(
                {
                    constant: new FormControl(true),
                    decliningAmortizing: new FormControl(true),
                    noRenewals: new FormControl(true),
                    selectedDefault: new FormControl(RenewalOption.Constant)
                },
                Validators.required
            ),
            premiumPaymentPlan: this.fb.group(
                {
                    deferredMonthly: new FormControl(true),
                    single: new FormControl(true),
                    splitPremium: new FormControl(true),
                    annual: new FormControl(true),
                    selectedDefault: new FormControl(PremiumPaymentPlan.DeferredMonthly)
                },
                Validators.required
            ),
            splitPremium: this.fb.group(
                {
                    percent050: new FormControl(true),
                    percent075: new FormControl(true),
                    percent1: new FormControl(true),
                    percent125: new FormControl(true),
                    percent150: new FormControl(true),
                    percent175: new FormControl(true),
                    selectedDefault: new FormControl(SplitPremium.Percent050)
                },
                Validators.required
            ),
            premiumFinanced: new FormControl(false)
        });
    }

    ngOnInit(): void {
        if (this.adminSettings !== null && this.adminSettings !== undefined) {
            this.setAdminSettings();
        }
    }

    setAdminSettings(): void {
        this.initializeDropdownValues();
        this.adminSettingsForm.setValue({
            premiumPaidBy: {
                borrowerPaid: this.adminSettings.premiumPaidBy.borrowerPaid,
                lenderPaid: this.adminSettings.premiumPaidBy.lenderPaid,
                selectedDefault: this.adminSettings.premiumPaidBy.selectedDefault
            },
            refundOption: {
                refundable: this.adminSettings.refundOption.refundable,
                notRefundable: this.adminSettings.refundOption.notRefundable,
                selectedDefault: this.adminSettings.refundOption.selectedDefault
            },
            renewalOption: {
                constant: this.adminSettings.renewalOption.constant,
                decliningAmortizing: this.adminSettings.renewalOption.decliningAmortizing,
                noRenewals: this.adminSettings.renewalOption.noRenewals,
                selectedDefault: this.adminSettings.renewalOption.selectedDefault
            },
            premiumPaymentPlan: {
                deferredMonthly: this.adminSettings.premiumPaymentPlan.deferredMonthly,
                single: this.adminSettings.premiumPaymentPlan.single,
                splitPremium: this.adminSettings.premiumPaymentPlan.splitPremium,
                annual: this.adminSettings.premiumPaymentPlan.annual,
                selectedDefault: this.adminSettings.premiumPaymentPlan.selectedDefault
            },
            splitPremium: {
                percent050: this.adminSettings.splitPremium.percent050,
                percent075: this.adminSettings.splitPremium.percent075,
                percent1: this.adminSettings.splitPremium.percent1,
                percent125: this.adminSettings.splitPremium.percent125,
                percent150: this.adminSettings.splitPremium.percent150,
                percent175: this.adminSettings.splitPremium.percent175,
                selectedDefault: this.adminSettings.splitPremium.selectedDefault
            },
            premiumFinanced: this.adminSettings.premiumFinanced
        });

        this.fannieMae = this.adminSettings.miCoverageType === MiCoverageType.FannieMae;
        this.miTable = this.adminSettings.miTable;
        this.personas = this.adminSettings.personas;
        this.permissionsApplied = this.adminSettings.permissionsApplied;
    }

    openBranchModal(editing: boolean, branch?: Branch): void {
        this.modalRef = this.modalService.open(BranchComponent, {
            windowClass: "branch-modal",
            backdrop: "static"
        });

        this.modalRef.componentInstance.editing = editing;
        if (editing) {
            this.modalRef.componentInstance.branchToEdit = branch;
        }

        this.modalRef.result.then(b => {
            if (b && !editing) {
                this.addBranch(b);
            } else if (b && editing) {
                if (branch) {
                    const index = this.adminSettings.branches.findIndex(
                        selectedBranch => selectedBranch.id === branch.id
                    );
                    this.adminSettings.branches[index] = {
                        id: b.id,
                        name: b.name,
                        default: b.default
                    };
                    if (b.default) {
                        this.defaultCheckedId = b.id;
                    }
                }
            }
        });
    }

    openConfirmationDialog(branch: Branch): void {
        this.modalRef = this.modalService.open(ConfirmationDialogComponent, {
            windowClass: "confirmation-dialog",
            backdrop: "static"
        });

        this.modalRef.result.then(confirm => {
            if (confirm) {
                this.deleteBranch(branch);
            }
        });
    }

    addBranch(branch: Branch): void {
        if (branch.default) {
            let currentBranches = this.adminSettings.branches;
            currentBranches.forEach(b => {
                if (b.default) {
                    b.default = false;
                }
            });
        }

        if (this.adminSettings.branches.length === 0) {
            branch.default = true;
        }

        this.adminSettings.branches.push(branch);
    }

    deleteBranch(branch: Branch): void {
        const oldBranchList = this.adminSettings.branches;
        const newBranchList = oldBranchList.filter(function (b) {
            return b.id !== branch.id;
        });

        this.adminSettings.branches = newBranchList;
    }

    getOptions(list: any) {
        return list.filter((option: any) => option.selected !== false);
    }

    isSelected(option: any, key: string) {
        return option.label === this.adminSettingsForm.get(key)?.get("selectedDefault")?.value;
    }

    getDropdownValues(section: string): any {
        const dropdownKey = section as keyof AdminSettingsDropdown;
        const availableOptions = this.adminSettingsForm.get(section)?.value;

        const keys = Object.keys(availableOptions);
        keys.forEach(key => {
            this.adminSettingsDropdowns[dropdownKey].forEach(opt => {
                if (opt.key === key && opt.key !== "selectedDefault") {
                    opt.selected = availableOptions[key];
                }
            });
        });

        const options = this.adminSettingsDropdowns[dropdownKey].filter((option: any) => option.selected !== false);
        if (options.length) {
            const option = { value: options[0].label };
            this.getDefault(section, option);
        }

        this.toggleSplitPremium();
    }

    getDefault(section: string, option: any) {
        this.adminSettingsForm.get(section)?.get("selectedDefault")?.setValue(option.value);
    }

    initializeDropdownValues(): void {
        this.getDropdownValues("premiumPaidBy");
        this.getDropdownValues("refundOption");
        this.getDropdownValues("renewalOption");
        this.getDropdownValues("premiumPaymentPlan");
        this.getDropdownValues("splitPremium");
    }

    onSave(): void {
        // Validate
        const validated: boolean = this.getValidForm() && this.validTable && this.validPersonas;

        if (validated) {
            const settings = this.formatAdminSettings();
            const adminApiRequest: AdminApiRequest = {
                clientId: this.clientId,
                adminSettings: JSON.stringify(settings)
            };

            this.applicationApisService.createAdminSettings(adminApiRequest).subscribe({
                next: response => {
                    // Handle the successful response here
                    this.activeModal.close(settings);
                },
                error: err => {
                    // Handle any errors here
                    console.error("Error:", err);
                },
                complete: () => {
                    // Handle completion here
                    console.log("Request complete");
                }
            });
            // Close modal and pass Admin Settings back
        } else {
            // TODO: Error handling
            console.log("Not valid!");
        }
    }

    validateTable(valid: boolean) {
        this.validTable = valid;
    }

    validatePersonas(valid: boolean) {
        this.validPersonas = valid;
    }

    updateAdminSettings(section: string, value: any) {
        switch (section) {
            case "miTable":
                this.miTable = value;
                break;

            case "personas":
                this.personas = value;
                break;

            default:
                break;
        }
    }

    handleApplyPermissions(event: any) {
        this.permissionsApplied = event.target.checked;
    }

    formatAdminSettings(): AdminSettings {
        const premiumPaidBy = this.adminSettingsForm.get("premiumPaidBy")?.value;
        const refundOption = this.adminSettingsForm.get("refundOption")?.value;
        const renewalOption = this.adminSettingsForm.get("renewalOption")?.value;
        const premiumPaymentPlan = this.adminSettingsForm.get("premiumPaymentPlan")?.value;
        const splitPremium = this.adminSettingsForm.get("splitPremium")?.value;

        const settings = {
            branches: this.adminSettings.branches,
            premiumPaidBy: {
                borrowerPaid: premiumPaidBy.borrowerPaid,
                lenderPaid: premiumPaidBy.lenderPaid,
                selectedDefault: premiumPaidBy.selectedDefault
            },
            refundOption: {
                refundable: refundOption.refundable,
                notRefundable: refundOption.notRefundable,
                selectedDefault: refundOption.selectedDefault
            },
            renewalOption: {
                constant: renewalOption.constant,
                decliningAmortizing: renewalOption.decliningAmortizing,
                noRenewals: renewalOption.noRenewals,
                selectedDefault: renewalOption.selectedDefault
            },
            premiumPaymentPlan: {
                deferredMonthly: premiumPaymentPlan.deferredMonthly,
                single: premiumPaymentPlan.single,
                splitPremium: premiumPaymentPlan.splitPremium,
                annual: premiumPaymentPlan.annual,
                selectedDefault: premiumPaymentPlan.selectedDefault
            },
            splitPremium: {
                percent050: splitPremium.percent050,
                percent075: splitPremium.percent075,
                percent1: splitPremium.percent1,
                percent125: splitPremium.percent125,
                percent150: splitPremium.percent150,
                percent175: splitPremium.percent175,
                selectedDefault: splitPremium.selectedDefault
            },
            premiumFinanced: this.adminSettingsForm.get("premiumFinanced")?.value,
            miCoverageType: this.fannieMae === true ? MiCoverageType.FannieMae : MiCoverageType.FreddieMac,
            miTable: this.miTable,
            personas: this.personas,
            permissionsApplied: this.permissionsApplied
        };
        return settings;
    }

    onMiCoverageClick(coverageType: MiCoverageType): void {
        coverageType === MiCoverageType.FannieMae ? (this.fannieMae = true) : (this.fannieMae = false);
    }

    onDefaultChange(eventTarget: any): string {
        this.defaultCheckedId = eventTarget.value;
        return this.defaultCheckedId;
    }

    toggleSplitPremium(): void {
        this.showSplitPremium = this.adminSettingsForm.get("premiumPaymentPlan")?.get("splitPremium")?.value;
    }

    getErrors(section: string): boolean {
        const dropdownKey = section as keyof AdminSettingsDropdown;
        const availableOptions = this.adminSettingsForm.get(section)?.value;
        let valid = false;

        const keys = Object.keys(availableOptions);
        keys.forEach(key => {
            this.adminSettingsDropdowns[dropdownKey].forEach(opt => {
                if (opt.key === key) {
                    if (availableOptions[key]) {
                        valid = true;
                    }
                }
            });
        });

        return !valid;
    }

    getValidForm(): boolean {
        const premiumPaidBy = !this.getErrors("premiumPaidBy");
        const refundOption = !this.getErrors("refundOption");
        const renewalOption = !this.getErrors("renewalOption");
        const premiumPaymentPlan = !this.getErrors("premiumPaymentPlan");
        const splitPremium = !this.getErrors("splitPremium");

        return premiumPaidBy && refundOption && renewalOption && premiumPaymentPlan && splitPremium;
    }
}

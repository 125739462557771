import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminComponent } from "./admin.component";
import { AdminHeaderComponent } from "./components/admin-header/admin-header.component";
import { AdminInfoComponent } from "./components/admin-info/admin-info.component";
import { AdminTitleTemplateComponent } from "./components/admin-title-template/admin-title-template.component";
import { AdminOrderTypeComponent } from "./components/admin-order-type/admin-order-type.component";
import { AdminOrderParametersComponent } from "./components/admin-order-parameters/admin-order-parameters.component";
import { AdminFooterComponent } from "./components/admin-footer/admin-footer.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AdminMultiQuoteComponent } from "./components/admin-multi-quote/admin-multi-quote.component";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "../app-routing.module";

@NgModule({
    declarations: [
        AdminComponent,
        AdminHeaderComponent,
        AdminInfoComponent,
        AdminTitleTemplateComponent,
        AdminOrderTypeComponent,
        AdminOrderParametersComponent,
        AdminFooterComponent,
        AdminMultiQuoteComponent
    ],
    imports: [CommonModule, AdminRoutingModule, ReactiveFormsModule, NgbModule, FormsModule]
})
export class AdminModule {}

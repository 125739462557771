import { DecimalPipe, PercentPipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class FormatUtils {
    constructor(
        private decimalPipe: DecimalPipe,
        private percentPipe: PercentPipe
    ) {}

    formatDollar(num: number) {
        return "$" + this.decimalPipe.transform(num, "1.2-2");
    }

    formatDollarReturnNumber(num: number) {
        // return  Number(this.decimalPipe.transform(num, "1.2-2"));
        let formattedValue: string;
        // First, check if the number has a decimal point
        if (num % 1 === 0) {
            // No decimal point, so add .00
            formattedValue = this.decimalPipe.transform(num, "1.2-2") || "0.00";
        } else {
            // Number already has decimal points, ensure two decimal places
            formattedValue = this.decimalPipe.transform(num, "1.2-2") || "0.00";
        }

        // Return the formatted number as a floating-point number
        return Number(formattedValue.replace(/,/g, "")); // Ensure it returns a proper number and remove commas
    }

    formatDollarfromString(value: String) {
        const numericValue = Number(value.replace(/,/g, ""));
        return isNaN(numericValue) ? "" : "$" + this.decimalPipe.transform(numericValue, "1.2-2");
    }

    formatPercent(num: number, decimalPlaces: number) {
        const format = `1.${decimalPlaces}-${decimalPlaces}`;
        return this.percentPipe.transform(num, format);
    }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class EncompassApisService {
    private baseUrl = environment.backend_base_url;
    private path = "api/encompass-api";

    constructor(private http: HttpClient) {}

    private createHeaders(): HttpHeaders {
        return new HttpHeaders().set("Authorization", `${localStorage.getItem("CURRENT_EPC_TOKEN")}`);
    }

    ping(): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/ping`);
    }

    generateToken(): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/token`);
    }

    introspectToken(): Observable<any> {
        return this.http.post(`${this.baseUrl}/${this.path}/introspect`, undefined, {
            headers: this.createHeaders()
        });
    }

    getOriginDetails(originId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/origin/${originId}`, {
            headers: this.createHeaders()
        });
    }

    getTransactionDetailsById(transactionId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/transaction/${transactionId}`, {
            headers: this.createHeaders()
        });
    }

    getTransactionResources(transactionId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/transaction/${transactionId}/resources`, {
            headers: this.createHeaders()
        });
    }

    // Will likely not be used
    getAllTransactions(): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/transactions`, { headers: this.createHeaders() });
    }

    updateTransactionResponse(transactionId: string, data: any): Observable<any> {
        return this.http.patch(`${this.baseUrl}/${this.path}/transaction/${transactionId}/response`, data, {
            headers: this.createHeaders()
        });
    }

    createResponseResources(transactionId: string, data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/${this.path}/transaction/${transactionId}/response/resources`, data, {
            headers: this.createHeaders()
        });
    }

    getTransactionResponseResources(transactionId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/transaction/${transactionId}/response/resources`, {
            headers: this.createHeaders()
        });
    }

    createTransactionEvent(transactionId: string, data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/${this.path}/transaction/${transactionId}/events`, data, {
            headers: this.createHeaders()
        });
    }

    getAllTransactionEvents(transactionId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/transaction/${transactionId}/events`, {
            headers: this.createHeaders()
        });
    }

    getTransactionEvent(transactionId: string, eventId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/${this.path}/transaction/${transactionId}/events/${eventId}`, {
            headers: this.createHeaders()
        });
    }
}

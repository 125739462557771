import { Component, Input, OnInit } from "@angular/core";
import { FormatUtils } from "../../utilities/formatUtils";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";

@Component({
    selector: "app-order-complete",
    templateUrl: "./order-complete.component.html",
    styleUrls: ["./order-complete.component.css"],
    providers: [FormatUtils]
})
export class OrderCompleteComponent implements OnInit {
    @Input() originData: OriginDataObject | null = null;
    @Input() transactionData: TransactionData | null = null;
    @Input() rateData: any | null = null;
    placeHolderTableData: any;
    selectedRow!: number;
    searchValue: string;
    searchClicked = false;
    showOrderStatus = true;
    showDocumentsReceived = false;
    showDocumentsUploaded = false;

    constructor(public formatUtils: FormatUtils) {
        this.searchValue = "";
    }

    ngOnInit(): void {
        this.placeHolderTableData = [
            {
                selected: false,
                product: "Upfront Premium",
                atClosingPercent: 0.001,
                atClosingAmount: 2500,
                atClosingDuration: 0,
                firstRenewalPercent: 0,
                firstRenewalAmount: 0,
                firstRenewalDuration: 0,
                secondRenewalPercent: 0,
                secondRenewalAmount: 0,
                secondRenewalDuration: 0
            },
            {
                selected: true,
                product: this.transactionData?.premiumPaymentPlan.valueOf,
                atClosingPercent: this.rateData?.atClosing.percentage,
                atClosingAmount: this.rateData?.atClosing.amount,
                atClosingDuration: this.rateData?.atClosing.duration,
                firstRenewalPercent: this.rateData?.firstRenewal.percentage,
                firstRenewalAmount: this.rateData?.firstRenewal.amount,
                firstRenewalDuration: this.rateData?.firstRenewal.duration,
                secondRenewalPercent: this.rateData?.secondRenewal.percentage,
                secondRenewalAmount: this.rateData?.secondRenewal.amount,
                secondRenewalDuration: this.rateData?.secondRenewal.duration
            }
        ];
        console.log("Placeholder Table Data: ", this.placeHolderTableData);
        this.selectedRow = this.placeHolderTableData.findIndex((row: any) => row.selected === true).toString();
    }

    checkStatus() {
        console.log("check status button clicked");
    }

    resubmitOrder() {
        console.log("resubmit order button clicked");
    }

    openSettings() {
        console.log("settings button clicked");
    }

    searchButton(searchValue: string) {
        this.searchClicked = true;
        if (searchValue === "") {
            this.searchValue = "";
        } else {
            console.log("search button clicked with value: ", searchValue);
            this.searchValue = "";
            this.searchClicked = false;
        }
    }

    messageLog() {
        console.log("message log clicked");
    }

    toggleOrderStatus() {
        this.showOrderStatus = true;
        this.showDocumentsReceived = false;
        this.showDocumentsUploaded = false;
    }

    toggleDocumentsReceived() {
        this.showOrderStatus = false;
        this.showDocumentsReceived = true;
        this.showDocumentsUploaded = false;
    }

    toggleDocumentsRequested() {
        this.showOrderStatus = false;
        this.showDocumentsReceived = false;
        this.showDocumentsUploaded = true;
    }
}

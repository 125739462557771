import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { RequestType } from "src/app/models/enums/RequestType";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TransactionTemplate } from "src/app/models/TransactionTemplate";

@Component({
    selector: "app-admin-order-type",
    templateUrl: "./admin-order-type.component.html",
    styleUrls: ["./admin-order-type.component.css"]
})
export class AdminOrderTypeComponent implements OnInit, OnChanges {
    @Input() requestTypeInput: string;
    @Input() transactionTemplate: TransactionTemplate;
    @Output() requestType = new EventEmitter<String>();
    requestTypeList: any = Object.keys(RequestType) as (keyof typeof RequestType)[];
    requestTypeEnum: any = RequestType;
    selectControlRequestType = new FormControl(RequestType.RateQuote);
    // TODO: get this value from credentials
    masterPolicyNumberForm = new FormControl("");
    providerName: string = "";
    requestTypeString: any = null;
    orderTypeForm: FormGroup;
    showText = false;

    constructor(private orderTypeFB: FormBuilder) {
        this.orderTypeForm = this.orderTypeFB.group({
            requestTypeForm: new FormControl({ value: "", disabled: false }, Validators.required),
            masterPolicyNumberForm: new FormControl({ value: "", disabled: false })
        });
    }

    ngOnInit(): void {
        this.getRequestTypes();

        this.selectControlRequestType.valueChanges.subscribe(value => {
            this.requestTypeString = value !== null ? value : RequestType.RateQuote;
            this.requestType.emit(this.requestTypeString);
        });

        // TODO: Get from ???
        this.providerName = "Provider Name Placeholder";
        if(this.transactionTemplate.name !== ""){
            let requestStrng = this.mapTemplateToRequestType(this.transactionTemplate.request.type)
            this.selectControlRequestType.setValue(requestStrng);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings) {
            this.getRequestTypes();
        }
    }

    getRequestTypes(): void {
        // Remove Retrieve Certificate
        let requestTypeKeys = Object.keys(this.requestTypeEnum);
        const retrieveCertKey = Object.keys(this.requestTypeEnum).find(
            key => this.requestTypeEnum[key] === RequestType.RetrieveCert
        );

        if (retrieveCertKey) {
            const retrieveCertIndex = requestTypeKeys.indexOf(retrieveCertKey);
            requestTypeKeys.splice(retrieveCertIndex, 1);

            this.getSelectedOrderType(requestTypeKeys);
        }
    }

    getSelectedOrderType(requestTypeList: string[]): void {
        if (requestTypeList.length > 0) {
            const key: string = requestTypeList[0];
            const value: string = RequestType[key as keyof typeof RequestType];

            this.requestTypeString = value;
            this.requestTypeList = requestTypeList;
            this.selectControlRequestType.setValue(this.requestTypeString);
        }
    }

    getPopoutText(): string {
        return (
            "Master Policy Number for the automated order is required, and must match one the MPN " +
            "values defined in the product credentials for " +
            this.providerName
        );
    }

    // default is to set to rate quote to emulate defualt if admin settings not retrieved
    mapTemplateToRequestType(value: string): RequestType {
        switch (value) {
            case "Contract Underwriting with MI":
                return RequestType.ContractUnderwritingWithMi;
            case "Contract Underwriting without MI":
                return RequestType.ContractUnderwritingWithoutMi;
            case "Delegated MI":
                return RequestType.DelegatedMi;
            case "Non-Delegated MI":
                return RequestType.NonDelegatedMi;
            case "Multi Quote":
                return RequestType.MultiQuote;
            case "Rate Quote":
                return RequestType.RateQuote;
            default: 
                return RequestType.RateQuote;
        }
    }
}

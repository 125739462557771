import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "app-admin-info",
    templateUrl: "./admin-info.component.html",
    styleUrl: "./admin-info.component.css"
})
export class AdminInfoComponent {
    @Output() closeInfo = new EventEmitter<void>();

    onClose() {
        this.closeInfo.emit();
    }
}

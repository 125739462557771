<form [formGroup]="orderTypeForm" (ngSubmit)="onSubmit()">
    <div class="primary-container">
        <h3>ORDER TYPE</h3>
        <div class="container-order-type">
            <div class="selection-block-quarter">
                <label class="selection-label-left">Request Type<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectControlRequestType"
                        [class.read-only]="transactionData"
                        class="selection-dropdown-left"
                        data-test-id="request-type-dropdown"
                    >
                        <option *ngIf="transactionData" id="requestTypeForm" selected>
                            {{ transactionData.requestType }}
                        </option>
                        <option *ngFor="let key of requestTypeList" id="requestTypeForm" [value]="requestTypeEnum[key]">
                            {{ requestTypeEnum[key] }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showCertificateRetrieval" class="selection-block-quarter">
                <label class="selection-label-left"
                    >Certificate # to Retrieve<span class="required-field-marker">*</span></label
                >
                <ng-container>
                    <input
                        [formControl]="certificateNumberToRetrieve"
                        type="text"
                        class="input-selection-id"
                        data-test-id="certificate-number-input"
                    />
                </ng-container>
            </div>
            <div *ngIf="showRateQuoteId" class="selection-block-quarter">
                <label class="selection-label-left">Rate Quote ID</label> <br />
                <ng-container>
                    <input
                        *ngIf="transactionData"
                        [formControl]="searchIdForm"
                        [class.read-only]="transactionData"
                        type="text"
                        class="input-selection-id"
                        data-test-id="rate-quote-id"
                    />
                    <input
                        *ngIf="!transactionData"
                        [formControl]="searchIdForm"
                        type="text"
                        class="input-selection-id"
                    />
                </ng-container>
            </div>
            <div *ngIf="showRateQuoteId" class="selection-block-quarter">
                <button
                    type="submit"
                    [disabled]="orderTypeForm.get('searchIdForm')?.disabled ?? true"
                    class="search-button"
                    id="searchById"
                    data-test-id="rate-quote-search-pop-out"
                    (click)="openSearchRateQuote(searchIdForm.value, selectControlRequestType.value)"
                >
                    <i class="bi bi-search search-button-image" alt="rateQuoteSearch"></i>
                </button>
            </div>
        </div>
    </div>
</form>

import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "app-contact-info",
    templateUrl: "./contact-info.component.html",
    styleUrl: "./contact-info.component.css"
})
export class ContactInfoComponent {
    @Output() closeSidebar = new EventEmitter<void>();

    close() {
        this.closeSidebar.emit();
    }
}

<div class="container-main">
    <div class="container-inline">
        <h3>MULTI RATE QUOTE</h3>
        <button
            (click)="addRow()"
            [disabled]="isAddButtonDisabled()"
            [ngClass]="{ 'disabled-button': isAddButtonDisabled() }"
            class="blue"
        >
            <i class="bi bi-plus-lg"></i>
        </button>
    </div>

    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>PREMIUM PLAN</th>
                    <th>SPLIT PREMIUM UPFRONT RATE</th>
                    <th>PREMIUM PAID BY</th>
                    <th>REFUND OPTION</th>
                    <th>RENEWAL OPTION</th>
                    <th>CHOICE MONTHLY UPFRONT PREMIUM</th>
                    <th>FINANCE PREMIUM</th>
                    <th>SPECIAL PROGRAM ID</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of initialMultiQuoteTableData; let i = index">
                    <td
                        (mouseenter)="row.showIcon = true"
                        (mouseleave)="row.showIcon = false"
                        (click)="onCellClick('premiumPlanSelect', i, row)"
                    >
                        <label class="full-width">
                            <select
                                id="premiumPlanSelect_{{ i }}"
                                [ngModel]="row.premiumPlan"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'premiumPlan', $event, i)"
                            >
                                <option *ngFor="let plan of premiumPlans" [value]="plan">{{ plan }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td
                        [class.disabled-cell]="!row.isSplit ? true : false"
                        (mouseenter)="!row.isSplit ? (row.showIcon = true) : null"
                        (mouseleave)="row.showIcon = false"
                        (click)="onCellClick('isSplitPlanSelect', i, row)"
                    >
                        <label class="full-width">
                            <select
                                id="isSplitPlanSelect_{{ i }}"
                                [disabled]="!row.isSplit"
                                [class.disabled-select]="row.showDisabled"
                                [(ngModel)]="row.splitPremiumUpfrontRate"
                                (change)="
                                    updateValue(initialMultiQuoteTableData[i], 'splitPremiumUpfrontRate', $event, i)
                                "
                            >
                                >
                                <option [value]="null" selected hidden>0</option>
                                <option *ngFor="let split of splitPremiumUpfrontRates" [value]="split">
                                    {{ split }}
                                </option>
                            </select>
                        </label>

                        <span class="edit-icon" *ngIf="row.showIcon && isSplitPlan(row)">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td
                        (mouseenter)="row.showIcon = true"
                        (mouseleave)="row.showIcon = false"
                        (click)="onCellClick('premiumPaidBySelect', i, row)"
                    >
                        <label class="full-width">
                            <select
                                id="premiumPaidBySelect_{{ i }}"
                                [(ngModel)]="row.premiumPaidBy"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'premiumPaidBy', $event, i)"
                            >
                                <option *ngFor="let payee of premiumPaidByOptions" [value]="payee">{{ payee }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false">
                        <label class="full-width">
                            <select
                                [(ngModel)]="row.refundOption"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'refundOption', $event, i)"
                            >
                                <option *ngFor="let refund of refundOptions" [value]="refund">{{ refund }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false">
                        <label class="full-width">
                            <select
                                [(ngModel)]="row.renewalOption"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'renewalOption', $event, i)"
                            >
                                <option *ngFor="let renewal of renewalOptions" [value]="renewal">{{ renewal }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td
                        (mouseenter)="row.showIcon = true"
                        (mouseleave)="row.showIcon = false"
                        (click)="onCellClick('choiceMonthlyUpfrontPremiumSelect', i, row)"
                    >
                        <label class="full-width"
                            ><div class="inline">
                                <input
                                    id="choiceMonthlyUpfrontPremiumSelect_{{ i }}"
                                    type="string"
                                    [(ngModel)]="row.choiceMonthlyUpfrontPremium"
                                    (blur)="
                                        validateDollar(initialMultiQuoteTableData[i].choiceMonthlyUpfrontPremium, i)
                                    "
                                    (keypress)="keyPress($event)"
                                    (change)="
                                        updateValue(
                                            initialMultiQuoteTableData[i],
                                            'choiceMonthlyUpfrontPremium',
                                            $event,
                                            i
                                        )
                                    "
                                />
                            </div>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td
                        (mouseenter)="row.showIcon = true"
                        (mouseleave)="row.showIcon = false"
                        (click)="onCellClick('financePremiumSelect', i, row)"
                    >
                        <label class="full-width">
                            <input
                                id="financePremiumSelect_{{ i }}"
                                type="checkbox"
                                [(ngModel)]="row.financePremium"
                                (click)="onCellClick('financePremiumSelect', i, row)"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'financePremium', $event, i)"
                            />
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td
                        (mouseenter)="row.showIcon = true"
                        (mouseleave)="row.showIcon = false"
                        (click)="onCellClick('specialProgramIdSelect', i, row)"
                    >
                        <label class="full-width">
                            <input
                                id="specialProgramIdSelect_{{ i }}"
                                type="text"
                                [(ngModel)]="row.specialProgramId"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'specialProgramId', $event, i)"
                            />
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td>
                        <button
                            (click)="deleteRow(i)"
                            [disabled]="isDeleteButtonDisabled()"
                            [ngClass]="{ 'disabled-button': isDeleteButtonDisabled() }"
                            class="blue"
                        >
                            <i class="bi bi-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

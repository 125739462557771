<table>
    <thead>
        <tr>
            <th class="header"></th>
            <th class="header" colspan="2">FNMA STANDARD COVERAGE</th>
            <th class="header" colspan="2">FHLMC STANDARD COVERAGE</th>
            <th class="header" colspan="2">HOME READY / HOME POSSIBLE</th>
            <th class="header">HFA CHARTER</th>
        </tr>
        <tr>
            <th class="subheader">BASE LTV</th>
            <th class="subheader">TERM &gt; 20 YEARS OR ARM</th>
            <th class="subheader">TERM &lt;= 20 YEARS</th>
            <th class="subheader">TERM &gt; 20 YEARS OR ARM</th>
            <th class="subheader">TERM &lt;= 20 YEARS</th>
            <th class="subheader">ANY TERM</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of miTable; index as i">
            <td>{{ row.baseLTV }}</td>
            <td
                (click)="onCellClick(row.fnmaCoverage.greaterThan20Years, 'row-' + i + '-fnmaCoverageGreater')"
                [ngClass]="{ selected: selectedCell === 'row-' + i + '-fnmaCoverageGreater' }"
            >
                <input
                    [readonly]="!row.fnmaCoverage.greaterThan20Years.editMode"
                    class="cell-input"
                    placeholder="{{ row.fnmaCoverage.greaterThan20Years.value }}"
                    (input)="handleInput(row.fnmaCoverage.greaterThan20Years, $event.target)"
                    (blur)="onCellBlur(row.fnmaCoverage.greaterThan20Years)"
                />
                <div *ngIf="!row.fnmaCoverage.greaterThan20Years.valid" class="validation-error">
                    Please input a valid percentage between 1 to 100
                </div>
            </td>
            <td
                (click)="onCellClick(row.fnmaCoverage.lessThan20Years, 'row-' + i + '-fnmaCoverageLess')"
                [ngClass]="{ selected: selectedCell === 'row-' + i + '-fnmaCoverageLess' }"
            >
                <input
                    [readonly]="!row.fnmaCoverage.lessThan20Years.editMode"
                    class="cell-input"
                    placeholder="{{ row.fnmaCoverage.lessThan20Years.value }}"
                    (input)="handleInput(row.fnmaCoverage.lessThan20Years, $event.target)"
                    (blur)="onCellBlur(row.fnmaCoverage.lessThan20Years)"
                />
                <div *ngIf="!row.fnmaCoverage.lessThan20Years.valid" class="validation-error">
                    Please input a valid percentage between 1 to 100
                </div>
            </td>
            <td
                (click)="onCellClick(row.fhlmcCoverage.greaterThan20Years, 'row-' + i + '-fhlmcCoverageGreater')"
                [ngClass]="{ selected: selectedCell === 'row-' + i + '-fhlmcCoverageGreater' }"
            >
                <input
                    [readonly]="!row.fhlmcCoverage.greaterThan20Years.editMode"
                    class="cell-input"
                    placeholder="{{ row.fhlmcCoverage.greaterThan20Years.value }}"
                    (input)="handleInput(row.fhlmcCoverage.greaterThan20Years, $event.target)"
                    (blur)="onCellBlur(row.fhlmcCoverage.greaterThan20Years)"
                />
                <div *ngIf="!row.fhlmcCoverage.greaterThan20Years.valid" class="validation-error">
                    Please input a valid percentage between 1 to 100
                </div>
            </td>
            <td
                (click)="onCellClick(row.fhlmcCoverage.lessThan20Years, 'row-' + i + '-fhlmcCoverageLess')"
                [ngClass]="{ selected: selectedCell === 'row-' + i + '-fhlmcCoverageLess' }"
            >
                <input
                    [readonly]="!row.fhlmcCoverage.lessThan20Years.editMode"
                    class="cell-input"
                    placeholder="{{ row.fhlmcCoverage.lessThan20Years.value }}"
                    (input)="handleInput(row.fhlmcCoverage.lessThan20Years, $event.target)"
                    (blur)="onCellBlur(row.fhlmcCoverage.lessThan20Years)"
                />
                <div *ngIf="!row.fhlmcCoverage.lessThan20Years.valid" class="validation-error">
                    Please input a valid percentage between 1 to 100
                </div>
            </td>

            <td
                (click)="
                    onCellClick(
                        row.homeReadyHomePossible.greaterThan20Years,
                        'row-' + i + '-homeReadyHomePossibleGreater'
                    )
                "
                [ngClass]="{ selected: selectedCell === 'row-' + i + '-homeReadyHomePossibleGreater' }"
            >
                <input
                    [readonly]="!row.homeReadyHomePossible.greaterThan20Years.editMode"
                    class="cell-input"
                    placeholder="{{ row.homeReadyHomePossible.greaterThan20Years.value }}"
                    (input)="handleInput(row.homeReadyHomePossible.greaterThan20Years, $event.target)"
                    (blur)="onCellBlur(row.homeReadyHomePossible.greaterThan20Years)"
                />
                <div *ngIf="!row.homeReadyHomePossible.greaterThan20Years.valid" class="validation-error">
                    Please input a valid percentage between 1 to 100
                </div>
            </td>
            <td
                (click)="
                    onCellClick(row.homeReadyHomePossible.lessThan20Years, 'row-' + i + '-homeReadyHomePossibleLess')
                "
                [ngClass]="{ selected: selectedCell === 'row-' + i + '-homeReadyHomePossibleLess' }"
            >
                <input
                    [readonly]="!row.homeReadyHomePossible.lessThan20Years.editMode"
                    class="cell-input"
                    placeholder="{{ row.homeReadyHomePossible.lessThan20Years.value }}"
                    (input)="handleInput(row.homeReadyHomePossible.lessThan20Years, $event.target)"
                    (blur)="onCellBlur(row.homeReadyHomePossible.lessThan20Years)"
                />
                <div *ngIf="!row.homeReadyHomePossible.lessThan20Years.valid" class="validation-error">
                    Please input a valid percentage between 1 to 100
                </div>
            </td>
            <td
                (click)="onCellClick(row.hfaCharter.anyTerm, 'row-' + i + '-hfaCharterAnyTerm')"
                [ngClass]="{ selected: selectedCell === 'row-' + i + '-hfaCharterAnyTerm' }"
                class="data-row-end"
            >
                <input
                    [readonly]="!row.hfaCharter.anyTerm.editMode"
                    class="cell-input"
                    placeholder="{{ row.hfaCharter.anyTerm.value }}"
                    (input)="handleInput(row.hfaCharter.anyTerm, $event.target)"
                    (blur)="onCellBlur(row.hfaCharter.anyTerm)"
                />
                <div *ngIf="!row.hfaCharter.anyTerm.valid" class="validation-error">
                    Please input a valid percentage between 1 to 100
                </div>
            </td>
        </tr>
    </tbody>
</table>

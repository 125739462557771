import { Component, ChangeDetectorRef, Input } from "@angular/core";
import { RequestType } from "../models/enums/RequestType";
import AdminSettings from "../models/AdminSettings";
import { ApplicationApisService } from "../services/application-apis/application-apis.service";
import { EncompassApisService } from "../services/encompass-apis/encompass-apis.service";
import adminSettings  from "./../mocks/adminSettings.json";

@Component({
    selector: "app-admin",
    templateUrl: "./admin.component.html",
    styleUrl: "./admin.component.css"
})
export class AdminComponent {
    disableSave: boolean = true;
    showInfo = false;
    showAlert: boolean = true;
    showWarning: boolean = false;
    warningMessage: string = "";
    requestTypeString: string = RequestType.RateQuote;
    saveDisabled: boolean = false;
    settings: AdminSettings;
    loading: boolean = false;
   

    constructor(
            private cd: ChangeDetectorRef,
            private applicationApisService: ApplicationApisService,
            private encompassApisService: EncompassApisService,
        ) {
            const masterPolicyNumber = "DeployTest003"
            this.retrieveAdminSettings(masterPolicyNumber)
        }

    ngOnInit(): void {
        this.requestTypeString = RequestType.RateQuote;
    }

    handleDisableSave(saveable: any) {
        this.disableSave = saveable;
    }

    toggleInfo() {
        this.showInfo = !this.showInfo;
    }

    closeAlert() {
        this.showAlert = false;
    }

    handleRequestTypeChange(requestType: any) {
        this.requestTypeString = requestType;
    }

    handleSettingsChange(settings: AdminSettings) {
        this.settings = settings;
    }

    retrieveAdminSettings(clientId: string): void {
        // this.applicationApisService.getAdminSettings(clientId).subscribe(
        //     (data: string) => {
        //         this.settings = JSON.parse(data);
        //         this.loading = false;
        //     },
        //     (error: any) => {
        //         const status = parseInt(error.status);
        //         if (status >= 400 && status < 500) {
        //             this.applicationApisService.getAdminSettings(clientId).subscribe((data: string) => {
        //                 this.settings = JSON.parse(data);
        //                 this.loading = false;
        //             });
        //         }
        //         if (status >= 500 && status < 600) {
        //             console.log("error: ", error);
        //         }
        //     }
        // );
        this.settings = adminSettings as AdminSettings;
    }
}

import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoanData } from "../../models/loan-data";
import { exactStringValidator } from "../../utilities/validators";
import { PremiumPaymentPlan } from "../../models/enums/PremiumPaymentPlan";
import { SplitPremium } from "../../models/enums/SplitPremium";
import { PremiumPaidBy } from "../../models/enums/PremiumPaidBy";
import { RefundOption } from "../../models/enums/RefundOption";
import { RenewalOption } from "../../models/enums/RenewalOption";
import { RequestType } from "../../models/enums/RequestType";
import { combineLatest } from "rxjs";
import { FormatUtils } from "../../utilities/formatUtils";
import { AusService } from "../../models/enums/AusService";
import { AdminSettings } from "../../models/AdminSettings";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";
import { RequestDataOptions } from "src/app/models/RequestDataOptions";

@Component({
    selector: "app-order-parameters",
    templateUrl: "./order-parameters.component.html",
    styleUrls: ["./order-parameters.component.css"]
})
export class OrderParametersComponent implements OnInit {
    @Input() originData: OriginDataObject | null;
    @Input() transactionData: TransactionData | null;
    @Input() settings: AdminSettings;
    @Input() requestTypeString: string;
    @Input() transactionRequestOptions: RequestDataOptions;

    loading: boolean = true;
    //Emums for Select Drop Down Options
    PremiumPaymentPlans: any = PremiumPaymentPlan;
    SplitPremium: any = SplitPremium;
    PremiumPaidBy: any = PremiumPaidBy;
    RefundOptions: any = RefundOption;
    RenewalOptions: any = RenewalOption;

    selectControlPremiumPaymentPlan = new FormControl();
    selectSplitPremiumUpFrontRate = new FormControl();
    selectPremiumPaidBy = new FormControl();
    selectRefundOption = new FormControl();
    selectRenewalOption = new FormControl();
    selectGuidelines = new FormControl();
    checkBoxPremiumFinanced = new FormControl();
    checkBoxRelocationLoan = new FormControl();
    checkBoxRush = new FormControl();
    textInputSpecialProgramId = new FormControl();
    textInputChoiceMonthlyUpfrontPremium = new FormControl();
    textDebtRatioWithoutMi = new FormControl();
    textHousingRatioWithoutMi = new FormControl();

    // hardcoding front and back DTI ratios
    backEndDTI = "";
    frontEndDTI = "";

    showText = false;
    showRush = false;
    showPremiumPaymentPlan = false;
    showSplitPremiumUpFrontRate = false;
    showPremiumPaidBy = false;
    showRefundOption = false;
    showRenewalOption = false;
    showPremiumFinanced = false;
    showRelocationLoan = false;
    showSpecialProgramId = false;
    showChoiceMonthlyUpfrontPremium = false;
    showDebtRatioWithoutMi = false;
    showHousingDebtRatioWithoutMi = false;
    showGuidelines = false;
    validPayeeAndRefund = true;

    orderParamForm: FormGroup;

    constructor(
        private orderParamFB: FormBuilder,
        public formatUtils: FormatUtils,
        public cdr: ChangeDetectorRef
    ) {
        this.orderParamForm = this.orderParamFB.group({
            selectControlPremiumPaymentPlan: ["", Validators.required],
            selectSplitPremiumUpFrontRate: [""],
            selectPremiumPaidBy: ["", Validators.required],
            selectRefundOption: ["", Validators.required],
            selectRenewalOption: ["", Validators.required],
            selectGuidelines: [""],
            checkBoxPremiumFinanced: [""],
            checkBoxRelocationLoan: [""],
            textInputSpecialProgramId: [""],
            textInputChoiceMonthlyUpfrontPremium: [""]
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showFields();
        this.enableInputs();
        if (changes.settings && this.settings) {
            this.loadSettings();
            this.requestTypeChangeListener();
        }

        if (changes.requestTypeString) {
            if (
                this.requestTypeString === RequestType.DelegatedMi ||
                this.requestTypeString === RequestType.RateQuote ||
                this.requestTypeString === RequestType.NonDelegatedMi ||
                this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
                this.requestTypeString === RequestType.ContractUnderwritingWithMi
            ) {
                if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                    this.showChoiceMonthlyUpfrontPremium = false;
                    this.showRush = true;
                    this.disableInputs();
                }

                if (this.requestTypeString === RequestType.RateQuote) {
                    this.showRush = false;
                }
                if (
                    this.requestTypeString === RequestType.ContractUnderwritingWithMi ||
                    this.requestTypeString === RequestType.DelegatedMi ||
                    this.requestTypeString === RequestType.RateQuote ||
                    this.requestTypeString === RequestType.NonDelegatedMi
                ) {
                    this.showChoiceMonthlyUpfrontPremium = true;
                }
                if (
                    this.requestTypeString === RequestType.DelegatedMi ||
                    this.requestTypeString === RequestType.NonDelegatedMi ||
                    this.requestTypeString === RequestType.ContractUnderwritingWithoutMi ||
                    this.requestTypeString === RequestType.ContractUnderwritingWithMi
                ) {
                    this.showRush = true;
                }
            }
            if (
                this.requestTypeString !== RequestType.ContractUnderwritingWithoutMi &&
                this.requestTypeString !== RequestType.ContractUnderwritingWithMi
            ) {
                this.showGuidelines = false;
                this.enableInputs();
            }

            if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                this.showChoiceMonthlyUpfrontPremium = false;
                this.showGuidelines = true;
            }
            if (this.requestTypeString === RequestType.ContractUnderwritingWithMi) {
                this.showChoiceMonthlyUpfrontPremium = true;
                this.showGuidelines = true;
            }
            if (this.requestTypeString === RequestType.RetrieveCert) {
                this.disableInputs();
                this.showRush = false;
            }
        }
        if (changes.selectControlPremiumPaymentPlan) {
            this.selectPremiumPaidBy?.valueChanges.subscribe(value => {
                if (value === PremiumPaidBy.LenderPaid) {
                    this.checkBoxPremiumFinanced?.disable();
                } else {
                    this.checkBoxPremiumFinanced?.enable();
                }
            });

            combineLatest([
                this.selectControlPremiumPaymentPlan?.valueChanges,
                this.selectPremiumPaidBy?.valueChanges
            ]).subscribe(([premiumPaymentPlanValue, premiumPaidByValue]) => {
                if (
                    premiumPaymentPlanValue === PremiumPaymentPlan.DeferredMonthly &&
                    premiumPaidByValue === PremiumPaidBy.BorrowerPaid
                ) {
                    this.textInputChoiceMonthlyUpfrontPremium.enable();
                }
                if (
                    premiumPaymentPlanValue !== PremiumPaymentPlan.DeferredMonthly ||
                    premiumPaidByValue !== PremiumPaidBy.BorrowerPaid
                ) {
                    this.textInputChoiceMonthlyUpfrontPremium.disable();
                }
            });
        }

        combineLatest([
            this.selectRefundOption?.valueChanges, 
            this.selectPremiumPaidBy?.valueChanges
        ]).subscribe(
            ([premiumRefundOption, premiumPaidByValue]) => {
                if (
                    (premiumRefundOption === RefundOption.Refundable &&
                        premiumPaidByValue === PremiumPaidBy.BorrowerPaid) ||
                    (premiumRefundOption === RefundOption.NotRefundable &&
                        premiumPaidByValue === PremiumPaidBy.BorrowerPaid) ||
                    (premiumRefundOption === RefundOption.NotRefundable &&
                        premiumPaidByValue === PremiumPaidBy.LenderPaid)
                ) {
                    this.validPayeeAndRefund = true;
                }
                if (
                    premiumRefundOption === RefundOption.Refundable &&
                    premiumPaidByValue === PremiumPaidBy.LenderPaid
                ) {
                    this.validPayeeAndRefund = false;
                }
            }
        );
    }

    ngOnInit(): void {
        this.selectSplitPremiumUpFrontRate?.disable();

        combineLatest([
            this.selectControlPremiumPaymentPlan.valueChanges,
            this.selectPremiumPaidBy.valueChanges
        ]).subscribe(([premiumPaymentPlan, premiumPaidBy]) => {
            // Handle Split Premium case
            if (premiumPaymentPlan === PremiumPaymentPlan.SplitPremium) {
                this.selectSplitPremiumUpFrontRate.setValue(SplitPremium.Percent050);
                this.selectSplitPremiumUpFrontRate.enable();
            } else {
                this.selectSplitPremiumUpFrontRate.setValue("");
                this.selectSplitPremiumUpFrontRate.disable();
            }

            // Handle Premium Paid By = Lender case
            if (premiumPaidBy === PremiumPaidBy.LenderPaid) {
                this.selectRefundOption.setValue(RefundOption.NotRefundable);
                this.checkBoxPremiumFinanced.disable();
            } else {
                this.checkBoxPremiumFinanced.enable();
            }

            // Handle Choice Monthly Upfront Premium case
            if (
                premiumPaymentPlan === PremiumPaymentPlan.DeferredMonthly &&
                premiumPaidBy === PremiumPaidBy.BorrowerPaid
            ) {
                this.textInputChoiceMonthlyUpfrontPremium.enable();
            } else {
                this.textInputChoiceMonthlyUpfrontPremium.disable();
            }

            // Handle Annual Payment Plan
            if (premiumPaymentPlan === PremiumPaymentPlan.Annual && premiumPaidBy === PremiumPaidBy.BorrowerPaid) {
                this.selectRefundOption.setValue(RefundOption.Refundable);
                this.selectRefundOption.addValidators([
                    Validators.required,
                    exactStringValidator(RefundOption.Refundable)
                ]);
            }

            if (premiumPaymentPlan) {
                this.transactionRequestOptions.miApplication.miDurationType = premiumPaymentPlan;
            }
            if (premiumPaidBy) {
                this.transactionRequestOptions.miApplication.miPremiumSourceType = premiumPaidBy;
            }
        });

        combineLatest([
            this.selectRefundOption?.valueChanges,
            this.selectRenewalOption?.valueChanges,
            this.selectSplitPremiumUpFrontRate?.valueChanges,
            this.checkBoxPremiumFinanced?.valueChanges,
            this.checkBoxRelocationLoan?.valueChanges,
            this.checkBoxRush?.valueChanges,
            this.textInputSpecialProgramId?.valueChanges
        ]).subscribe(
            ([refundOption, renewalOption, splitPremium, premiumFinanced, relocation, rush, specialProgramId]) => {
                if (refundOption) {
                    this.transactionRequestOptions.miApplication.miPremiumRefundableType = refundOption;
                }
                if (renewalOption) {
                    this.transactionRequestOptions.miApplication.miPremiumCalculationType = renewalOption;
                }
                if (splitPremium) {
                    this.transactionRequestOptions.miApplication.miPremiumUpfrontPercent = splitPremium;
                }
                if (premiumFinanced) {
                    this.transactionRequestOptions.miApplication.miPremiumFinancedIndicator = premiumFinanced;
                }
                if (relocation) {
                    this.transactionRequestOptions.miApplication.relocationLoanIndicator = relocation;
                }
                if (rush) {
                    this.transactionRequestOptions.miApplication.rushUnderwritingIndicator = rush;
                }
                if (specialProgramId) {
                    this.transactionRequestOptions.miApplication.miSpecialPricingDescription = specialProgramId;
                }
            }
        );

        this.loadLoanData();

        if (this.requestTypeString !== RequestType.MultiQuote && this.requestTypeString !== RequestType.RetrieveCert) {
            this.showFields();
            this.showChoiceMonthlyUpfrontPremium = true;

            if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                this.showChoiceMonthlyUpfrontPremium = false;
                this.showGuidelines = true;
                this.disableInputs();
            }

            if (this.requestTypeString === RequestType.ContractUnderwritingWithMi) {
                this.showChoiceMonthlyUpfrontPremium = true;
                this.showGuidelines = true;
            }

            if (this.requestTypeString === RequestType.RateQuote) {
                this.showRush = false;
            }
        } else {
            this.showPremiumPaidBy = false;
        }
    }

    parseSettings(): any {
        const newSettings = this.settings;
        const premiumPaymentPlan = [];
        let premiumPaymentPlanDefault = "";
        const splitPremium = [];
        let splitPremiumDefault = "";
        const premiumPaidBy = [];
        let premiumPaidByDefault = "";
        const refundOption = [];
        let refundOptionDefault = "";
        const renewalOption = [];
        let renewalOptionDefault = "";

        // Premium Payment Plan
        if (newSettings.premiumPaymentPlan.deferredMonthly) premiumPaymentPlan.push(PremiumPaymentPlan.DeferredMonthly);
        if (newSettings.premiumPaymentPlan.splitPremium) premiumPaymentPlan.push(PremiumPaymentPlan.SplitPremium);
        if (newSettings.premiumPaymentPlan.single) premiumPaymentPlan.push(PremiumPaymentPlan.Single);
        if (newSettings.premiumPaymentPlan.annual) premiumPaymentPlan.push(PremiumPaymentPlan.Annual);

        premiumPaymentPlanDefault = newSettings.premiumPaymentPlan.selectedDefault;

        // Split Premium
        if (newSettings.splitPremium.percent050) splitPremium.push(SplitPremium.Percent050);
        if (newSettings.splitPremium.percent075) splitPremium.push(SplitPremium.Percent075);
        if (newSettings.splitPremium.percent1) splitPremium.push(SplitPremium.Percent1);
        if (newSettings.splitPremium.percent125) splitPremium.push(SplitPremium.Percent125);
        if (newSettings.splitPremium.percent150) splitPremium.push(SplitPremium.Percent150);
        if (newSettings.splitPremium.percent175) splitPremium.push(SplitPremium.Percent175);

        splitPremiumDefault = newSettings.splitPremium.selectedDefault;

        // Premium Paid By
        if (newSettings.premiumPaidBy.borrowerPaid) premiumPaidBy.push(PremiumPaidBy.BorrowerPaid);
        if (newSettings.premiumPaidBy.lenderPaid) premiumPaidBy.push(PremiumPaidBy.LenderPaid);

        premiumPaidByDefault = newSettings.premiumPaidBy.selectedDefault;

        // Refund Option
        if (newSettings.refundOption.refundable) refundOption.push(RefundOption.Refundable);
        if (newSettings.refundOption.notRefundable) refundOption.push(RefundOption.NotRefundable);

        refundOptionDefault = newSettings.refundOption.selectedDefault;

        // Renewal Option
        if (newSettings.renewalOption.constant) renewalOption.push(RenewalOption.Constant);
        if (newSettings.renewalOption.decliningAmortizing) renewalOption.push(RenewalOption.DecliningAmortizing);
        if (newSettings.renewalOption.noRenewals) renewalOption.push(RenewalOption.NoRenewals);

        renewalOptionDefault = newSettings.renewalOption.selectedDefault;

        this.PremiumPaymentPlans = premiumPaymentPlan;
        this.selectControlPremiumPaymentPlan.setValue(premiumPaymentPlanDefault);

        this.SplitPremium = splitPremium;
        this.selectSplitPremiumUpFrontRate.setValue(splitPremiumDefault);

        this.PremiumPaidBy = premiumPaidBy;
        this.selectPremiumPaidBy.setValue(premiumPaidByDefault);

        this.RefundOptions = refundOption;
        this.selectRefundOption.setValue(refundOptionDefault);

        this.RenewalOptions = renewalOption;
        this.selectRenewalOption.setValue(renewalOptionDefault);

        if (
            premiumPaymentPlanDefault === PremiumPaymentPlan.Single &&
            premiumPaidByDefault === PremiumPaidBy.BorrowerPaid &&
            newSettings.premiumFinanced
        ) {
            this.checkBoxPremiumFinanced.setValue(true);
        }

        this.loading = false;
    }

    loadSettings(): void {
        this.orderParamForm.reset();
        this.parseSettings();
    }

    loadLoanData(): void {
        if (this.originData) {
            this.textDebtRatioWithoutMi.setValue(this.originData?.debtRatioWithoutMi?.toString());
            this.textHousingRatioWithoutMi.setValue(this.originData?.housingRatioWithoutMi?.toString());

            if (this.originData?.debtRatioWithoutMi?.toString()) {
                this.backEndDTI = this.originData?.debtRatioWithoutMi?.toString();
            }

            if (this.originData?.housingRatioWithoutMi?.toString()) {
                this.frontEndDTI = this.originData?.housingRatioWithoutMi?.toString();
            }
        }

        if (this.transactionData) {
            this.selectControlPremiumPaymentPlan.setValue(this.transactionData?.premiumPaymentPlan);
            this.selectControlPremiumPaymentPlan.disable();
            this.selectSplitPremiumUpFrontRate.setValue(this.transactionData?.splitPremiumUpFrontRate);
            this.selectSplitPremiumUpFrontRate.disable();
            this.selectPremiumPaidBy.setValue(this.transactionData?.premiumPaidBy);
            this.selectPremiumPaidBy.disable();
            this.selectRefundOption.setValue(this.transactionData?.refundOption);
            this.selectRefundOption.disable();
            this.selectRenewalOption.setValue(this.transactionData?.renewalOption);
            this.selectRenewalOption.disable();
            this.checkBoxPremiumFinanced.setValue(this.transactionData?.premiumFinanced);
            this.checkBoxPremiumFinanced.disable();
            this.checkBoxRelocationLoan.setValue(this.transactionData?.relocationLoan);
            this.checkBoxRelocationLoan.disable();
            this.textInputSpecialProgramId.setValue(this.transactionData?.specialProgramId);
            this.textInputSpecialProgramId.disable();
            this.textInputChoiceMonthlyUpfrontPremium.setValue(this.transactionData?.choiceMonthlyUpfrontPremium);
            this.textInputChoiceMonthlyUpfrontPremium.disable();
        } else {
            this.selectControlPremiumPaymentPlan.setValue(this.settings?.premiumPaymentPlan.selectedDefault);
            this.selectSplitPremiumUpFrontRate.setValue(this.settings?.splitPremium.selectedDefault);
            this.selectPremiumPaidBy.setValue(this.settings?.premiumPaidBy.selectedDefault);
            this.selectRefundOption.setValue(this.settings?.refundOption.selectedDefault);
            this.selectRenewalOption.setValue(this.settings?.renewalOption.selectedDefault);
            this.checkBoxPremiumFinanced.setValue(this.settings?.premiumFinanced);
        }
    }

    disableInputs(): void {
        this.selectControlPremiumPaymentPlan.disable();
        this.selectPremiumPaidBy.disable();
        this.selectRefundOption.disable();
        this.selectRenewalOption.disable();
        this.checkBoxPremiumFinanced.disable();
        this.checkBoxRelocationLoan.disable();
        this.textInputSpecialProgramId.disable();
        this.textInputChoiceMonthlyUpfrontPremium.disable();
    }

    enableInputs(): void {
        this.selectControlPremiumPaymentPlan.enable();
        this.selectPremiumPaidBy.enable();
        this.selectRefundOption.enable();
        this.selectRenewalOption.enable();
        if (this.selectPremiumPaidBy.value !== PremiumPaidBy.LenderPaid) {
            this.checkBoxPremiumFinanced.enable();
        }
        this.checkBoxRelocationLoan.enable();
        this.textInputSpecialProgramId.enable();
    }

    showFields(): void {
        this.showPremiumPaymentPlan = true;
        this.showSplitPremiumUpFrontRate = true;
        this.showPremiumPaidBy = true;
        this.showRefundOption = true;
        this.showRenewalOption = true;
        this.showPremiumFinanced = true;
        this.showRelocationLoan = true;
        this.showSpecialProgramId = true;
        this.showDebtRatioWithoutMi = true;
        this.showHousingDebtRatioWithoutMi = true;
        this.showRush = true;
    }

    hideFields(): void {
        this.showPremiumPaymentPlan = false;
        this.showSplitPremiumUpFrontRate = false;
        this.showPremiumPaidBy = false;
        this.showRefundOption = false;
        this.showRenewalOption = false;
        this.showPremiumFinanced = false;
        this.showRelocationLoan = false;
        this.showSpecialProgramId = false;
        this.showChoiceMonthlyUpfrontPremium = false;
        this.showDebtRatioWithoutMi = false;
        this.showHousingDebtRatioWithoutMi = false;
        this.showRush = false;
    }

    keyPress(event: KeyboardEvent) {
        const pattern = /^\$?[0-9]+\.?[0-9]*$/;
        const value = this.textInputChoiceMonthlyUpfrontPremium.value
            ? this.textInputChoiceMonthlyUpfrontPremium.value + event.key
            : event.key;

        if (!pattern.test(value)) {
            // invalid value, prevent input
            event.preventDefault();
        }
    }

    validateDollar(number: string) {
        let formatedNum: string;
        formatedNum = this.formatUtils.formatDollarfromString(number);
        this.textInputChoiceMonthlyUpfrontPremium.setValue(formatedNum);
    }

    validateRefundOption() {
        return (
            this.selectControlPremiumPaymentPlan.value === PremiumPaymentPlan.Annual &&
            this.selectPremiumPaidBy.value === PremiumPaidBy.LenderPaid &&
            this.selectRefundOption.value === RefundOption.NotRefundable
        );
    }

    requestTypeChangeListener() {
        if (this.requestTypeString !== RequestType.MultiQuote && this.requestTypeString !== RequestType.RetrieveCert) {
            if (this.requestTypeString === RequestType.ContractUnderwritingWithoutMi) {
                this.showChoiceMonthlyUpfrontPremium = false;
                this.disableInputs();
            }

            if (this.requestTypeString === RequestType.RateQuote) {
                this.showRush = false;
            }
        }

        if (
            this.requestTypeString !== RequestType.ContractUnderwritingWithoutMi &&
            this.requestTypeString !== RequestType.ContractUnderwritingWithMi
        ) {
            this.showGuidelines = false;
            this.enableInputs();
        } else {
            this.showChoiceMonthlyUpfrontPremium = false;
            this.showGuidelines = true;
        }

        if (this.requestTypeString === RequestType.RetrieveCert) {
            this.disableInputs();
        }
    }
}

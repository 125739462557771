<div class="main-container">
    <header>
        <h3>RATES</h3>
    </header>
    <div class="rate-title">
        <div class="rate-card">
            <p class="rate-card-title">At Closing</p>
            <div class="rate-card-values">
                <label class="rate-card-label">Percentage %</label>
                <p class="rate-card-data">{{ formatUtils.formatPercent(rateData?.atClosing?.percentage, 2) }}</p>
            </div>
            <div class="rate-card-values">
                <label class="rate-card-label">Amount</label>
                <p class="rate-card-data">
                    {{ formatUtils.formatDollar(rateData?.atClosing?.amount) }}
                </p>
            </div>
            <div class="rate-card-values-last">
                <label class="rate-card-label">Duration</label>
                <p class="rate-card-data">{{ rateData?.atClosing?.duration }} months</p>
            </div>
        </div>
        <div class="rate-card">
            <p class="rate-card-title">First Renewal</p>
            <div class="rate-card-values">
                <label class="rate-card-label">Percentage %</label>
                <p class="rate-card-data">{{ formatUtils.formatPercent(rateData?.firstRenewal?.percentage, 2) }}</p>
            </div>
            <div class="rate-card-values">
                <label class="rate-card-label">Amount</label>
                <p class="rate-card-data">
                    {{ formatUtils.formatDollar(rateData?.firstRenewal?.amount) }}
                </p>
            </div>
            <div class="rate-card-values-last">
                <label class="rate-card-label">Duration</label>
                <p class="rate-card-data">{{ rateData?.firstRenewal?.duration }} months</p>
            </div>
        </div>
        <div class="rate-card-last">
            <p class="rate-card-title">Second Renewal</p>
            <div class="rate-card-values">
                <label class="rate-card-label">Percentage %</label>
                <p class="rate-card-data">{{ formatUtils.formatPercent(rateData?.secondRenewal?.percentage, 2) }}</p>
            </div>
            <div class="rate-card-values">
                <label class="rate-card-label">Amount</label>
                <p class="rate-card-data">
                    {{ formatUtils.formatDollar(rateData?.secondRenewal?.amount) }}
                </p>
            </div>
            <div class="rate-card-values-last">
                <label class="rate-card-label">Duration</label>
                <p class="rate-card-data">{{ rateData?.secondRenewal?.duration }} months</p>
            </div>
        </div>
    </div>
</div>

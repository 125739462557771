import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { MiCoverageBracket } from "src/app/models/MiCoverageBracket";
import { MiCoverageType } from "src/app/models/enums/MiCoverageType";

@Component({
    selector: "app-admin-mi-table",
    templateUrl: "./admin-mi-table.component.html",
    styleUrls: ["./admin-mi-table.component.css"]
})
export class AdminMiTableComponent implements OnInit {
    @Input() mi: MiCoverageBracket[];
    @Input() fannieMae :boolean;
    
    @Output() editedMiTable = new EventEmitter<any>();
    @Output() validForm = new EventEmitter<boolean>();
    

    miTable: MiCoverageBracket[];
    selectedCell: string;
    valid: boolean = true;
    
    coverageType = "";

    constructor() {}

    ngOnInit(): void {
        this.miTable = this.mi;
        if(this.fannieMae === true){
            this.coverageType = MiCoverageType.FannieMae.toUpperCase();
        }
        if(this.fannieMae === false){
            this.coverageType = MiCoverageType.FreddieMac.toUpperCase();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.fannieMae){
            if(this.fannieMae === true){
                this.coverageType = MiCoverageType.FannieMae.toUpperCase();
            }
            if(this.fannieMae === false){
                this.coverageType = MiCoverageType.FreddieMac.toUpperCase();
            }
        }
    }


    onCellClick(cell: any, cellName: string): void {
        cell.editMode = true;
        this.selectedCell = cellName;
    }

    onCellBlur(cell: any): void {
        cell.editMode = false;
    }

    handleInput(cell: any, eventTarget: any) {
        let validForm = true;
        if (/\D/.test(eventTarget.value) || eventTarget.value < 1 || eventTarget.value > 100) {
            cell.valid = false;
            validForm = false;
        } else {
            cell.valid = true;
            cell.value = eventTarget.value;
        }

        this.valid = validForm;
        this.validForm.emit(this.valid);

        if (this.valid) {
            this.editedMiTable.emit(this.miTable);
        }
    }
}

import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormBuilder } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AffordableHousing } from "../../models/enums/AffordableHousing";
import { AdminSettings } from "../../models/AdminSettings";
import { AusService } from "../../models/enums/AusService";
import { CreditRisk } from "../../models/enums/CreditRisk";
import { DuFindings } from "../../models/enums/DuFindings";
import { OriginatorTypes } from "../../models/enums/OriginatorTypes";
import { PurchaseEligibility } from "../../models/enums/PurchaseEligibility";
import { MiTableComponent } from "../mi-table/mi-table.component";
import { RequestType } from "../../models/enums/RequestType";
import { States } from "../../models/enums/State";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";
import { RequestDataOptions } from "src/app/models/RequestDataOptions";
import { combineLatest } from "rxjs";

@Component({
    selector: "app-mi-coverage-and-aus-findings",
    templateUrl: "./mi-coverage-and-aus-findings.component.html",
    styleUrls: ["./mi-coverage-and-aus-findings.component.css"]
})
export class MiCoverageAndAusFindingsComponent implements OnInit, OnChanges {
    @Input() settings: AdminSettings;
    @Input() originData: OriginDataObject | null;
    @Input() transactionData: TransactionData | null;
    @Input() requestTypeString: string;
    @Input() transactionRequestOptions: RequestDataOptions;

    originatorInfoForm: FormGroup;
    tpoNameForm: FormGroup;
    modalRef?: NgbModalRef;
    ausServiceEnum = AusService;
    states = States;
    stateKeys = Object.keys(States) as (keyof typeof States)[];
    ausKeys = Object.keys(AusService) as (keyof typeof AusService)[];
    purchaseEligibility = PurchaseEligibility;
    isMiInvalid: boolean;
    purchaseEligibilityKeys = Object.keys(PurchaseEligibility) as (keyof typeof PurchaseEligibility)[];
    creditRisk = CreditRisk;
    creditRiskKeys = Object.keys(CreditRisk) as (keyof typeof CreditRisk)[];
    duFindings = DuFindings;
    duFeedbackKeys = Object.keys(DuFindings) as (keyof typeof DuFindings)[];
    originatorTypes = OriginatorTypes;
    originatorTypeKeys = Object.keys(OriginatorTypes) as (keyof typeof OriginatorTypes)[];
    affordableHousing = AffordableHousing;
    affordableHousingKeys = Object.keys(AffordableHousing) as (keyof typeof AffordableHousing)[];
    showMiTableComponent: boolean = false;
    selectMiCoverage = new FormControl("", [
        Validators.required,
        Validators.pattern("^(100|[1-9]?[0-9])?(\\.\\d{1,2})?%?$")
    ]);
    selectAffordableHousingType = new FormControl("Select");
    selectAusService = new FormControl();
    selectDuFindings = new FormControl();
    selectPurchaseEligibility = new FormControl();
    selectRiskClass = new FormControl();
    selectOriginatorType = new FormControl("Lender");
    selectState = new FormControl("", Validators.required);
    checkBoxRush = new FormControl();
    checkBoxRelocationLoan = new FormControl();
    checkBoxAusDocumentWaiver = new FormControl();
    showUnderwriterComments: boolean = false;
    currentCharacters: number = 0;
    maxCharacters: number = 2000;
    showRush: boolean = false;
    showRelocationLoan: boolean = false;
    disableMiCoverage: boolean = false;

    // form definitions
    miCoverageAndAus = new FormGroup({
        userSelectedMiCoverage: this.selectMiCoverage,
        userSelectedAffordableHousing: this.selectAffordableHousingType,
        userSelectedEngineSelection: this.selectAusService,
        userSelectedDuFindings: this.selectDuFindings,
        userSelectedPurchaseEligibility: this.selectPurchaseEligibility,
        userSelectedRiskClass: this.selectRiskClass,
        userSelectedAusDocumentWaiver: this.checkBoxAusDocumentWaiver,
        userSelectedOriginatorType: this.selectOriginatorType,
        userSelectedState: this.selectState
    });

    constructor(
        public cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private fb: FormBuilder
    ) {
        this.tpoNameForm = this.fb.group({
            tpoCompanyName: ["", Validators.required, Validators.maxLength(51)]
        });
        this.originatorInfoForm = this.fb.group({
            address: ["", Validators.maxLength(51)],
            city: ["", Validators.required, Validators.maxLength(51)],
            selectState: this.selectState,
            zipcode: ["", Validators.required, Validators.maxLength(10)]
        });
    }

    ngOnInit(): void {
        this.loadLoanData();
        combineLatest([
            this.selectMiCoverage?.valueChanges,
            this.selectAffordableHousingType?.valueChanges,
            this.selectAusService?.valueChanges,
            this.selectDuFindings?.valueChanges,
            this.selectOriginatorType?.valueChanges,
            this.selectPurchaseEligibility?.valueChanges,
            this.selectRiskClass?.valueChanges,
            this.tpoNameForm?.valueChanges,
            this.originatorInfoForm?.valueChanges,
            this.checkBoxAusDocumentWaiver?.valueChanges,
            this.checkBoxRelocationLoan?.valueChanges,
            this.checkBoxRush?.valueChanges
        ]).subscribe(
            ([
                miCoverage,
                housingType,
                ausService,
                duFindings,
                originator,
                purchaseEligibility,
                riskClass,
                tpoNameForm,
                originatorInfoForm,
                ausDocumentWaiver,
                relocation,
                rush
            ]) => {
                if (miCoverage) {
                    this.transactionRequestOptions.miApplication.miCoveragePercent = Number(miCoverage);
                }
                if (housingType) {
                    this.transactionRequestOptions.miApplication.affordableHousingType = housingType;
                }
                if (ausService) {
                    this.transactionRequestOptions.aus.ausType = ausService;
                }
                if (duFindings) {
                    this.transactionRequestOptions.aus.desktopUnderwriterRecommendationType = duFindings;
                }
                if (originator) {
                    this.transactionRequestOptions.tpo.originationType = originator;
                }
                if (purchaseEligibility) {
                    this.transactionRequestOptions.aus.frePurchaseEligibilityType = purchaseEligibility;
                }
                if (riskClass) {
                    this.transactionRequestOptions.aus.loanProspectorCreditRiskClassificationDescription;
                }
                if (tpoNameForm) {
                    this.transactionRequestOptions.tpo.tpoCompanyName = tpoNameForm.values.tpoCompanyName.value;
                }
                if (originatorInfoForm) {
                    this.transactionRequestOptions.tpo.tpoCompanyAddress = originatorInfoForm.values.address.value;
                    this.transactionRequestOptions.tpo.tpoCityName = originatorInfoForm.values.city.value;
                    this.transactionRequestOptions.tpo.tpoStateCode = originatorInfoForm.values.state.value;
                    this.transactionRequestOptions.tpo.tpoPostalCode = originatorInfoForm.values.zipcode.value;
                }
                if (ausDocumentWaiver) {
                    this.transactionRequestOptions.aus.automatedUnderwritingSystemDocumentWaiverIndicator =
                        ausDocumentWaiver;
                }
                if (relocation) {
                    this.transactionRequestOptions.miApplication.relocationLoanIndicator = relocation;
                }
                if (rush) {
                    this.transactionRequestOptions.miApplication.rushUnderwritingIndicator = rush;
                }
            }
        );
    }

    get tpoCompanyName() {
        return this.tpoNameForm.get("tpoCompanyName");
    }

    get city() {
        return this.originatorInfoForm.get("city");
    }

    get zipcode() {
        return this.originatorInfoForm.get("zipcode");
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.requestTypeString) {
            this.enableInputs();
            const requestType = changes.requestTypeString.currentValue;
            if (requestType === RequestType.MultiQuote) {
                this.disableMiCoverage = true;
            }
            if (requestType !== RequestType.MultiQuote) {
                this.disableMiCoverage = false;
            }
            if (requestType === RequestType.ContractUnderwritingWithoutMi) {
                this.selectMiCoverage.disable();
                this.selectAffordableHousingType.disable();
            }

            if (
                requestType === RequestType.NonDelegatedMi ||
                requestType === RequestType.ContractUnderwritingWithMi ||
                requestType === RequestType.ContractUnderwritingWithoutMi
            ) {
                this.showUnderwriterComments = true;
            } else {
                this.showUnderwriterComments = false;
            }

            if (requestType === RequestType.RetrieveCert) {
                this.disableInputs();
            }

            if (requestType === RequestType.MultiQuote) {
                this.showRush = true;
                this.showRelocationLoan = true;
            } else {
                this.showRush = false;
                this.showRelocationLoan = false;
            }
        }
    }

    showMiTable() {
        this.showMiTableComponent = !this.showMiTableComponent;
    }

    refreshMi() {
        console.log("Message: this would recalc the MI");
    }

    percentageValidator(): ValidatorFn {
        return (control: AbstractControl): { [keys: string]: any } | null => {
            const value = control.value.replace("%", "");
            const isValid = /^([1-9][0-9]?)?$/.test(value) && parseInt(value) <= 99;
            return isValid ? null : { percentage: { value: control.value } };
        };
    }

    appendPercentage(): void {
        let value = this.selectMiCoverage.value;
        if (value && !value.endsWith("%")) {
            this.selectMiCoverage.setValue(value + "%");
        }
    }

    loadLoanData(): void {
        if (this.originData) {
            this.selectAffordableHousingType.setValue(this.originData.affordableHousingType);

            this.originData.loan.channel.includes("Retail")
                ? this.selectOriginatorType.setValue(OriginatorTypes.Lender)
                : this.originData.loan.channel.includes("Broker")
                    ? this.selectOriginatorType.setValue(OriginatorTypes.Broker)
                    : this.originData.loan.channel.includes("Correspondent")
                        ? this.selectOriginatorType.setValue(OriginatorTypes.Correspondent)
                        : this.selectOriginatorType.setValue(OriginatorTypes.Lender);
            
            this.selectOriginatorType.setValue(this.originData.loan.channel);
            
            this.originData.loan.tsum.riskAssessmentType === "DU"
                ? this.selectAusService.setValue(AusService.DesktopUnderwriter)
                : this.originData.loan.tsum.riskAssessmentType === "LPA"
                    ? this.selectAusService.setValue(AusService.LoanProductAdvisor)
                    : this.selectAusService.setValue(AusService.Select);

            this.originData.loan.tql.stonegate4506TBaseLineReport === "Elligible"
                ? this.selectPurchaseEligibility.setValue(PurchaseEligibility.Eligible)
                : this.originData.loan.tql.stonegate4506TBaseLineReport === "Elligible A Minus"
                    ? this.selectPurchaseEligibility.setValue(PurchaseEligibility.EligibleAMinus)
                    : this.originData.loan.tql.stonegate4506TBaseLineReport === "Inelligible"
                        ? this.selectPurchaseEligibility.setValue(PurchaseEligibility.Ineligible)
                        : this.selectPurchaseEligibility.setValue(PurchaseEligibility.Select);

            this.originData.loan.tql.stonegateFraudBaselineReportRequired === "Approve Elligible"
                ? this.selectDuFindings.setValue(DuFindings.ApproveEligible)
                : this.originData.loan.tql.stonegateFraudBaselineReportRequired === "Approve Inelligible"
                    ? this.selectDuFindings.setValue(DuFindings.ApproveIneligible)
                    : this.originData.loan.tql.stonegateFraudBaselineReportRequired === "Refer with Caution"
                        ? this.selectDuFindings.setValue(DuFindings.ReferWithCaution)
                        : this.originData.loan.tql.stonegateFraudBaselineReportRequired === "Out of Scope"
                            ? this.selectDuFindings.setValue(DuFindings.OutOfScope)
                            : this.selectDuFindings.setValue(DuFindings.Select);
        }

        if (this.transactionData) {
            this.selectMiCoverage.setValue(this.transactionData?.miCoverage);
            this.selectMiCoverage.disable();
            this.selectAffordableHousingType.setValue(this.transactionData?.affordableHousingType);
            this.selectAffordableHousingType.disable();

            if (
                this.originData?.loan?.tsum?.riskAssessmentType &&
                AusService[this.originData?.loan?.tsum?.riskAssessmentType as keyof typeof AusService] !== undefined
            ) {
                this.selectAusService.setValue(
                    AusService[this.originData?.loan?.tsum?.riskAssessmentType as keyof typeof AusService]
                );
            } else {
                this.selectAusService.setValue(null);
            }

            this.selectAusService.disable();
            this.selectOriginatorType.setValue(this.transactionData?.originatorType);
            this.selectOriginatorType.disable();
            this.checkBoxAusDocumentWaiver.setValue(this.transactionData?.ausWaiver);
            this.checkBoxAusDocumentWaiver.disable();

            if (this.originData?.loan?.tsum?.riskAssessmentType === AusService.DesktopUnderwriter) {
                this.selectDuFindings.setValue(this.originData?.loan?.tql?.stonegateFraudBaselineReportRequired);
                this.selectDuFindings.disable();
            }

            if (this.originData?.loan?.tsum?.riskAssessmentType === AusService.LoanProductAdvisor) {
                this.selectPurchaseEligibility.setValue(this.originData?.loan?.tql?.stonegate4506TBaseLineReport);
                this.selectPurchaseEligibility.disable();
                this.selectRiskClass.setValue(this.originData?.loan?.freddieMac?.freddieFiel14);
                this.selectRiskClass.disable();
            }

            this.checkBoxAusDocumentWaiver.setValue(this.transactionData?.ausWaiver);
            this.checkBoxAusDocumentWaiver.disable();
            this.selectOriginatorType.setValue(this.transactionData?.originatorType);
            this.selectOriginatorType.disable();
        }
        if (!this.originData) {
            this.selectAusService.setValue(AusService.Select);
            this.selectDuFindings.setValue(DuFindings.Select);
            this.selectPurchaseEligibility.setValue(PurchaseEligibility.Select);
            this.selectRiskClass.setValue(CreditRisk.Select);
        }
    }

    openMiCoverageDetails(): void {
        this.modalRef = this.modalService.open(MiTableComponent, {
            windowClass: "mi-table-modal",
            backdrop: "static"
        });

        this.modalRef.componentInstance.settings = this.settings;
        this.modalRef.componentInstance.transactionData = this.transactionData;
        this.modalRef.componentInstance.originData = this.originData;
        this.modalRef.componentInstance.miCoverageValueRecieved = this.selectMiCoverage.value;
        this.modalRef.componentInstance.affordableHousingTypeValueRecieved = this.selectAffordableHousingType.value;

        this.modalRef.result.then(result => {
            this.selectMiCoverage.setValue(result.miValue);
            this.selectAffordableHousingType.setValue(result.affordableHousing);
        });
    }

    handleInput(event: any) {
        this.currentCharacters = event.target.value.length;
    }

    disableInputs(): void {
        this.miCoverageAndAus.disable();
    }

    enableInputs(): void {
        this.miCoverageAndAus.enable();
    }

    // TODO Wire refresh MI Button to encompass per feedback. Not yet implemented connection front and back
    // rename this
    pullDataEncompass() {
        // TODO
    }
}

import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TransactionTemplate } from "src/app/models/TransactionTemplate";
import { SaveButtonService } from "src/app/shared/save-button.service";

@Component({
    selector: "app-admin-title-template",
    templateUrl: "./admin-title-template.component.html",
    styleUrl: "./admin-title-template.component.css"
})
export class AdminTitleTemplateComponent {
    @Input() transactionTemplate : TransactionTemplate;
    adminTitleTemplateForm: FormGroup;
    templateName: FormControl = new FormControl("");

    constructor(
        private adminTitleTemplate: FormBuilder,
        private saveService: SaveButtonService
    ) {
        this.adminTitleTemplateForm = this.adminTitleTemplate.group({
            templateName: ["", Validators.required]
        });
    }
    
    updateTemplateName() {
        this.transactionTemplate.name = this.templateName.value;
        this.saveService.setCanSave(
            this.transactionTemplate.name !== "" &&
            this.transactionTemplate.name !== null &&
            this.transactionTemplate.name !== undefined
        );
    }

    handleTranscationTemplate(){
        this.templateName.setValue(this.transactionTemplate.name);
    }
}

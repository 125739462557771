<div *ngIf="router.url === '/'">
    <div class="app-container" [class.app-container-shifted]="isContactInfoOpen" *ngIf="!loading">
        <div class="main-content">
            <app-header
                *ngIf="!transactionData?.orderStatus"
                [originData]="originData"
                [transactionData]="transactionData"
                [adminSettings]="settings"
                [clientId]="clientId"
                (settings)="handleSettingsChange($event)"
                (openContactInfo)="openContactInfo()"
            ></app-header>
            <div *ngIf="showWarning" class="warning-container">
                <div class="message-container">
                    <div class="message">
                        <span>
                            <i class="bi bi-exclamation-triangle-fill"></i>
                        </span>
                        <span class="bold">Warning</span> {{ warningMessage }}
                    </div>
                    <div class="message-close">
                        <button class="close-button" (click)="closeWarning()">
                            <i class="bi bi-x-lg"></i>
                        </button>
                    </div>
                </div>
                <div class="colored-underline"></div>
            </div>
            <app-order-complete
                *ngIf="transactionData?.orderStatus"
                [originData]="originData"
                [transactionData]="transactionData"
                [rateData]="rateData"
            ></app-order-complete>
            <app-contact-info *ngIf="isContactInfoOpen" (closeSidebar)="closeContactInfo()"></app-contact-info>
            <app-master-policy
                *ngIf="!transactionData?.orderStatus"
                [settings]="settings"
                [originData]="originData"
                [transactionData]="transactionData"
                [requestTypeString]="requestTypeString"
            ></app-master-policy>
            <app-order-type
                *ngIf="!transactionData?.orderStatus"
                [persona]="persona"
                [settings]="settings"
                [originData]="originData"
                [transactionData]="transactionData"
                (requestType)="handleRequestTypeChange($event)"
            ></app-order-type>
            <app-order-rates
                *ngIf="transactionData && transactionData?.orderStatus"
                [originData]="originData"
                [transactionData]="transactionData"
                [rateData]="rateData"
            ></app-order-rates>
            <app-order-status *ngIf="transactionData && transactionData?.orderStatus" [originData]="originData" [transactionData]="transactionData"></app-order-status>
            <app-order-parameters
                *ngIf="!transactionData?.orderStatus && requestTypeString !== requestType.MultiQuote"
                [settings]="settings"
                [originData]="originData"
                [transactionData]="transactionData"
                [requestTypeString]="requestTypeString"
            ></app-order-parameters>
            <app-multi-rate-quote-table
                *ngIf="!transactionData?.orderStatus && requestTypeString === requestType.MultiQuote"
                [originData]="originData"
                [transactionData]="transactionData"
                [originData]="originData"
                [transactionData]="transactionData"
            >
            </app-multi-rate-quote-table>
            <app-mi-coverage-and-aus-findings
                *ngIf="!transactionData?.orderStatus"
                [settings]="settings"
                [originData]="originData"
                [transactionData]="transactionData"
                [requestTypeString]="requestTypeString"
            ></app-mi-coverage-and-aus-findings>
            <app-footer *ngIf="!transactionData?.orderStatus" [applicationState]="applicationState" [tranasctionData]="transactionData" [originData]="originData"></app-footer>
        </div>
    </div>
</div>

<router-outlet></router-outlet>

<form [formGroup]="adminTitleTemplateForm">
    <div class="primary-container">
        <h3>
            <div class="title">Title</div>
        </h3>
        <div class="selection-block-quarter-width">
            <p class="selection-label-left">Template Name</p>
            <ng-container>
                <input [formControl]="templateName" type="text" id="templateName" class="input-selection-id" (blur)="updateTemplateName()"/>
            </ng-container>
        </div>
    </div>
</form>

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class ApplicationApisService {
    private baseUrl = environment.backend_base_url;
    private path = "api/app-api";

    constructor(private http: HttpClient) {}

    uploadDocument(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/${this.path}/document`, data);
    }

    completeOrder(data: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/${this.path}/complete`, data);
    }

    createAdminSettings(data: any): Observable<any> {
        const builtUrl = `${this.baseUrl}/${this.path}/adminSettings`;
        return this.http.post(builtUrl, data);
    }

    getAdminSettings(clientId: string): Observable<any> {
        const builtUrl = `${this.baseUrl}/${this.path}/adminSettings?clientId=${clientId}`;
        return this.http.get(builtUrl, { responseType: "text" });
    }
}

/*
EXAMPLE CALL TO SERVICE FROM COMPONENT - Not tested. 

import { ApplicationApisService } from './application-apis.service.ts;

@Component({
  ....
})

export class ExampleComponent{
  uploadResponse: any

  constructor(private applicationApisService: ApplicationApisService) {}

  uploadDocument(data: any): void {
    this.applicationApisService.uploadDocument(data).subscribe(
      (response) => {
        console.log('Upload successful', response);
        this.uploadResponse = response;
      },
      (error) => {
        console.error('Upload failed', error);
      }
    );
  }
}
*/

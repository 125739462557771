import host from '@elliemae/em-ssf-guest';
import { ApplicationState } from '../models/ApplicationState';
import { TransactionTemplate } from '../models/TransactionTemplate';

// Origin JS APIs
export async function initializeOriginationContext(applicationState: ApplicationState) {
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject('transaction')
        const originationContext = transactionObject.getOrigin()
    
        applicationState.originId = originationContext.id
        applicationState.partnerAccessToken = originationContext.partnerAccessToken;
        if (originationContext.transactionId) {
            applicationState.transactionId = originationContext.transactionId
        }
        return applicationState;
    } catch (error) {
      console.log(error)
      return;
    }
}

export async function refreshOriginationContext(applicationState: ApplicationState) {
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject('transaction');
        const originationContext = await transactionObject.refreshOrigin();
    
        applicationState.originId = originationContext.id;
        applicationState.partnerAccessToken = originationContext.partnerAccessToken;
        if (originationContext.transactionId) {
            applicationState.transactionId = originationContext.transactionId;
        }
        return applicationState;
    } catch (error) {
        console.log(error)
        return;
      }
}

// Transaction JS APIs
export async function createTransaction(transactionRequest: any, applicationState: ApplicationState, requestType: string) { // TODO: Design transactionRequest type 
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject('transaction')
        const createRequest = {
            request: {
                options: transactionRequest, // Include user selected resources in request,
                type: requestType,
                resources: transactionRequest.resources
            }
        };

        const transactionData = await transactionObject.create(createRequest)
    
        applicationState.transactionId = transactionData.id;
        return applicationState;
    } catch (error) {
        console.log(error)
        return;
      }
}

export async function updateTransaction(
    updatedTransactionRequest: any, // TODO: Design transactionRequest type 
    applicationState: ApplicationState
) {
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject('transaction');
        const updateRequest = {
            request: {
                options: updatedTransactionRequest
            }
        };
        const transactionData = await transactionObject.update(updateRequest);
        applicationState.transactionId = transactionData.id;
        return applicationState;
    } catch (error) {
        console.log(error)
        return;
      }
}

// TODO: add logic to this function 
async function initialize(transactionTemplate?: TransactionTemplate) {
    // TODO: this is a placeholder that needs to be developed 
    // it should pre-fill the user-interface with the
    // values from the current transaction template
    if(transactionTemplate){
        // Edit mode
    }else{
        // Create mode
    }
}

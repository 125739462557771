<form [formGroup]="orderParamForm">
    <div class="container-main">
        <h3>ORDER PARAMETERS</h3>
        <div class="container-param">
            <div *ngIf="showPremiumPaymentPlan" class="selection-block-quarter">
                <label class="selection-label-left"
                    >Premium Payment Plan<span class="required-field-marker">*</span></label
                >
                <br />
                <ng-container>
                    <select
                        [formControl]="selectControlPremiumPaymentPlan"
                        id="premiumPaymentPlanSelected"
                        class="selection-dropdown-left"
                        data-test-id="premium-payment-plan"
                    >
                        <option *ngFor="let plan of PremiumPaymentPlans | keyvalue" value="{{ plan.value }}">
                            {{ plan.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showSplitPremiumUpFrontRate" class="selection-block-quarter">
                <label *ngIf="selectControlPremiumPaymentPlan.value ==='Split Premium'" class="selection-label-left"
                    >Split Premium Up Front Rate<span class="required-field-marker" >*</span></label
                >
                <label *ngIf="selectControlPremiumPaymentPlan.value !=='Split Premium'" class="selection-label-left"
                >Split Premium Up Front Rate</label
            >
                <br />
                <ng-container>
                    <select
                        [formControl]="selectSplitPremiumUpFrontRate"
                        id="splitPremiumRate"
                        [attr.disabled]="selectControlPremiumPaymentPlan.value !== 'Split Premium' ? true : null"
                        class="selection-dropdown-left"
                        data-test-id="split-premium-up-front-rate"
                    >
                        <option
                            *ngFor="let splitPremiumRate of SplitPremium | keyvalue"
                            value="{{ splitPremiumRate.value }}"
                        >
                            {{ splitPremiumRate.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showPremiumPaidBy" class="selection-block-quarter">
                <label class="selection-label-left">Premium Paid By<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectPremiumPaidBy"
                        id="premiumPaidBy"
                        class="selection-dropdown-left"
                        data-test-id="premium-paid-by"
                    >
                        <option
                            *ngFor="let premiumPaidBy of PremiumPaidBy | keyvalue"
                            value="{{ premiumPaidBy.value }}"
                        >
                            {{ premiumPaidBy.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showRefundOption" class="selection-block-quarter">
                <label class="selection-label-left">Refund Option<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectRefundOption"
                        id="refundOptionSelection"
                        class="selection-dropdown-left"
                        [class.selection-dropdown-left-invalid]="validateRefundOption()"
                        data-test-id="refund-option"
                    >
                        <option *ngFor="let option of RefundOptions | keyvalue" value="{{ option.value }}">
                            {{ option.value }}
                        </option>
                    </select>
                </ng-container>
                <div *ngIf="validateRefundOption()" class="error-text">
                    Refund Option must be Refundable when Premium Payment Plan is Annual
                </div>
            </div>
        </div>
        <div class="container-param">
            <div *ngIf="showRenewalOption" class="selection-block-quarter">
                <label class="selection-label-left">Renewal Options<span class="required-field-marker">*</span></label>
                <br />
                <ng-container>
                    <select
                        [formControl]="selectRenewalOption"
                        id="renewalOptionSelected"
                        class="selection-dropdown-left"
                        data-test-id="renewal-options"
                    >
                        <option
                            *ngFor="let renewalOption of RenewalOptions | keyvalue"
                            value="{{ renewalOption.value }}"
                        >
                            {{ renewalOption.value }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <div *ngIf="showPremiumFinanced" class="selection-block-quarter">
                <ng-container>
                    <input
                        [formControl]="checkBoxPremiumFinanced"
                        type="checkbox"
                        id="premiumFinanced"
                        data-test-id="premium-financed-clickbox"
                    />
                </ng-container>
                <label class="check-box-label" for="premiumFinanced">Premium Financed</label>
            </div>
            <div *ngIf="showSpecialProgramId" class="selection-block-quarter">
                <label class="selection-label-left" for="specialProgramId">Special Program ID</label>
                <ng-container>
                    <input
                        [formControl]="textInputSpecialProgramId"
                        type="text"
                        id="specialProgramId"
                        class="input-selection-id"
                        data-test-id="special-program-id"
                    />
                </ng-container>
            </div>
            <div *ngIf="showChoiceMonthlyUpfrontPremium" class="selection-block-quarter">
                <label class="selection-label-left" for="choiceMonthlyUpfrontPremium"
                    >Choice Monthly Upfront Premium</label
                >
                <p
                    *ngIf="!textInputChoiceMonthlyUpfrontPremium.valid && textInputChoiceMonthlyUpfrontPremium.touched"
                    class="alert-text"
                >
                    Invalid Choice Monthly Upfront Premium. Please enter numeric values only.
                </p>
                <ng-container>
                    <input
                        [formControl]="textInputChoiceMonthlyUpfrontPremium"
                        type="text"
                        id="choiceMonthlyUpfrontPremium"
                        class="input-selection-id-short"
                        data-test-id="choice-monthly-upfront-premium"
                        (blur)="validateChoiceMonthlyUpfrontPremium()"
                    />
                </ng-container>
                <button
                    class="text-overlay"
                    type="button"
                    ngbPopover="Choice Monthly allows payment of an upfront premium amount you choose to lower the monthly MI premium
            rate. The Upfront Premium may be lower on the quote we provide due to rounding. We always round down to
            ensure the Upfront Premium doesn't exceed what you've requested. Please note that Choice Monthly is only
            available to customers using risk-based pricing"
                    triggers="mouseover:mouseout"
                >
                    <i
                        class="bi bi-info-circle info-icon-image"
                        alt="infoIcon"
                        (mouseover)="showText = true"
                        (mouseout)="showText = false"
                        data-test-id="info-button"
                    ></i>
                </button>
            </div>
        </div>
        <div class="container-param">
            <div *ngIf="showGuidelines" class="selection-block-quarter">
                <label class="selection-label-left" for="selectGuidelines"> Guidelines </label>
                <ng-container>
                    <select
                        [formControl]="selectGuidelines"
                        id="guidelinesSelected"
                        class="selection-dropdown-left"
                        data-test-id="guidelines-options"
                    >
                        <option value="Fannie Mae">Fannie Mae</option>
                        <option value="Freddie Mac">Freddie Mac</option>
                    </select>
                </ng-container>
            </div>
        </div>
    </div>
</form>

<form [formGroup]="masterPolicyForm">
    <div class="primary-container">
        <h3>
            <div class="title">MASTER POLICY INFORMATION</div>
            <div *ngIf="showAttachDocuments" class="attach-documents-button-container">
                <button class="attach-documents-button" (click)="attachDocuments()">Attach Documents</button>
            </div>
        </h3>
        <div class="selection-block-quarter-width">
            <p class="selection-label-left">Master Policy Number</p>

            <select *ngIf="masterPolicyIds.length > 1" class="selection-dropdown-left text-bold">
                <option *ngFor="let masterPolicyId of masterPolicyIds" [value]="masterPolicyId" class="text-bold">
                    {{ masterPolicyId }}
                </option>
            </select>
            <p *ngIf="masterPolicyIds.length === 1" class="selection-dropdown-left text-bold">
                {{ masterPolicyIds[0] }}
            </p>
        </div>
        <div class="selection-block-quarter-width">
            <p class="selection-label-left">Branch ID</p>
            <ng-container>
                <select
                    [formControl]="selectControlBranchId"
                    class="selection-dropdown-left"
                    [class.read-only]="transactionData"
                    data-test-id="branch-id-dropdown"
                >
                    <option *ngIf="transactionData" [value]="branchList[0].id">{{  branchList[0].id }}</option>
                    <option *ngFor="let branch of branchList" [value]="branch">
                        {{ branch.name }}<span *ngIf="branch.id !== '0'"> | {{ branch.id }}</span>
                    </option>
                </select>
            </ng-container>
        </div>
    </div>
</form>

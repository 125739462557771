import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AdminSettings } from "src/app/models/AdminSettings";
import { Branch } from "src/app/models/Branch";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { RequestDataOptions } from "src/app/models/RequestDataOptions";
import { TransactionData } from "src/app/models/TransactionData";
import { RequestType } from "src/app/models/enums/RequestType";
import { uploadDocuments } from "src/app/utilities/elli";

@Component({
    selector: "app-master-policy",
    templateUrl: "./master-policy.component.html",
    styleUrls: ["./master-policy.component.css"]
})
export class MasterPolicyComponent implements OnInit, OnChanges {
    @Input() originData: OriginDataObject | null = null;
    @Input() transactionData: TransactionData | null = null;
    @Input() settings: AdminSettings;
    @Input() requestTypeString: string;
    @Input() transactionRequestOptions: RequestDataOptions;

    masterPolicyIds: string[] = [];
    branchList: Branch[] = [];
    defaultBranch: any;
    selectMasterPolicyId = new FormControl("");
    selectControlBranchId = new FormControl("");

    loading: boolean;

    masterPolicyForm: FormGroup;

    showAttachDocuments: boolean = false;

    constructor(private masterPolicy: FormBuilder) {
        this.masterPolicyForm = this.masterPolicy.group({
            masterPolicyNumber: ["", Validators.required],
            branchId: ["", Validators.required]
        });
    }

    ngOnInit(): void {
        if (this.loading === false) {
            this.loadSettings();
            this.loadLoanData();
        }

        this.selectMasterPolicyId.valueChanges.subscribe(value => {
            if (value) {
                this.transactionRequestOptions.miApplication.miLenderIdentifier = value;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings) {
            if (this.settings === null || this.settings === undefined) {
                this.loading = true;
            } else {
                this.loading = false;
                this.loadSettings();
                this.loadLoanData();
            }
        }

        if (changes.requestTypeString) {
            switch (changes.requestTypeString.currentValue) {
                case RequestType.NonDelegatedMi:
                    this.showAttachDocuments = true;
                    break;

                case RequestType.ContractUnderwritingWithMi:
                    this.showAttachDocuments = true;
                    break;

                case RequestType.ContractUnderwritingWithoutMi:
                    this.showAttachDocuments = true;
                    break;
                default:
                    this.showAttachDocuments = false;
                    break;
            }
        }
    }

    loadSettings(): void {
        if (this.loading === false) {
            this.branchList = this.settings.branches;
            this.defaultBranch = this.branchList.find(branch => branch.default);
            this.selectControlBranchId.setValue(this.defaultBranch);
        }
    }

    loadLoanData(): void {
        if (this.transactionData) {
            this.branchList = this.settings?.branches;
            if (this.branchList === null || undefined) {
                this.selectControlBranchId.setValue("");
            }
            if (this.branchList.length === 1) {
                this.selectControlBranchId.setValue(this.branchList[0].id);
            }
            if (this.branchList.length >= 2) {
                this.selectControlBranchId.setValue(this.defaultBranch.id);
            }
            this.selectControlBranchId.setValue("");
            this.selectControlBranchId.disable();
        }

        if (this.originData) {
            // Generate Master Policy string object(s)
            let masterPolicy = "";
            if (
                this.originData?.credentials?.masterPolicyNumber &&
                this.originData?.credentials?.masterPolicyNumberDescription
            ) {
                masterPolicy = `${this.originData?.credentials?.masterPolicyNumberDescription} | ${this.originData?.credentials?.masterPolicyNumber}`;
                this.masterPolicyIds.push(masterPolicy);
            }
        }
    }

    attachDocuments(): void {
        const status = this.transactionData?.orderStatus === "create" ? "create" : "update";
        uploadDocuments("getAvailableResources", status);
    }

    setBranchData(): void {}
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DecimalPipe, PercentPipe } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

// Components
import { HeaderComponent } from "./components/header/header.component";
import { MasterPolicyComponent } from "./components/master-policy/master-policy.component";
import { OrderTypeComponent } from "./components/order-type/order-type.component";
import { SearchRateQuoteIdComponent } from "./components/search-rate-quote-id/search-rate-quote-id.component";
import { FooterComponent } from "./components/footer/footer.component";
import { OrderParametersComponent } from "./components/order-parameters/order-parameters.component";
import { AdminSettingsComponent } from "./components/admin-settings/admin-settings.component";
import { MiCoverageAndAusFindingsComponent } from "./components/mi-coverage-and-aus-findings/mi-coverage-and-aus-findings.component";
import { MiTableComponent } from "./components/mi-table/mi-table.component";
import { OrderStatusComponent } from "./components/order-status/order-status.component";
import { OrderRatesComponent } from "./components/order-rates/order-rates.component";
import { AllMessagesComponent } from "./components/all-messages/all-messages.component";
import { OrderCompleteComponent } from "./components/order-complete/order-complete.component";
import { AdminMiTableComponent } from "./components/admin-mi-table/admin-mi-table.component";
import { BranchComponent } from "./components/branch/branch.component";
import { MultiRateQuoteTableComponent } from "./components/multi-rate-quote-table/multi-rate-quote-table.component";
import { ContactInfoComponent } from "./components/contact-info/contact-info.component";
import { AdminPersonasTableComponent } from "./components/admin-personas-table/admin-personas-table.component";

// Admin Components
import { AdminModule } from "./admin/admin.module";

import { provideHttpClient } from "@angular/common/http";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ContactInfoComponent,
        MasterPolicyComponent,
        OrderTypeComponent,
        OrderParametersComponent,
        MiCoverageAndAusFindingsComponent,
        MiTableComponent,
        SearchRateQuoteIdComponent,
        AdminSettingsComponent,
        FooterComponent,
        OrderStatusComponent,
        OrderRatesComponent,
        AllMessagesComponent,
        OrderCompleteComponent,
        AdminMiTableComponent,
        BranchComponent,
        MultiRateQuoteTableComponent,
        AdminPersonasTableComponent
    ],
    imports: [BrowserModule, AppRoutingModule, NgbModule, ReactiveFormsModule, FormsModule, AdminModule],
    exports: [],
    providers: [DecimalPipe, PercentPipe, NgbActiveModal, provideHttpClient()],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

<div class="container-main">
    <div class="container-inline">
        <h3>MULTI RATE QUOTE</h3>
        <button
            (click)="addRow()"
            [disabled]="isAddButtonDisabled()"
            [ngClass]="{ 'disabled-button': isAddButtonDisabled() }"
            class="blue"
        >
            <i class="bi bi-plus-lg"></i>
        </button>
    </div>

    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>PREMIUM PLAN</th>
                    <th>SPLIT PREMIUM UPFRONT RATE</th>
                    <th>PREMIUM PAID BY</th>
                    <th>COVERAGE PERCENTAGE %</th>
                    <th>REFUND OPTION</th>
                    <th>RENEWAL OPTION</th>
                    <th>CHOICE MONTHLY UPFRONT PREMIUM</th>
                    <th>FINANCE PREMIUM</th>
                    <th>SPECIAL PROGRAM ID</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of initialMultiQuoteTableData; let i = index">
                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false" (click)="onCellClick('premiumPlanSelect', i, row)">
                        <label class="full-width">
                            <select id="premiumPlanSelect_{{i}}" [ngModel]="row.premiumPlan" (change)="updateValue(initialMultiQuoteTableData[i], 'premiumPlan', $event, i)">
                                <option *ngFor="let plan of premiumPlans" [value]="plan">{{ plan }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td
                        [class.disabled-cell]="!row.isSplit ? true : false"
                        (mouseenter)="!row.isSplit ? (row.showIcon = true) : null"
                        (mouseleave)="row.showIcon = false"
                        (click)="onCellClick('isSplitPlanSelect', i, row)"
                    >
                        <label class="full-width">
                            <select
                                id="isSplitPlanSelect_{{i}}"
                                [disabled]="!row.isSplit"
                                [class.disabled-select]="row.showDisabled"
                                [(ngModel)]="row.splitPremiumUpfrontRate"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'splitPremiumUpfrontRate', $event, i)">
                            >
                            <option [value]="null" selected hidden>
                                0
                            </option>
                                <option *ngFor="let split of splitPremiumUpfrontRates" [value]="split">
                                    {{ split }}
                                </option>
                            </select>
                        </label>

                        <span class="edit-icon" *ngIf="row.showIcon && isSplitPlan(row)">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false" (click)="onCellClick('premiumPaidBySelect', i, row)">
                        <label class="full-width">
                            <select  id="premiumPaidBySelect_{{i}}" [(ngModel)]="row.premiumPaidBy" (change)="updateValue(initialMultiQuoteTableData[i], 'premiumPaidBy', $event, i)">
                                <option *ngFor="let payee of premiumPaidByOptions" [value]="payee">{{ payee }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false" (click)="onCellClick('coveragePercentageSelect', i, row)">
                        <label class="full-width">
                            <input
                                id="coveragePercentageSelect_{{i}}"
                                type="number"
                                [(ngModel)]="row.coveragePercentage"
                                min="1"
                                max="99"
                                (focus)="onInputFocus(i)"
                                (blur)="onInputBlur()"
                                (click)="printMe()"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'coveragePercentage', $event, i)"
                            />
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-arrow-clockwise" (click)="printMe()"></i>
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false">
                        <label class="full-width">
                            <select [(ngModel)]="row.refundOption"  (change)="updateValue(initialMultiQuoteTableData[i], 'refundOption', $event, i)">
                                <option *ngFor="let refund of refundOptions" [value]="refund">{{ refund }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>

                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false">
                        <label class="full-width">
                            <select [(ngModel)]="row.renewalOption" (change)="updateValue(initialMultiQuoteTableData[i], 'renewalOption', $event, i)">
                                <option *ngFor="let renewal of renewalOptions" [value]="renewal">{{ renewal }}</option>
                            </select>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false" (click)="onCellClick('choiceMonthlyUpfrontPremiumSelect', i, row)">
                        <label class="full-width">
                            <input
                                id="choiceMonthlyUpfrontPremiumSelect_{{i}}"
                                type="text"
                                [(ngModel)]="row.choiceMonthlyUpfrontPremium"
                                
                                (blur)="formatAmount(row.choiceMonthlyUpfrontPremium, i)"
                                (change)="updateValue(initialMultiQuoteTableData[i], 'choiceMonthlyUpfrontPremium', $event, i)"
                            />
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false"  (click)="onCellClick('financePremiumSelect', i, row)">
                        <label class="full-width">
                            <input id="financePremiumSelect_{{i}}" type="checkbox" [(ngModel)]="row.financePremium" (click)="onCellClick('financePremiumSelect', i, row)" (change)="updateValue(initialMultiQuoteTableData[i], 'financePremium', $event, i)"/>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td (mouseenter)="row.showIcon = true" (mouseleave)="row.showIcon = false" (click)="onCellClick('specialProgramIdSelect', i, row)">
                        <label class="full-width">
                            <input id="specialProgramIdSelect_{{i}}" type="text" [(ngModel)]="row.specialProgramId" (change)="updateValue(initialMultiQuoteTableData[i], 'specialProgramId', $event, i)"/>
                        </label>
                        <span class="edit-icon" *ngIf="row.showIcon">
                            <i class="bi bi-pencil"></i>
                        </span>
                    </td>
                    <td>
                        <button
                            (click)="deleteRow(i)"
                            [disabled]="isDeleteButtonDisabled()"
                            [ngClass]="{ 'disabled-button': isDeleteButtonDisabled() }"
                            class="blue"
                        >
                            <i class="bi bi-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="container-debt-ratios">
            <div class="selection-block-quarter">
                <p class="debt-label">Debt Ratio without MI</p>
                <ng-container *ngIf="originData; else defaultDebtRatioWithoutMi">
                    <p class="debt-ratio">{{ originData.debtRatioWithoutMi }}</p>
                </ng-container>
                <ng-template #defaultDebtRatioWithoutMi>
                    <p class="debt-ratio">{{ backEndDTI }}</p>
                </ng-template>
            </div>
            <div class="selection-block-quarter">
                <p class="debt-label">Housing Ratio without MI</p>
                <ng-container *ngIf="originData; else defaultHousingRatioWithoutMi">
                    <p class="debt-ratio">{{ originData.housingRatioWithoutMi }}</p>
                </ng-container>
                <ng-template #defaultHousingRatioWithoutMi>
                    <p class="debt-ratio">{{ frontEndDTI }}</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div class="header">
    <h2>Mortgage Insurance Center: Active Order</h2>
    <div>
        <button class="button-settings" (click)="openSettings()">
            <i class="bi bi-gear"></i>
        </button>
        <input type="text" [(ngModel)]="searchValue" [class.error]="searchValue === '' && searchClicked" />
        <button class="button-search" (click)="searchButton(searchValue)">
            <i class="bi bi-search"></i>
        </button>
    </div>
</div>
<div class="order-info-title">
    <div>Order #: {{ transactionData?.rateQuoteId }}</div>
    <div class="order-info-title-buttons">
        <div (click)="resubmitOrder()" class="pointer">RESUBMIT ORDER</div>
        <div (click)="checkStatus()" class="pointer">CHECK STATUS</div>
        <button class="message-log-button" (click)="messageLog()">
            <i class="bi bi-info-square"></i>
        </button>
    </div>
</div>

<div class="overview">
    <div class="overview-buttons-row">
        <div class="overview-button-individual pointer" (click)="toggleOrderStatus()">OVERVIEW |</div>
        <div class="overview-button-individual pointer" (click)="toggleDocumentsReceived()">
            DOCUMENTS RECEIVED ({{ transactionData?.receivedDocuments }}) |
        </div>
        <div class="overview-button-individual pointer" (click)="toggleDocumentsRequested()">
            DOCUMENTS UPLOADED ({{ transactionData?.uploadedDocuments }})
        </div>
    </div>
    <img src="../../../assets/mgic-header-logo.svg" alt="mgicLogo" class="mgic-logo" />
</div>
<div class="content-container" *ngIf="showOrderStatus">
    <div class="content-card-box">
        <div class="order-status-title-row">
            <div>
                <h2>Order Status: {{ transactionData?.orderStatus }}</h2>
            </div>
            <div class="order-status-color-title">ACTITVE ORDER</div>
        </div>
        <div class="status-row">
            <div class="status-box">
                <label class="status-element">Type</label><br />
                <div class="status-element bold">{{ transactionData?.orderStatus }}</div>
            </div>
            <div class="status-box">
                <label class="status-element">Provider</label>
                <div class="status-element bold">MGIC</div>
            </div>
            <div class="status-box">
                <label class="status-element">Order Date</label>
                <div class="status-element bold">TBD DATE</div>
            </div>
        </div>
        <div class="status-row">
            <div class="status-box">
                <label class="status-element">Premium Payment Plan</label>
                <div class="status-element bold">{{ transactionData?.premiumPaymentPlan }}</div>
            </div>

            <div class="status-box">
                <label class="status-element">Premium at Closing</label>
                <div class="status-element bold">TBD Var</div>
            </div>

            <div class="status-box">
                <label class="status-element">Paid By</label>
                <div class="status-element bold">{{ transactionData?.premiumPaidBy }}</div>
            </div>
        </div>

        <div class="status-row">
            <div class="status-box">
                <label class="status-element">Refund Option</label>
                <div class="status-element bold">{{ transactionData?.refundOption }}</div>
            </div>
            <div class="status-box">
                <label class="status-element">Renewal Option</label>
                <div class="status-element bold">{{ transactionData?.renewalOption }}</div>
            </div>
            <div class="status-box">
                <label class="status-element">Product Description</label>
                <div class="status-element bold">{{ transactionData?.premiumPaidBy }} {{ transactionData?.premiumPaymentPlan }}</div>
            </div>
        </div>

        <div class="status-row">
            <div class="status-box">
                <label class="status-element">Certification Expiration Date</label>
                <div class="status-element bold">tbd date</div>
            </div>
            <div class="status-box">
                <label class="status-element">Premium Financed</label>
                <div class="status-element bold">{{ transactionData?.premiumFinanced ? "Yes" : "No" }}</div>
            </div>
            <div class="status-box"></div>
        </div>
    </div>
    <div class="content-card-box">
        <div class="order-status-title-row">
            <div><h2>Imported Rates</h2></div>
        </div>
        <div class="data-row">
            <div class="data-element bold"></div>
            <div class="data-element bold">Percent %</div>
            <div class="data-element bold">Amount</div>
            <div class="data-element bold">Duration</div>
        </div>

        <div class="data-row">
            <div class="data-element bold">Initial Premium at Closing</div>
            <div class="data-element">{{ formatUtils.formatPercent(rateData?.atClosing?.percentage, 2) }}</div>
            <div class="data-element">{{ formatUtils.formatDollar(rateData?.atClosing?.amount) }}</div>
            <div class="data-element">{{ rateData?.atClosing?.duration }} months</div>
        </div>
        <div class="data-row">
            <div class="data-element bold">First Renewal</div>
            <div class="data-element">{{ formatUtils.formatPercent(rateData?.firstRenewal?.percentage, 2) }}</div>
            <div class="data-element">{{ formatUtils.formatDollar(rateData?.firstRenewal?.amount) }}</div>
            <div class="data-element">{{ rateData?.firstRenewal?.duration }} months</div>
        </div>
        <div class="data-row">
            <div class="data-element bold">Second Renewal</div>
            <div class="data-element">{{ formatUtils.formatPercent(rateData?.secondRenewal?.percentage, 2) }}</div>
            <div class="data-element">{{ formatUtils.formatDollar(rateData?.secondRenewal?.amount) }}</div>
            <div class="data-element">{{ rateData?.secondRenewal?.duration }} months</div>
        </div>

        <br />
        <br />
        <div class="date-title">Rates Imported on</div>
        <div class="date-title bold">date</div>
    </div>
</div>
<div>
    <div class="content-container" *ngIf="showDocumentsReceived">
        <div class="content-card-box">
            <div class="order-status-title-row">
                <div>
                    <h2>DOCUMENTS RECEIVED: {{ transactionData?.receivedDocuments }}</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi magnam sunt iusto repudiandae quis
                        aliquid vero beatae laboriosam? Saepe quas dignissimos minus commodi, illum ipsum tenetur sequi
                        impedit laudantium. Dolor?
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content-container" *ngIf="showDocumentsUploaded">
    <div class="content-card-box">
        <div class="order-status-title-row">
            <div>
                <h2>DOCUMENTS UPLOADED: {{ transactionData?.uploadedDocuments }}</h2>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi magnam sunt iusto repudiandae quis
                    aliquid vero beatae laboriosam? Saepe quas dignissimos minus commodi, illum ipsum tenetur sequi
                    impedit laudantium. Dolor?
                </p>
            </div>
        </div>
    </div>
</div>
<div class="rate-title">Rates</div>
<table>
    <thead>
        <tr>
            <th class="th-blank"></th>
            <th class="th-blank"></th>
            <th colspan="3" class="th-main">Initial Premium at Closing</th>
            <th colspan="3" class="th-main">First Renewal</th>
            <th colspan="3" class="th-last">Second Renewal</th>
        </tr>
        <tr>
            <th class="th-blank"></th>
            <th class="th-main">Product</th>
            <th class="th-main">Percent</th>
            <th class="th-main">Amount</th>
            <th class="th-main">Duration</th>
            <th class="th-main">Percent</th>
            <th class="th-main">Amount</th>
            <th class="th-main">Duration</th>
            <th class="th-main">Percent</th>
            <th class="th-main">Amount</th>
            <th class="th-last">Duration</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of placeHolderTableData; let i = index">
            <td><input type="radio" [(ngModel)]="selectedRow" name="tableRadio" class="radio" [value]="i" /></td>
            <td class="td-product">{{ row?.product }}</td>
            <td class="td-data">{{ formatUtils.formatPercent(row?.atClosingPercent, 2) }}</td>
            <td class="td-data">{{ formatUtils.formatDollar(row?.atClosingAmount) }}</td>
            <td class="td-data">{{ row?.atClosingDuration }} months</td>
            <td class="td-data">{{ formatUtils.formatPercent(row?.firstRenewalPercent, 2) }}</td>
            <td class="td-data">{{ formatUtils.formatDollar(row?.firstRenewalAmount) }}</td>
            <td class="td-data">{{ row?.firstRenewalDuration }} months</td>
            <td class="td-data">{{ formatUtils.formatPercent(row?.secondRenewalPercent, 2) }}</td>
            <td class="td-data">{{ formatUtils.formatDollar(row?.secondRenewalAmount) }}</td>
            <td class="td-data">{{ row?.secondRenewalDuration }} months</td>
        </tr>
    </tbody>
</table>

import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-all-messages",
    templateUrl: "./all-messages.component.html",
    styleUrls: ["./all-messages.component.css"]
})
export class AllMessagesComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

import { Component, Input, OnInit } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AllMessagesComponent } from "../all-messages/all-messages.component";
import { OriginDataObject } from "src/app/models/OriginDataObject";
import { TransactionData } from "src/app/models/TransactionData";

@Component({
    selector: "app-order-status",
    templateUrl: "./order-status.component.html",
    styleUrls: ["./order-status.component.css"]
})
export class OrderStatusComponent implements OnInit {
    @Input() originData: OriginDataObject | null;
    @Input() transactionData: TransactionData | null;
    modalRef?: NgbModalRef;
    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}

    openMessages(): void {
        this.modalRef = this.modalService.open(AllMessagesComponent, {
            windowClass: "admin-settings-modal",
            backdrop: "static"
        });
    }
}

import { Component, ChangeDetectorRef, Input, Output, SimpleChanges, EventEmitter, OnInit } from "@angular/core";
import { RequestType } from "../models/enums/RequestType";
import { AdminSettings } from "../models/AdminSettings";
import { ApplicationApisService } from "../services/application-apis/application-apis.service";
import { EncompassApisService } from "../services/encompass-apis/encompass-apis.service";
import { getCurrentTransactionTemplate } from "src/app/utilities/elli";
import { TransactionTemplate } from "../models/TransactionTemplate";

@Component({
    selector: "app-admin",
    templateUrl: "./admin.component.html",
    styleUrl: "./admin.component.css"
})
export class AdminComponent implements OnInit {
    @Output() transactionTemplateEmitter = new EventEmitter<TransactionTemplate>;
    disableSave: boolean = true;
    adminApoTitle: string = "";
    showInfo = false;
    showAlert: boolean = true;
    showWarning: boolean = false;
    warningMessage: string = "";
    requestTypeString: string = RequestType.RateQuote;
    saveDisabled: boolean = false;
    settings: AdminSettings;
    loading: boolean = false;
    currentTemplate: TransactionTemplate;

    constructor(
        private cd: ChangeDetectorRef,
        private applicationApisService: ApplicationApisService,
        private encompassApisService: EncompassApisService,
    ) {
        const masterPolicyNumber = "DeployTest003"
        this.retrieveTransactionTemplate(masterPolicyNumber)
    }

    ngOnInit(): void {
        this.requestTypeString = RequestType.RateQuote;
        this.currentTemplate.request.type = RequestType.RateQuote;
    }

    handleDisableSave(saveable: any) {
        this.disableSave = saveable;
    }

    toggleInfo() {
        this.showInfo = !this.showInfo;
    }

    closeAlert() {
        this.showAlert = false;
    }

    handleRequestTypeChange(requestType: any) {
        this.currentTemplate.request.type = requestType;
        this.requestTypeString = requestType;
    }

    handleSettingsChange(settings: AdminSettings) {
        this.settings = settings;
    }

    handleTemplateTitleChange(title: any){
        this.adminApoTitle = title;
    }

    retrieveAdminSettings(clientId: string): void {
        this.applicationApisService.getAdminSettings(clientId).subscribe(
            (data: string) => {
                this.settings = JSON.parse(data);
                this.loading = false;
            },
            (error: any) => {
                const status = parseInt(error.status);
                if (status >= 400 && status < 500) {
                    this.applicationApisService.getAdminSettings(clientId).subscribe((data: string) => {
                        this.settings = JSON.parse(data);
                        this.loading = false;
                    });
                }
                if (status >= 500 && status < 600) {
                    console.log("error: ", error);
                }
            }
        );
    }

    retrieveTransactionTemplate(clientId: string)  {
        getCurrentTransactionTemplate().then((template: TransactionTemplate) => {
            this.currentTemplate = template;
        });

        this.currentTemplate = {
            name: "",
            request: {
                type: RequestType.RateQuote,
                options: {
                    integration: {
                        autoImport: true,
                        miServiceMethodType: "Automated"
                    },
                    tpo: {
                        tpoCityName: "",
                        tpoStateCode: "",
                        tpoPostalCode: "",
                        tpoCompanyName: "",
                        tpoCompanyAddress: "",
                        originationType: ""
                    },
                    miApplication: {
                        investor: "",
                        rateQuoteId: "",
                        miServiceType: "",
                        miDurationType: "",
                        miCoveragePercent: 0,
                        miLenderIdentifier: "",
                        miPremiumSourceType: "",
                        affordableHousingType: "",
                        investorProgramNameType: "",
                        miCertificateIdentifier: "",
                        miPremiumRefundableType: "",
                        miPremiumUpfrontPercent: 0,
                        relocationLoanIndicator: false,
                        miApplicationRequestType: "",
                        miPremiumCalculationType: "",
                        rushUnderwritingIndicator: false,
                        miPremiumFinancedIndicator: false,
                        miSpecialPricingDescription: "",
                        contractUnderwriteIdentifier: "",
                        miLenderSpecialProgramDescription: "",
                        investorProgramNameTypeOtherDescription: "",
                    }
                }
            }
        }

        this.retrieveAdminSettings(clientId);
    }
}
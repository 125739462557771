<!-- eslint-disable spaced-comment -->
<div class="admin-settings-container">
    <div class="admin-settings-header">
        <div class="admin-settings-title">Admin Settings</div>
        <div>
            <button class="close-button" (click)="activeModal.close()">x</button>
        </div>
    </div>
    <div>
        <div class="subsection-header">
            Branches
            <button id="addBranchButton" class="button-secondary-alt" (click)="openBranchModal(false)">Add</button>
        </div>
        <div class="subsection-content">
            <div class="card-container" *ngFor="let branch of adminSettings?.branches">
                <div>
                    <div class="card-info-header">
                        {{ branch.name }}
                    </div>
                    <div>
                        Branch ID
                        <span class="card-info-subheader-value">{{ branch.id }}</span>
                    </div>
                    <div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="`branchCheckbox-${branch.id}`"
                                value="{{ branch.id }}"
                                [checked]="branch.id === defaultCheckedId"
                                (change)="onDefaultChange($event.target)"
                            />
                            <label class="form-check-label" for="`branchCheckbox-${branch.id}`">Default</label>
                        </div>
                    </div>
                </div>
                <div>
                    <button class="card-button" (click)="openBranchModal(true, branch)">
                        <i class="bi bi-pencil-square card-button"></i>
                    </button>
                    <button class="card-button" (click)="openConfirmationDialog(branch)">
                        <i class="bi bi-trash card-button"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]="adminSettingsForm">
        <div class="subsection-container">
            <div class="subsection-header">Premium, Refund and Renewal Options</div>
            <div class="sub-subsection-container">
                <div class="sub-subsection-30">
                    <div class="sub-subsection-header">Premium Paid By</div>
                    <div class="options-container">
                        <div class="checkbox-container">
                            Select the items to appear in the list
                            <div class="checkbox-item-container" formGroupName="premiumPaidBy">
                                <input
                                    formControlName="borrowerPaid"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="premiumPaidBy-borrowerPaid"
                                    (change)="getDropdownValues('premiumPaidBy')"
                                />
                                <label class="checkbox-label" for="premiumPaidBy-borrowerPaid">Borrower Paid</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="premiumPaidBy">
                                <input
                                    formControlName="lenderPaid"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="premiumPaidBy-lenderPaid"
                                    (change)="getDropdownValues('premiumPaidBy')"
                                />
                                <label class="checkbox-label" for="premiumPaidBy-lenderPaid">Lender Paid</label>
                            </div>
                            <div *ngIf="getErrors('premiumPaidBy')" class="validation-error-checkbox">
                                Select at least one option
                            </div>
                        </div>
                        <div class="dropdown-container">
                            Selected Default
                            <div class="default-dropdown-container">
                                <select class="default-dropdown" (change)="getDefault('premiumPaidBy', $event.target)">
                                    <option
                                        *ngFor="let option of getOptions(adminSettingsDropdowns.premiumPaidBy)"
                                        [value]="option.label"
                                        [selected]="isSelected(option, 'premiumPaidBy')"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="getErrors('premiumPaidBy')" class="validation-error-dropdown">
                                Please select option
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-subsection-divider"></div>
                <div class="sub-subsection-30">
                    <div class="sub-subsection-header">Refund Option</div>
                    <div class="options-container">
                        <div class="checkbox-container">
                            Select the items to appear in the list
                            <div class="checkbox-item-container" formGroupName="refundOption">
                                <input
                                    formControlName="refundable"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="refundOption-refundable"
                                    (change)="getDropdownValues('refundOption')"
                                />
                                <label class="checkbox-label" for="refundOption-refundable">Refundable</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="refundOption">
                                <input
                                    formControlName="notRefundable"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="refundOption-notRefundable"
                                    (change)="getDropdownValues('refundOption')"
                                />
                                <label class="checkbox-label" for="refundOption-notRefundable">Not Refundable</label>
                            </div>
                            <div *ngIf="getErrors('refundOption')" class="validation-error-checkbox">
                                Select at least one option
                            </div>
                        </div>
                        <div class="dropdown-container">
                            Selected Default
                            <div class="default-dropdown-container">
                                <select class="default-dropdown" (change)="getDefault('refundOption', $event.target)">
                                    <option
                                        *ngFor="let option of getOptions(adminSettingsDropdowns.refundOption)"
                                        [value]="option.label"
                                        [selected]="isSelected(option, 'refundOption')"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="getErrors('refundOption')" class="validation-error-dropdown">
                                Please select option
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-subsection-divider"></div>
                <div class="sub-subsection-30">
                    <div class="sub-subsection-header">Renewal Option</div>
                    <div class="options-container">
                        <div class="checkbox-container">
                            Select the items to appear in the list
                            <div class="checkbox-item-container" formGroupName="renewalOption">
                                <input
                                    formControlName="constant"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="renewalOption-constant"
                                    (change)="getDropdownValues('renewalOption')"
                                />
                                <label class="checkbox-label" for="renewalOption-constant">Constant</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="renewalOption">
                                <input
                                    formControlName="decliningAmortizing"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="renewalOption-decliningAmortizing"
                                    (change)="getDropdownValues('renewalOption')"
                                />
                                <label class="checkbox-label" for="renewalOption-decliningAmortizing">
                                    Declining/Amortizing
                                </label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="renewalOption">
                                <input
                                    formControlName="noRenewals"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="renewalOption-noRenewals"
                                    (change)="getDropdownValues('renewalOption')"
                                />
                                <label class="checkbox-label" for="renewalOption-noRenewals">No Renewals</label>
                            </div>
                            <div *ngIf="getErrors('renewalOption')" class="validation-error-checkbox">
                                Select at least one option
                            </div>
                        </div>
                        <div class="dropdown-container">
                            Selected Default
                            <div class="default-dropdown-container">
                                <select class="default-dropdown" (change)="getDefault('renewalOption', $event.target)">
                                    <option
                                        *ngFor="let option of getOptions(adminSettingsDropdowns.renewalOption)"
                                        [value]="option.label"
                                        [selected]="isSelected(option, 'renewalOption')"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="getErrors('renewalOption')" class="validation-error-dropdown">
                                Please select option
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-subsection-container">
                <div class="sub-subsection-50">
                    <div class="sub-subsection-header">Premium Payment Plan</div>
                    <div class="options-container">
                        <div class="checkbox-container">
                            Select the items to appear in the list
                            <div class="checkbox-item-container" formGroupName="premiumPaymentPlan">
                                <input
                                    formControlName="deferredMonthly"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="premiumPaymentPlan-deferredMonthly"
                                    (change)="getDropdownValues('premiumPaymentPlan')"
                                />
                                <label class="checkbox-label" for="premiumPaymentPlan-deferredMonthly">
                                    Deferred Monthly
                                </label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="premiumPaymentPlan">
                                <input
                                    formControlName="single"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="premiumPaymentPlan-single"
                                    (change)="getDropdownValues('premiumPaymentPlan')"
                                />
                                <label class="checkbox-label" for="premiumPaymentPlan-single">Single</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="premiumPaymentPlan">
                                <input
                                    formControlName="splitPremium"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="premiumPaymentPlan-splitPremium"
                                    (change)="getDropdownValues('premiumPaymentPlan')"
                                />
                                <label class="checkbox-label" for="premiumPaymentPlan-splitPremium">
                                    Split Premium
                                </label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="premiumPaymentPlan">
                                <input
                                    formControlName="annual"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="premiumPaymentPlan-annual"
                                    (change)="getDropdownValues('premiumPaymentPlan')"
                                />
                                <label class="checkbox-label" for="premiumPaymentPlan-annual">Annual</label>
                            </div>
                            <div *ngIf="getErrors('premiumPaymentPlan')" class="validation-error-checkbox">
                                Select at least one option
                            </div>
                        </div>
                        <div class="dropdown-container">
                            Selected Default
                            <div class="default-dropdown-container">
                                <select
                                    class="default-dropdown"
                                    (change)="getDefault('premiumPaymentPlan', $event.target)"
                                >
                                    <option
                                        *ngFor="let option of getOptions(adminSettingsDropdowns.premiumPaymentPlan)"
                                        [value]="option.label"
                                        [selected]="isSelected(option, 'premiumPaymentPlan')"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="getErrors('premiumPaymentPlan')" class="validation-error-dropdown">
                                Please select option
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sub-subsection-divider"></div>
                <div class="sub-subsection-50" *ngIf="showSplitPremium">
                    <div class="sub-subsection-header">Split Premium</div>
                    <div class="options-container">
                        <div class="checkbox-container">
                            Select the items to appear in the list
                            <div class="checkbox-item-container" formGroupName="splitPremium">
                                <input
                                    formControlName="percent050"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="splitPremium-percent050"
                                    (change)="getDropdownValues('splitPremium')"
                                />
                                <label class="checkbox-label" for="splitPremium-percent050">0.50%</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="splitPremium">
                                <input
                                    formControlName="percent075"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="splitPremium-percent075"
                                    (change)="getDropdownValues('splitPremium')"
                                />
                                <label class="checkbox-label" for="splitPremium-percent075">0.75%</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="splitPremium">
                                <input
                                    formControlName="percent1"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="splitPremium-percent1"
                                    (change)="getDropdownValues('splitPremium')"
                                />
                                <label class="checkbox-label" for="splitPremium-percent1">1.00%</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="splitPremium">
                                <input
                                    formControlName="percent125"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="splitPremium-percent125"
                                    (change)="getDropdownValues('splitPremium')"
                                />
                                <label class="checkbox-label" for="splitPremium-percent125">1.25%</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="splitPremium">
                                <input
                                    formControlName="percent150"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="splitPremium-percent150"
                                    (change)="getDropdownValues('splitPremium')"
                                />
                                <label class="checkbox-label" for="splitPremium-percent150">1.50%</label>
                            </div>
                            <div class="checkbox-item-container" formGroupName="splitPremium">
                                <input
                                    formControlName="percent175"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="splitPremium-percent175"
                                    (change)="getDropdownValues('splitPremium')"
                                />
                                <label class="checkbox-label" for="splitPremium-percent175">1.75%</label>
                            </div>
                            <div *ngIf="getErrors('splitPremium')" class="validation-error-checkbox">
                                Select at least one option
                            </div>
                        </div>
                        <div class="dropdown-container">
                            Selected Default
                            <div class="default-dropdown-container">
                                <select class="default-dropdown" (change)="getDefault('splitPremium', $event.target)">
                                    <option
                                        *ngFor="let option of getOptions(adminSettingsDropdowns.splitPremium)"
                                        [value]="option.label"
                                        [selected]="isSelected(option, 'splitPremium')"
                                    >
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="getErrors('splitPremium')" class="validation-error-dropdown">
                                Please select option
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-subsection-container">
                <div class="sub-subsection">
                    <div class="sub-subsection-header">Premium Financed</div>
                    <div class="options-container">
                        <div class="checkbox-container">
                            <div class="checkbox-item-container">
                                <input
                                    formControlName="premiumFinanced"
                                    class="checkbox-item"
                                    type="checkbox"
                                    id="premiumFinanced"
                                />
                                <label class="checkbox-label" for="premiumFinanced">Premium Financed</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="subsection-container">
        <div class="subsection-container">
            <div class="subsection-header">MI Coverage Percentage</div>
            <div class="subsection-content no-flex">
                <div class="radio-buttons-container">
                    <div class="radio-button-container">
                        <input
                            id="fannieMae"
                            type="radio"
                            value="Fannie Mae"
                            name="miTable"
                            [checked]="fannieMae"
                            (change)="onMiCoverageClick(MiCoverageType.FannieMae)"
                        />
                        <label for="fannieMae" class="radio-button-label">Fannie Mae</label>
                    </div>
                    <div class="radio-button-container">
                        <input
                            id="freddieMac"
                            type="radio"
                            value="Freddie Mac"
                            name="miTable"
                            [checked]="!fannieMae"
                            (change)="onMiCoverageClick(MiCoverageType.FreddieMac)"
                        />
                        <label for="freddieMac" class="radio-button-label">Freddie Mac</label>
                    </div>
                </div>
                <div class="table-container">
                    <app-admin-mi-table
                        [mi]="adminSettings.miTable"
                        [fannieMae]="fannieMae"
                        (validForm)="validateTable($event)"
                        (editedMiTable)="updateAdminSettings('miTable', $event)"

                    >
                    </app-admin-mi-table>
                </div>
            </div>
        </div>
    </div>
    <div class="subsection-container">
        <div class="subsection-container">
            <div class="subsection-header">Persona Ordering Permissions</div>
            <div class="subsection-content no-flex">
                <div class="switch-button-container">
                    <div class="persona-permissions-label">Apply Persona Permissions?</div>
                    <label class="switch">
                        <input
                            type="checkbox"
                            [checked]="permissionsApplied"
                            (change)="handleApplyPermissions($event)"
                        />
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="table-container">
                    <app-admin-personas-table
                        [personas]="adminSettings.personas"
                        (validPersonas)="validatePersonas($event)"
                        (editedPersonas)="updateAdminSettings('personas', $event)"
                    >
                    </app-admin-personas-table>
                </div>
            </div>
        </div>
        <div class="buttons-container">
            <button class="button-secondary" (click)="activeModal.close()">Cancel</button>
            <button class="button-primary" (click)="onSave()">Save</button>
        </div>
    </div>
</div>

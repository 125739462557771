import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { cancelInteraction } from "src/app/utilities/elli";

@Component({
    selector: "app-admin-header",
    templateUrl: "./admin-header.component.html",
    styleUrls: ["./admin-header.component.css"]
})
export class AdminHeaderComponent {
    @Input() showInfo: boolean = false;
    @Output() toggleInfo = new EventEmitter<void>();

    constructor() {}

    onToggleInfo() {
        this.toggleInfo.emit();
    }

    handleNav() {
        cancelInteraction();
    }
}

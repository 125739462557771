import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import AdminSettings from "src/app/models/AdminSettings";
import { Branch } from "src/app/models/Branch";
import { OriginData } from "src/app/models/OriginData";
import { TransactionData } from "src/app/models/TransactionData";
import { RequestType } from "src/app/models/enums/RequestType";
import { LoanData } from "src/app/models/loan-data";

@Component({
    selector: "app-master-policy",
    templateUrl: "./master-policy.component.html",
    styleUrls: ["./master-policy.component.css"]
})
export class MasterPolicyComponent implements OnInit, OnChanges {
    @Input() originData: OriginData | null = null;
    @Input() transactionData: TransactionData | null = null;
    @Input() settings: AdminSettings;
    @Input() requestTypeString: string;

    selectControlBranchId = new FormControl("0");
    // hard coding master policy for now but will need to retrieve
    masterPolicyIds: string[] = ["MGIC QA 1 | 4846043673", "MGIC QA 2 | 9999999999"];
    selectMasterPolicyId = new FormControl("0");
    loading: boolean;

    // hard coding branch array for now
    branchList: Branch[] = [];
    defaultBranch: any;
    masterPolicyForm: FormGroup;

    showAttachDocuments: boolean = false;

    constructor(private masterPolicy: FormBuilder) {
        this.masterPolicyForm = this.masterPolicy.group({
            masterPolicyNumber: ["", Validators.required],
            branchId: ["", Validators.required]
        });
    }

    ngOnInit(): void {
        if (this.loading === false) {
            this.loadSettings();
            this.loadLoanData();

        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings) {
            if (this.settings === null || this.settings === undefined) {
                this.loading = true;
            }
            if (this.settings !== null && this.settings !== undefined) {
                this.loading = false;
                this.ngOnInit();
            }
        }
        if (changes.requestTypeString) {
            switch (changes.requestTypeString.currentValue) {
                case RequestType.NonDelegatedMi:
                    this.showAttachDocuments = true;
                    break;

                case RequestType.ContractUnderwritingWithMi:
                    this.showAttachDocuments = true;
                    break;

                case RequestType.ContractUnderwritingWithoutMi:
                    this.showAttachDocuments = true;
                    break;
                default:
                    this.showAttachDocuments = false;
                    break;
            }
        }
    }

    loadSettings(): void {
        if (this.loading === false) {
            this.branchList = this.settings.branches;
            this.defaultBranch = this.branchList.find(branch => branch.default);
            this.selectControlBranchId.setValue(this.defaultBranch);
        }
    }

    loadLoanData(): void {
        if (this.transactionData) {
            this.branchList = this.settings?.branches;
            if(this.branchList === null || undefined){
                this.selectControlBranchId.setValue("");
            }
            if(this.branchList.length === 1){
                this.selectControlBranchId.setValue(this.branchList[0].id);
            }
            if(this.branchList.length >= 2){
                this.selectControlBranchId.setValue(this.defaultBranch.id);
            }
            this.selectControlBranchId.setValue("");
            this.selectControlBranchId.disable();
        }
    }

    attachDocuments(): void {
        // TODO: Implement Encompass document uploader
        console.log("Encompass Document Uploader Popup");
    }

    setBranchData(): void {}
}

import { Component, Input, OnInit } from "@angular/core";
import { ApplicationState } from "src/app/models/ApplicationState";
import { OriginData } from "src/app/models/OriginData";
import { TransactionData } from "src/app/models/TransactionData";
import { createTransaction, initializeOriginationContext } from "src/app/utilities/elli";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
    @Input() originData: OriginData | null = null;
    @Input() transactionData: TransactionData | null = null;
    @Input() applicationState: ApplicationState;

    ngOnInit(): void {}

    onCancel() {
        console.log("cancel button was clicked");
    }

    async onFormSubmit() {
        console.log("submit button clicked for entire form");

        const transactionRequest = {
            resources: []
        }

        await createTransaction(transactionRequest, this.applicationState, "MIApplication");
    }
}

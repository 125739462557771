import host from "@elliemae/em-ssf-guest";
import { ApplicationState } from "../models/ApplicationState";
import { TransactionTemplate } from "../models/TransactionTemplate";
import { TransactionRequest } from "../models/TransactionRequest";
import { RequestType } from "../models/enums/RequestType";

// Origin JS APIs
export async function initializeOriginationContext(applicationState: ApplicationState) {
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject("transaction");
        const originationContext = transactionObject.getOrigin();

        applicationState.originId = originationContext.id;
        applicationState.partnerAccessToken = originationContext.partnerAccessToken;
        if (originationContext.transactionId) {
            applicationState.transactionId = originationContext.transactionId;
        }
        return applicationState;
    } catch (error) {
        console.log(error);
        return;
    }
}

export async function refreshOriginationContext(applicationState: ApplicationState) {
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject("transaction");
        const originationContext = await transactionObject.refreshOrigin();

        applicationState.originId = originationContext.id;
        applicationState.partnerAccessToken = originationContext.partnerAccessToken;
        if (originationContext.transactionId) {
            applicationState.transactionId = originationContext.transactionId;
        }
        return applicationState;
    } catch (error) {
        console.log(error);
        return;
    }
}

// Transaction JS APIs
export async function createTransaction(
    transactionRequest: TransactionRequest,
    applicationState: ApplicationState,
    requestType: string
) {
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject("transaction");
        const createRequest = {
            request: {
                options: transactionRequest.options,
                type: requestType,
                resources: transactionRequest.resources
            }
        };

        const transactionData = await transactionObject.create(createRequest);

        applicationState.transactionId = transactionData.id;
        return applicationState;
    } catch (error) {
        console.log(error);
        return;
    }
}

export async function updateTransaction(updatedTransactionRequest: any, applicationState: ApplicationState) {
    try {
        host.connect();
        host.ready();
        const transactionObject = await host.getObject("transaction");
        const updateRequest = {
            request: {
                options: updatedTransactionRequest.options
            }
        };
        const transactionData = await transactionObject.update(updateRequest);
        applicationState.transactionId = transactionData.id;
        return applicationState;
    } catch (error) {
        console.log(error);
        return;
    }
}

// Interaction Javascript APIs
export async function closeInteraction(): Promise<void> {
    host.connect();
    host.ready();
    const transactionObject = await host.getObject('transaction');
    transactionObject.close();
}

export async function cancelInteraction(): Promise<void> {
    host.connect();
    host.ready();
    const transactionObject = await host.getObject('transaction');
    transactionObject.cancel();
}

export async function errorInteraction(): Promise<void> {
    host.connect();
    host.ready();
    const transactionObject = await host.getObject('transaction');
    transactionObject.error();
}

// Document Javascript APIs
// TODO: Determine if MGIC would like file upload limits (Size, number of files)
export async function uploadDocuments(action: string, status: string): Promise<any> {
    try {
        host.connect();
        host.ready();
        const applicationObject = await host.getObject('application');
        const actionResult = await applicationObject.performAction(action);
        const transactionObject = await host.getObject('transaction');

        let transactionResponse;
        if (status === 'update') {
            if (actionResult.length > 0) {
                const transactionRequest: any = {
                    request: {
                        resources: actionResult // Include user selected resources in request
                    }
                };
                transactionResponse = await transactionObject.update(transactionRequest);
            }

            return transactionResponse;
        } else {
            return actionResult;
        }
    } catch (error) {
        console.log(error)
        return;
      }
}

// Action Javascript APIs
export async function performAction(action: string): Promise<any> {
    try {
        host.connect();
        host.ready();
        const applicationObject = await host.getObject('application');
        const actionResponse = await applicationObject.performAction(action);
        return actionResponse;
    } catch (error) {
        console.log(error)
        return;
    }
}

// Transaction Template JS APIs
export async function getCurrentTransactionTemplate() {
    try {
        host.connect();
        host.ready();
        const transactionTemplateObject = await host.getObject('transactionTemplate');
        const currentTransactionTemplate: TransactionTemplate = await transactionTemplateObject.get();

        if (currentTransactionTemplate) {
            return currentTransactionTemplate;
        } else {
            const newTransactionTemplate: TransactionTemplate = {
                name: "",
                request: {
                    type: RequestType.RateQuote,
                    options: {
                        integration: {
                            autoImport: true,
                            miServiceMethodType: 'Automated'
                        },
                        tpo: {
                            tpoCityName: "",
                            tpoStateCode: "",
                            tpoPostalCode: "",
                            tpoCompanyName: "",
                            tpoCompanyAddress: "",
                            originationType: ""
                        },
                        miApplication: {
                            investor: "",
                            rateQuoteId: "",
                            miServiceType: "",
                            miDurationType: "",
                            miCoveragePercent: 0,
                            miLenderIdentifier: "",
                            miPremiumSourceType: "",
                            affordableHousingType: "",
                            investorProgramNameType: "",
                            miCertificateIdentifier: "",
                            miPremiumRefundableType: "",
                            miPremiumUpfrontPercent: 0,
                            relocationLoanIndicator: false,
                            miApplicationRequestType: "",
                            miPremiumCalculationType: "",
                            rushUnderwritingIndicator: false,
                            miPremiumFinancedIndicator: false,
                            miSpecialPricingDescription: "",
                            contractUnderwriteIdentifier: "",
                            miLenderSpecialProgramDescription: "",
                            investorProgramNameTypeOtherDescription: "",
                        }
                    }
                }
            }
        }

        return currentTransactionTemplate;
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export async function createTransactionTemplate(transactionTemplate: TransactionTemplate) {  
    try {
        host.connect();
        host.ready();
        const transactionTemplateObject = await host.getObject('transactionTemplate')
        const transactionTemplateSuccess = await transactionTemplateObject.save(transactionTemplate)
    } catch (error) {
        console.log(error)
        return;
      }
}

export async function closeAdminInteraction() {
    try {
        host.connect();
        host.ready();
        const transactionTemplateObject = await host.getObject('transactionTemplate')
        transactionTemplateObject.close()
    } catch (error) {
        console.log(error)
        return;
      }
}

// TODO: add logic to this function 
export async function initialize(transactionTemplate?: TransactionTemplate) {
    // TODO: this is a placeholder that needs to be developed
    // it should pre-fill the user-interface with the
    // values from the current transaction template
    if(transactionTemplate){
        let tempTemplate = getCurrentTransactionTemplate();
        return tempTemplate;
    }else{
        // Create load defaults
        return transactionTemplate;
    }
}
  
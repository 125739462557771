import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LoanData } from "./models/loan-data";
import { RateData } from "./models/rate-data";
import { OriginData } from "./models/OriginData";
import { TransactionData } from "./models/TransactionData";
import { AusService } from "./models/enums/AusService";
import { RequestType } from "./models/enums/RequestType";
import { PremiumPaymentPlan } from "./models/enums/PremiumPaymentPlan";
import { SplitPremium } from "./models/enums/SplitPremium";
import { PremiumPaidBy } from "./models/enums/PremiumPaidBy";
import { RefundOption } from "./models/enums/RefundOption";
import { RenewalOption } from "./models/enums/RenewalOption";
import { AffordableHousingCodes } from "./models/enums/AffordableHousingCodes";
import { AmortizationPlan } from "./models/enums/AmortizationPlan";
import AdminSettings from "./models/AdminSettings";
import adminSettings  from "./mocks/adminSettings.json";

import { OriginatorTypes } from "./models/enums/OriginatorTypes";
import { ApplicationApisService } from "./services/application-apis/application-apis.service";
import { EncompassApisService } from "./services/encompass-apis/encompass-apis.service";
import { States } from "./models/enums/State";
import originDetails from "./mocks/originDetails.json";
import { ApplicationState } from "./models/ApplicationState";
import { initializeOriginationContext } from "./utilities/elli";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
    applicationState: ApplicationState = {
        originId: '',
        partnerAccessToken: '',
        transactionId: ''
    };
    persona: string = "Administrator";
    isContactInfoOpen: boolean = false;
    requestTypeString: string = RequestType.RateQuote;
    requestType = RequestType;
    settings: AdminSettings;
    title = "ui";
    originData: OriginData;
    transactionData: TransactionData;
    rateData: RateData;
    showWarning: boolean = false;
    warningMessage: string = "";
    clientId: string;
    loading: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private applicationApisService: ApplicationApisService,
        private encompassApisService: EncompassApisService,
        public router: Router
    ) {
        this.retrieveEncompassData();
    }

    ngOnInit(): void {
        // TODO: Get persona from Encompass
        // this.encompassApiService.getPersona();

        this.intialize();

        this.handleWarning();
    }

    async intialize() {
        await initializeOriginationContext(this.applicationState).then((state) => {
            if (state) {
                this.applicationState = state;
            }
        });
    }

    handleRequestTypeChange(requestType: any) {
        this.requestTypeString = requestType;
        this.handleWarning();
    }

    handleSettingsChange(settings: AdminSettings) {
        this.settings = settings;
    }

    handleWarning(): void {
        switch (this.requestTypeString) {
            case RequestType.NonDelegatedMi:
                this.warningMessage = "— Documents must be attached when submitting Non Delegated order";
                this.showWarning = true;
                break;

            case RequestType.ContractUnderwritingWithMi:
                this.warningMessage = "— Documents must be attached when submitting Contract Underwriting order";
                this.showWarning = true;
                break;

            case RequestType.ContractUnderwritingWithoutMi:
                this.warningMessage = "— Documents must be attached when submitting Contract Underwriting order";
                this.showWarning = true;
                break;
            default:
                this.warningMessage = "";
                this.showWarning = false;

                break;
        }
    }

    closeWarning(): void {
        this.showWarning = false;
    }

    openContactInfo() {
        this.isContactInfoOpen = true;
    }

    closeContactInfo() {
        this.isContactInfoOpen = false;
    }

    retrieveAdminSettings(clientId: string): void {
        this.clientId = clientId;
        // this.applicationApisService.getAdminSettings(clientId).subscribe(
        //     (data: string) => {
        //         this.settings = JSON.parse(data);
        //         this.loading = false;
        //     },
        //     (error: any) => {
        //         const status = parseInt(error.status);
        //         if (status >= 400 && status < 500) {
        //             this.applicationApisService.getAdminSettings(clientId).subscribe((data: string) => {
        //                 this.settings = JSON.parse(data);
        //                 this.loading = false;
        //             });
        //         }
        //         if (status >= 500 && status < 600) {
        //             console.log("error: ", error);
        //         }
        //     }
        // );
        this.settings = adminSettings as AdminSettings;
    }

    retrieveEncompassData() {
        if (localStorage.getItem("CURRENT_EPC_TOKEN")) {
            // If we have a token, introspect and see if it is active
            this.encompassApisService.introspectToken().subscribe(
                (data: any) => {
                    if (data.active) {
                        this.retrieveTransactionData();
                    } else {
                        this.retrieveToken();
                    }
                },
                (error: any) => {
                    const status = parseInt(error.status);
                    if (status >= 400 && status < 500) {
                        this.retrieveToken();
                    }
                    if (status >= 500 && status < 600) {
                        console.log("error: ", error);
                    }
                }
            );
        } else {
            this.retrieveToken();
        }
    }

    retrieveToken() {
        this.encompassApisService.generateToken().subscribe(
            (data: any) => {
                if (data.access_token) {
                    localStorage.setItem("CURRENT_EPC_TOKEN", data.access_token);

                    //retrieve origin details
                    this.retrieveTransactionData();
                }
            },
            (error: any) => {
                const status = parseInt(error.status);
                if (status >= 400 && status < 500) {
                    this.encompassApisService.generateToken().subscribe((data: any) => {
                        if (data.access_token) {
                            localStorage.setItem("CURRENT_EPC_TOKEN", data.access_token);
                            this.retrieveTransactionData();
                        }
                    });
                }
                if (status >= 500 && status < 600) {
                    console.log("error: ", error);
                }
            }
        );
    }

    retrieveTransactionData() {
        // This will come from the application state (elli JS API)
        // applicationState?.transactionId

        //temp tranId hardcoded for testing
        const appState = { tranId: "" };
        //const appState = { tranId : ""};

        // If tranId exists = order details
        if (appState?.tranId !== "") {
            // get transaction details, map data from transaction to order details page/dropdowns etc
            this.encompassApisService.getTransactionDetailsById(appState.tranId).subscribe(
                (data: any) => {
                    this.retrieveOrderData(data);
                },
                (error: any) => {
                    const status = parseInt(error.status);
                    if (status >= 400 && status < 500) {
                        this.encompassApisService.getTransactionDetailsById(appState.tranId).subscribe((data: any) => {
                            this.retrieveOrderData(data);
                        });
                    }
                    if (status >= 500 && status < 600) {
                        console.log("error: ", error);
                    }
                }
            );
        } else if (appState.tranId === "") {
            this.retrieveOriginDetails();
            console.log("elseIf",this.originData);
        }
    }

    // data will be transaction details data type
    retrieveOrderData(data: any) {
        // add data from transactiondetails to appropriate data variables
        // if (this.transactionData != null) {
        // TODO: ICE-57 - TransactionData should not contain OriginData items
            //this.transactionData.masterPolicyIds = [data.credentials.masterPolicyNumber];
            // this.transactionData.branchId = data.....
            // this.transactionData.rateQuoteId = data?.id;
            // this.transactionData.requestType = tran
            // this.transactionData.orderStatus = tran
            //this.transactionData.purchasePrice = data?.request?.property?.purchasePriceAmount ?? null;
            //this.transactionData.originatorType = pending
        
        //}
       
        // get origin details
        this.retrieveOriginDetails();
    }

    retrieveOriginDetails() {
        // TODO implement api call origin
        // this.encompassApisService.getOriginDetails("some-origin-id").subscribe((data: any) => {

        const data = originDetails; // TODO: ICE-57 - Create data type for origin data that comes from encompass (separate from the OriginData that exists now)

        const debtRatioWithoutMi: string = ((data?.loan?.applications[0]?.totalPaymentsAmount / data?.loan?.applications[0]?.totalGrossMonthlyIncomeAmount) * 100).toFixed(3).toString();
        const housingRatioWithoutMi: string = ((data?.loan?.applications[0]?.totalPrimaryHousingExpenseAmount / data?.loan?.applications[0]?.totalGrossMonthlyIncomeAmount) * 100).toFixed(3).toString();
        const affordableHousingType: string = Object.keys(AffordableHousingCodes)[
            Object.values(AffordableHousingCodes).indexOf(data.loan.fnmCommunityLendingProductName.toString())
        ];
        // TODO: ICE-57 - Set all OriginData properties together to initialize, cannot access by property until initialized
        this.originData = {
            encompassLoanNumber: data.entityRef?.entityId,
            fnmaLendingProduct : data?.loanProductData?.freOfferingIdentifier,
            fhlmcOfferingIdentifier : data?.loanProductData?.freOfferingIdentifier,
            ausService : data?.loan?.tsum?.riskAssessmentType,
            debtRatioWithoutMi : debtRatioWithoutMi,
            housingRatioWithoutMi : housingRatioWithoutMi,
            tpoOriginatorZip : data?.tpo?.companyZip,
            tpoOriginatorAddress : data?.tpo?.companyAddress,
            tpoOriginatorCompany : data?.tpo?.companyName,
            tpoOriginatorState : States[data?.tpo?.companyState as keyof typeof States],
            tpoOriginatorCity : data?.tpo?.companyCity,
            purchaseEligibility : data?.loan?.tql?.stonegate4506TBaseLineReport,
            duFindings : data?.loan?.tql?.stonegateFraudBaselineReportRequired,
            riskClass : data?.loan?.freddieMac?.freddieFiel14,
            lpaCaseFileId : data?.loan?.freddieMac?.loanProspectorId,
            duCaseFileId : data?.loan?.fannieMae?.mornetPlusCaseFileId,
            loanToValue : data?.loan?.ltv,
            amortizationType: data?.loan?.loanAmortizationType as AmortizationPlan,
            loanTermYears : (data?.loan?.loanAmortizationTermMonths / 12),
        }

        // this.loanData = data

        // get adminSettings using credentials (master policy number?)
        const masterPolicyNumber = data.credentials.masterPolicyNumber; //"DeployTest003" data.credentials.masterPolicyNumber
      
        // replace below line when mappings implemented.
        this.retrieveAdminSettings(masterPolicyNumber);
        //this.retrieveAdminSettings(data.credentials.masterPolicyNumber)

        //});
        // objects
        // OriginData
        // TransactionData

    }
}

import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
    styleUrl: "./confirmation-dialog.component.css"
})
export class ConfirmationDialogComponent {
    constructor(public activeModal: NgbActiveModal) {}

    onYes(): void {
        this.activeModal.close(true);
    }
}

<div class="main-container">
    <a class="a-x-small-text" href="#" type="button" (click)="handleNav()">Service Management /</a>
    <header>
        <div class="small-container-inline">
            <h1>MGIC Order Options</h1>
            <button id="info" (click)="onToggleInfo()" class="header-button">
                <i class="bi bi-info-circle"></i>
            </button>
        </div>
    </header>
</div>
